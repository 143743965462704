const colors = {
    black: '#000000',
    greyDark: '#999999',
    grey: '#DBDBDB',
    greyLight: '#F0F0F0',
    green: '#02C697',
    red: '#FF6764',
};

export default colors;
