import React from 'react';
import styled from 'styled-components';
import useApiResource from '../../hooks/useApiResource';
import Label from '../typography/Label';
import Select from './Select';

const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;

const LocationSelector = ({ selectedLocation, onChange, ...props }) => {
    const [locations, loading] = useApiResource('locations');

    if (!locations) return null;

    const mappedLocations = locations.map((a) => ({
        label: a.name,
        value: a.id,
    }));

    mappedLocations.unshift({
        label: 'Not selected',
        value: null,
    });

    return (
        <Container {...props}>
            <Label bold as="div">
                Location
            </Label>
            <Select
                selected={mappedLocations.find(
                    (a) => a.value === selectedLocation
                )}
                onChange={(e) =>
                    onChange(
                        e,
                        locations.find((a) => a.id == e)
                    )
                }
                options={mappedLocations}
                placeholder="Select location"
                color="grey"
            />
        </Container>
    );
};

export default LocationSelector;
