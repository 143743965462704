import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    background-color: white;
    box-shadow: 0 20px 24px -10px rgba(0, 0, 0, 0.12);
    margin-bottom: 36px;
`;

const Card = (props) => {
    return <Container {...props} />;
};

export default Card;
