import React, { useState } from 'react';
import styled from 'styled-components';
import Subtitle from '../../../components/typography/Subtitle';
import Label from '../../../components/typography/Label';
import CheckBox from '../../../components/form/CheckBox';
import Button from '../../../components/buttons/Button';
import api from '../../../services/Api';

const Container = styled.div``;

const List = styled.div`
    width: 100%;
`;

const ListRow = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
`;

const ListRowItem = styled(ListRow)`
    //padding: 10px 0;

    position: relative;
    cursor: pointer;

    .checkbox-wrap {
        right: calc(100% + 10px);
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }

    &:nth-child(2n + 0) {
        background-color: rgba(0, 0, 0, 0.05);
    }

    > div:nth-child(2) {
        grid-column-start: 1;
        grid-column-end: 2;
        align-self: center;
        padding: 0 10px;
    }

    > div:last-child {
        grid-column-start: 2;
        grid-column-end: 5;

        .issue {
            &:nth-child(2n + 0) {
                background-color: rgba(0, 0, 0, 0.05);
            }
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            padding: 10px;

            .opGroup {
                grid-column-start: 1;
                grid-column-end: 2;
                align-self: center;
            }

            .providedBy {
                grid-column-start: 2;
                grid-column-end: 4;
            }
        }
    }

    .sublabel {
        font-size: 12px;
        font-weight: 600;
        margin-right: 5px;
    }

    .subvalue {
        margin-right: 10px;
    }

    .providedBy > div {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 5px 0;

        > div {
            &:first-child {
                min-width: 150px;
            }
            display: flex;
            flex-direction: row;
            align-items: center;
        }
    }
`;

const ListHeader = styled(ListRow)``;

const ButtonRow = styled.div`
    margin-top: 20px;
`;

const AccessDebug = ({ issues, setIssues, onScan }) => {
    const [ignore, setIgnore] = useState([]);
    const [loading, setLoading] = useState(false);
    const toggleIgnore = (userId) => {
        setIgnore(
            ignore.includes(userId)
                ? ignore.filter((a) => a !== userId)
                : [...ignore, userId]
        );
    };

    const handleFix = () => {
        // Fix the ones that are NOT in the ignore array
        const approved = Object.keys(issues)
            .filter((key) => {
                const item = issues[key];
                return !ignore.includes(item.user.id);
            })
            .map((key) => {
                return issues[key];
            });

        const notApproved = Object.keys(issues)
            .filter((key) => {
                const item = issues[key];
                return ignore.includes(item.user.id);
            })
            .map((key) => {
                return issues[key];
            });
        setLoading(true);

        api(`/admin/access/apply-missing`, 'POST', {
            approved,
        })
            .then((res) => {
                setIssues(notApproved);
                setLoading(false);
            })
            .catch((e) => {
                console.log('apply-missing access failed, ', e.message());
            });
    };

    return (
        <Container>
            <List>
                <ListHeader>
                    <Subtitle>Name</Subtitle>
                    <Subtitle>Missing access</Subtitle>
                    <Subtitle>Provided by</Subtitle>
                </ListHeader>
                {Object.keys(issues).map((key) => {
                    const item = issues[key];
                    return (
                        <ListRowItem
                            key={item.user.id}
                            onClick={() => toggleIgnore(item.user.id)}
                        >
                            <div className="checkbox-wrap">
                                <CheckBox
                                    checked={!ignore.includes(item.user.id)}
                                />
                            </div>
                            <div>
                                <Label as="div">{item.user.name}</Label>
                            </div>
                            <div className="issues">
                                {Object.keys(item.missing).map((key2) => {
                                    const miss = item.missing[key2];
                                    return (
                                        <div className="issue" key={key2}>
                                            <div className="opGroup">
                                                {miss.openPathGroupId}
                                            </div>
                                            <div className="providedBy">
                                                {Object.keys(
                                                    miss.providedBy
                                                ).map((key3) => {
                                                    const prov =
                                                        miss.providedBy[key3];
                                                    return (
                                                        <div key={key3}>
                                                            <div>
                                                                <span className="sublabel">
                                                                    Team:
                                                                </span>
                                                                <span className="subvalue">
                                                                    {
                                                                        prov.teamName
                                                                    }
                                                                </span>
                                                            </div>
                                                            <span className="sublabel">
                                                                Groups:
                                                            </span>
                                                            <span className="subvalue">
                                                                {prov.groups.join(
                                                                    ', '
                                                                )}
                                                            </span>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </ListRowItem>
                    );
                })}
            </List>
            {!!issues.length && (
                <ButtonRow>
                    <Button disabled={loading} grey onClick={handleFix}>
                        Fix selected
                    </Button>
                </ButtonRow>
            )}
        </Container>
    );
};

export default AccessDebug;
