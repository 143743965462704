import React, { useState } from 'react';
import styled from 'styled-components';
import IconButton from '../buttons/IconButton';
import DropDownArrowIcon from '../icons/interface/DropDownArrowIcon';
import DropUpArrowIcon from '../icons/interface/DropUpArrowIcon';
import colors from '../../const/colors';
import Button from '../buttons/Button';
import Card from '../Card';

const Container = styled.div`
    position: relative;
`;

const DropdownContent = styled(Card)`
    position: absolute;
    z-index: 5;
    right: 0;
    border-radius: 5px;
    margin-top: 5px;
    overflow: hidden;
    display: ${(props) => (props.open ? 'block' : 'none')};

    > div {
        white-space: nowrap;
        padding: 15px 20px;
        cursor: pointer;
        position: relative;

        &:hover {
            background-color: ${colors.greyLight};
        }

        &:after {
            content: '';
            position: absolute;
            width: calc(100% - 24px);
            left: 12px;
            bottom: 0;
            height: 1px;
            background-color: ${colors.greyLight};
        }

        &:last-child:after {
            display: none;
        }
    }
`;

const DropdownButton = styled(Button)`
    padding-right: 5px;
    z-index: 2;
    position: relative;

    svg {
        margin-left: 5px;
    }
`;

const Fader = styled.div`
    position: fixed;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.2);
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
`;

const Select = ({ selected, color, options, placeholder, onChange, style }) => {
    const [open, setOpen] = useState(false);

    const select = (value) => {
        setOpen(false);
        onChange(value);
    };

    return (
        <Container style={style}>
            <DropdownButton grey onClick={() => setOpen(!open)}>
                <span>{selected ? selected.label : placeholder}</span>
                {open ? <DropUpArrowIcon /> : <DropDownArrowIcon />}
            </DropdownButton>

            <DropdownContent open={open}>
                {options.map((a) => (
                    <div key={a.value} onClick={() => select(a.value)}>
                        {a.label}
                    </div>
                ))}
            </DropdownContent>
            {open && <Fader onClick={() => setOpen(false)} />}
        </Container>
    );
};

export default Select;
