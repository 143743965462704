import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import SingleBookingView from './SingleBookingView';
import moment from 'moment';
import IconButton from '../../components/buttons/IconButton';
import AddWhiteIcon from '../../components/icons/AddWhiteIcon';
import AdminScreen from '../../components/templates/AdminScreen';
import useApiResource from '../../hooks/useApiResource';
import Label from '../../components/typography/Label';
import MinimalCard from '../../components/cards/MinimalCard';
import downloadCsv from 'download-csv';
import resourceEndpoints from '../../const/resourceEndpoint';
import api from '../../services/Api';
import DatePicker from 'react-datepicker';
import { format, subMonths } from 'date-fns';

import 'react-datepicker/dist/react-datepicker.css';

const formatTime = (time) => {
    if (time.length === 1) {
        const [date, timeRange] = time[0].split(' ');
        return `${moment(date, 'YYYY-MM-DD').format('MMM Do')}, ${timeRange}`;
    } else {
        const [startDate] = time[0].split(' ');
        const [endDate] = time[time.length - 1].split(' ');

        return `${moment(startDate, 'YYYY-MM-DD').format('MMM Do')} - ${moment(
            endDate,
            'YYYY-MM-DD'
        ).format('MMM Do')}`;
    }
};

const BookingCard = styled(({ image, duration, time, location, ...props }) => (
    <MinimalCard {...props}>
        <img src={image} />
        <div className="content">
            <div className="col">
                <Label tiny as="div">
                    {formatTime(time)}
                </Label>
                <Label tiny weak as="div">
                    {location}
                </Label>
            </div>
            <Label tiny as="div">
                {duration}
            </Label>
        </div>
    </MinimalCard>
))`
    padding: 0;
    height: 60px;
    display: flex;
    flex-direction: row;
    cursor: pointer;
    user-select: none;

    .content {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        padding: 0 18px;

        .col {
            display: flex;
            flex-direction: column;
            flex: 1;
            justify-content: center;
        }
    }

    img {
        width: 60px;
        height: 60px;
        object-fit: cover;
        object-position: center center;
    }
`;

const combineBookings = (bookingPages) => {
    let combined = [];

    bookingPages.map((page) => {
        Object.keys(page).map((key) => {
            let booking = page[key];
            combined.push({
                from: `"${booking.from}"`,
                to: `"${booking.to}"`,
                checkedOut: `"${booking.checkedOut}"`,
                totalPrice: booking.totalPrice,
                currency: booking.currency,
                payerTotalPrice: booking.payerTotalPrice,
                payerCurrency: booking.payerCurrency,
                occasionId: booking.occasionId,
                occasionName: `"${booking.occasionName}"`,
                bookedAs: `"${booking.bookedAs}"`,
                userName: `"${booking.userName}"`,
                userId: booking.userId,
                spaceName: `"${booking.spaceName}"`,
                spaceId: booking.spaceId,
                spaceLocationName: `"${booking.spaceLocationName}"`,
                spaceLocationId: booking.spaceLocationId,
                spaceProviderName: `"${booking.spaceProviderName}"`,
                spaceProviderId: booking.spaceProviderId,
                userTeams: `"${booking.userTeams}"`,
            });
        });
    });
    return combined;
};

const BookingIndex = () => {
    const [bookings, loading] = useApiResource('bookings');
    const [from, setFrom] = useState(subMonths(new Date(), 20));
    const [to, setTo] = useState(new Date());

    const [activeBooking, setActiveBooking] = useState(null);

    const [current, setCurrent] = useState(null);
    const [max, setMax] = useState(null);

    const fetchPaginated = async (data, page, time) => {
        const endpoint = resourceEndpoints['bookingExport'];

        return await api(endpoint + '?page=' + page, 'POST', {
            from: format(time.from, 'yyyy-MM-dd'),
            to: format(time.to, 'yyyy-MM-dd'),
        }).then((res) => {
            if (!max) {
                setMax(res.pagination.lastPage);
            }
            setCurrent(res.pagination.currentPage);
            if (res.pagination.currentPage < res.pagination.lastPage) {
                data[page - 1] = { ...res.data };
                return fetchPaginated(
                    data,
                    res.pagination.currentPage + 1,
                    time
                );
            } else {
                data[page - 1] = { ...res.data };
                return data;
            }
        });
    };
    const exportBookings = () => {
        fetchPaginated([], 1, {
            from,
            to,
        }).then((res) => {
            downloadCsv(
                combineBookings(res),
                {
                    to: 'End time',
                    from: 'Start time',
                    bookedAs: 'Booked Type',
                    occasionId: 'Occasion Id',
                    occasionName: 'Occasion Name',
                    checkedOut: 'Checked Out',
                    spaceId: 'Space Id',
                    spaceLocationId: 'SpaceLocation Id',
                    spaceLocationName: 'SpaceLocation Name',
                    spaceName: 'Space Name',
                    spaceProviderId: 'SpaceProvider Id',
                    spaceProviderName: 'SpaceProviderName',
                    totalPrice: 'Total Price',
                    currency: 'Currency',
                    payerTotalPrice: 'Payer Total Price',
                    payerCurrency: 'Payer Currency',
                    userId: 'User Id',
                    userName: 'User Name',
                    userTeams: 'User Teams',
                },
                'export.csv'
            );
        });
    };

    return (
        <AdminScreen
            title="Bookings"
            buttons={[
                <div>
                    Export Start
                    <DatePicker onChange={setFrom} selected={from} />
                </div>,
                <div>
                    Export End
                    <DatePicker onChange={setTo} selected={to} />
                </div>,
                <IconButton
                    key="download"
                    icon={<AddWhiteIcon />}
                    onClick={exportBookings}
                >
                    Generate CSV
                    {max && `- ${current}/${max}`}
                </IconButton>,
            ]}
        >
            {!!bookings && (
                <Bookings>
                    {bookings.map((item, index) => (
                        <div className="col" key={index}>
                            <BookingCard
                                image={item.space && item.space.images[0].url}
                                duration={
                                    item.duration
                                        ? `${item.duration.count} ${item.duration.type}`
                                        : null
                                }
                                time={item.slots}
                                location={
                                    !item.space
                                        ? 'Space deleted'
                                        : item.space.location
                                        ? item.space.location.name
                                        : item.space.address.fullAddress
                                }
                                onClick={() =>
                                    setActiveBooking(bookings[index])
                                }
                            />
                        </div>
                    ))}
                </Bookings>
            )}

            {activeBooking && (
                <SingleBookingView
                    booking={activeBooking}
                    onClose={() => setActiveBooking(null)}
                />
            )}
        </AdminScreen>
    );
};

export default BookingIndex;

const Bookings = styled.div`
    width: calc(100% + 20px);
    position: relative;
    left: -10px;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    > * {
        width: 33.33%;
        padding-left: 10px;
        padding-right: 10px;
    }
`;
