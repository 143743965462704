import { store } from '../redux';
import delayedNavigation from '../routes/delayNavigation';

const api = (endpoint, method, data) =>
    new Promise((resolve, reject) => {
        fetch(`${process.env.REACT_APP_API_URL}${endpoint}`, {
            method: method || 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${store.getState().user.token}`,
            },
            body: data ? JSON.stringify(data) : null,
        })
            .then(async (res) => {
                const data = await res.json();

                if (res.status === 401) {
                    reject();
                    delayedNavigation('/logout');
                }

                if (res.status >= 400) reject({ status: res.status, data });

                resolve(data);
            })
            .catch((err) => {
                reject(err);
            });
    });

export default api;
