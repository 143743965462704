import React, { useState } from 'react';
import styled from 'styled-components';
import Modal from '../../components/Modal';
import Button from '../../components/buttons/Button';
import Title from '../../components/typography/Title';
import Divider from '../../components/misc/Divider';
import Label from '../../components/typography/Label';
import FormController from '../../components/form/FormController';
import Input from '../../components/form/Input';
import ImageInput from '../../components/form/ImageInput';
import { Column, Row } from '../../components/layout/Grid';
import IconInput from '../../components/form/IconInput';
import PhoneIcon from '../../components/icons/PhoneIcon';
import useSaveApiResource from '../../hooks/useSaveApiResource';
import DeleteIcon from '../../components/icons/interface/DeleteIcon';
import CircleButton from '../../components/buttons/CircleButton';
import useDeleteApiResource from '../../hooks/useDeleteApiResource';

const Container = styled.div`
    width: 100%;
`;

const Info = styled.div`
    padding: 12px 0;
    font-size: 18px;
    line-height: 24px;
    margin-top: 6px;
`;

const defaultHostRevenueRate = 85;
const defaultAddOnRate = 0;

const ProviderForm = ({ onClose, edit, onDelete, provider }) => {
    const [name, setName] = useState(
        provider && provider.name ? provider.name : ''
    );
    const [contactName, setContactName] = useState(
        provider && provider.contact_name ? provider.contact_name : ''
    );
    const [contactTelephone, setContactTelephone] = useState(
        provider && provider.contact_telephone ? provider.contact_telephone : ''
    );
    const [logo, setLogo] = useState(
        provider && provider.logo ? provider.logo : null
    );
    const [hostRevenueRate, setHostRevenueRate] = useState(
        provider?.host_revenue_rate ?? defaultHostRevenueRate
    );
    const [addOn, setAddOn] = useState(
        provider?.add_on_rate ?? defaultAddOnRate
    );
    const [sendRequest, requestLoading, validationErrors] = useSaveApiResource(
        'providers',
        {
            onSuccess: () => {
                window.location.reload();
                onClose();
            },
        }
    );

    const inPercentageRange = (value) => value >= 0 && value <= 100;
    const getOwnRevenue = (hostRevenueRate) =>
        inPercentageRange(hostRevenueRate)
            ? 100 - hostRevenueRate
            : defaultAddOnRate;

    const [ownRevenueRate, setOwnRevenue] = useState(
        getOwnRevenue(provider?.host_revenue_rate ?? defaultHostRevenueRate)
    );

    const [requestDelete, deleteLoading] = useDeleteApiResource(
        'providers',
        (success) => onDelete(),
        (fail) => alert('Unable to delete provider')
    );

    const save = () => {
        sendRequest({
            id: edit ? provider.id : null,
            name: name,
            contact_name: contactName,
            contact_telephone: contactTelephone,
            logo_id: logo ? logo.id : null,
            host_revenue_rate: hostRevenueRate,
            add_on_rate: addOn,
        });
    };

    const doDelete = () => {
        const confirmed = window.confirm(
            'Please, make sure all related spaces are deleted or linked to a different provider'
        );

        if (confirmed) requestDelete(provider.id);
    };

    let headerMenu = [
        <Button grey key="save" onClick={save}>
            Save
        </Button>,
    ];

    if (edit) {
        headerMenu = [
            <CircleButton grey key="delete" onClick={doDelete}>
                <DeleteIcon />
            </CircleButton>,
            ...headerMenu,
        ];
    }

    //if (!edit) headerMenu.push(<Button key="next">Next</Button>);

    return (
        <Modal onClose={onClose} headerMenu={headerMenu}>
            <Container>
                <Title withSpace bold>
                    {!edit ? 'New Provider' : 'Edit provider'}
                </Title>
                <Divider />
                <Label as="div" withSpace>
                    About
                </Label>
                <FormController label="Name" error={validationErrors.name}>
                    <Input
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                </FormController>

                <Divider />

                <Label as="div" withSpace>
                    Logo
                </Label>

                <FormController error={validationErrors.logo_id}>
                    <ImageInput
                        value={
                            logo
                                ? {
                                      id: logo.id,
                                      image: logo.url,
                                  }
                                : null
                        }
                        onImageAdded={(image) => {
                            setLogo(image);
                        }}
                        onImageDeleted={(id) => setLogo(null)}
                    />
                </FormController>

                <Divider />

                <Label as="div" withSpace>
                    Revenue
                </Label>

                <Row>
                    <Column span={3}>
                        <FormController
                            label="Host revenue"
                            error={validationErrors?.host_revenue_rate}
                        >
                            <Input
                                value={hostRevenueRate}
                                onChange={(e) => {
                                    setHostRevenueRate(e.target.value);
                                    setOwnRevenue(
                                        getOwnRevenue(e.target.value)
                                    );
                                }}
                            />
                        </FormController>
                    </Column>
                    <Column span={3}>
                        <FormController label="Orbit revenue rate (100 - Host revenue rate)">
                            <Info>{ownRevenueRate}</Info>
                        </FormController>
                    </Column>
                </Row>
                <FormController
                    label="Orbit add-on rate"
                    error={validationErrors?.add_on_rate}
                >
                    <Input
                        value={addOn ?? 0}
                        onChange={(e) => {
                            setAddOn(e.target.value);
                        }}
                    />
                </FormController>

                <Divider />

                <Label as="div" withSpace>
                    Contact
                </Label>
                <Row>
                    <Column span={3}>
                        <FormController
                            label="Full name"
                            error={validationErrors.contact_name}
                        >
                            <Input
                                value={contactName}
                                onChange={(e) => setContactName(e.target.value)}
                            />
                        </FormController>
                    </Column>
                    <Column span={3}>
                        <FormController
                            label="Phone number"
                            error={validationErrors.contact_telephone}
                        >
                            <IconInput
                                value={contactTelephone}
                                onChange={(e) =>
                                    setContactTelephone(e.target.value)
                                }
                                icon={<PhoneIcon />}
                            />
                        </FormController>
                    </Column>
                </Row>
            </Container>
        </Modal>
    );
};

export default ProviderForm;
