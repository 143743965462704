import React from 'react';

const AddWhiteIcon = (props) => (
    <svg
        width="14px"
        height="14px"
        viewBox="0 0 14 14"
        version="1.1"
        {...props}
    >
        <g
            id="Icons"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
        >
            <g
                id="iPhone-11-Pro-Copy-30"
                transform="translate(-494.000000, -317.000000)"
            >
                <rect fill="#FFFFFF" x="0" y="0" width="1061" height="701" />
                <rect
                    id="Black-BG"
                    fill="#000000"
                    x="297"
                    y="300"
                    width="300"
                    height="84"
                />
                <g
                    id="24-px/Iconagraphy/White/Add-icon"
                    transform="translate(489.000000, 312.000000)"
                >
                    <g id="Add-icon-">
                        <g id="Add-icon-/-White">
                            <g id="Add-icon">
                                <rect
                                    id="Bound"
                                    x="0"
                                    y="0"
                                    width="24"
                                    height="24"
                                />
                                <path
                                    d="M12,5 C15.8659932,5 19,8.13400675 19,12 C19,15.8659932 15.8659932,19 12,19 C8.13400675,19 5,15.8659932 5,12 C5,8.13400675 8.13400675,5 12,5 Z M13,8 L11,8 L11,11 L8,11 L8,13 L11,13 L11,16 L13,16 L13,13 L16,13 L16,11 L13,11 L13,8 Z"
                                    id="Combined-Shape"
                                    fill="#FFFFFF"
                                />
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

export default AddWhiteIcon;
