import React from 'react';

const DoorSmallGreyIcon = (props) => (
    <svg
        width="10px"
        height="10px"
        viewBox="0 0 10 10"
        version="1.1"
        {...props}
    >
        <g
            id="Icons"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
        >
            <g
                id="iPhone-11-Pro-Copy-30"
                transform="translate(-70.000000, -228.000000)"
            >
                <rect fill="#FFFFFF" x="0" y="0" width="1061" height="701" />
                <g
                    id="10-px/White/Door"
                    transform="translate(70.000000, 228.000000)"
                >
                    <g id="Door">
                        <rect id="Bound" x="0" y="0" width="10" height="10" />
                        <path
                            d="M0,10 L0,9 L1,9 L1,0 L9,0 L9,9 L10,9 L10,10 L0,10 Z M7,4 L6,4 L6,6 L7,6 L7,4 Z"
                            id="Combined-Shape"
                            fill="#DBDBDB"
                        />
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

export default DoorSmallGreyIcon;
