import React from 'react';
import styled from 'styled-components';
import Header from './Header';

const Container = styled.div`
    display: flex;
    flex: 1;
    min-height: 100vh;
    flex-direction: column;
    align-items: center;
`;

const PageContent = styled.div`
    display: flex;
    flex: 1;
`;

const MaxWidth = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1125px;
    padding: 0 25px;
`;
const MasterLayout = ({ children }) => {
    return (
        <Container>
            <MaxWidth>
                <Header />
                <PageContent>{children}</PageContent>
            </MaxWidth>
        </Container>
    );
};

export default MasterLayout;
