import React from 'react';
import styled, { css } from 'styled-components';
import Card from '../Card';
import Subtitle from '../typography/Subtitle';
import { navigate } from 'hookrouter';
import colors from '../../const/colors';
import ContactCell from '../cells/ContactCell';
import { useSelector } from 'react-redux';

const Container = styled(Card)`
    position: absolute;
    right: 0;
    top: 100%;
    z-index: 10;
    padding: 36px 24px 20px;
    border-radius: 16px;
    box-shadow: 0 8px 24px 0 rgba(0, 0, 0, 0.1);
    min-width: 350px;
`;

const MenuItem = styled(Subtitle)`
    border-bottom: 1px solid ${colors.greyLight};
    padding: 22px 0;
    line-height: 1;
    margin: 0;
    cursor: pointer;
    transition: opacity 0.2s ease;

    ${(props) =>
        props.noBorder &&
        css`
            border-bottom: none;
        `};

    &:hover {
        opacity: 0.5;
    }
`;

const UserDetails = styled(ContactCell)`
    margin-top: 35px;
    padding: 0;
`;

const Menu = ({ close }) => {
    const user = useSelector((state) => state.user);

    const closeAndNavigate = (path) => {
        close();
        navigate(path);
    };

    return (
        <Container>
            <MenuItem bold onClick={() => closeAndNavigate('/admin/provider')}>
                Space providers
            </MenuItem>
            <MenuItem bold onClick={() => closeAndNavigate('/admin/spaces')}>
                Spaces
            </MenuItem>
            <MenuItem bold onClick={() => closeAndNavigate('/admin/bookings')}>
                Bookings
            </MenuItem>
            <MenuItem bold onClick={() => closeAndNavigate('/admin/locations')}>
                Locations
            </MenuItem>
            <MenuItem bold onClick={() => closeAndNavigate('/admin/users')}>
                User management
            </MenuItem>
            <MenuItem bold onClick={() => closeAndNavigate('/admin/settings')}>
                Settings
            </MenuItem>
            {!!user ? (
                <UserDetails
                    name={user.name}
                    subtitle="Curator"
                    buttonText="Log out"
                    onClick={() => closeAndNavigate('/logout')}
                />
            ) : (
                <MenuItem
                    bold
                    onClick={() => closeAndNavigate('/login')}
                    noBorder
                >
                    Login
                </MenuItem>
            )}
        </Container>
    );
};

export default Menu;
