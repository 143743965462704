import { put, takeEvery, call } from 'redux-saga/effects';
import { navigate } from 'hookrouter';

function* login({ type, payload }) {
    try {
        const response = yield call(
            fetch,
            `${process.env.REACT_APP_API_URL}/auth`,
            {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            }
        );

        const responseBody = yield response.json();

        if (responseBody.token) {
            // We update the user in a separate action to avoid redirecting before state is ready
            yield put({
                type: 'user/update',
                payload: {
                    token: responseBody.token,
                    name: responseBody.name,
                    email: responseBody.email,
                },
            });

            yield put({
                type: 'auth/login/success',
                payload: {
                    token: responseBody.token,
                    name: responseBody.name,
                    email: responseBody.email,
                },
            });
        } else {
            yield put({
                type: 'auth/failed',
            });
        }
    } catch (e) {
        yield put({
            type: 'auth/failed',
        });
        console.log('error in saga', e);
    }
}

function* logout() {
    try {
        yield put({
            type: 'REDUX/CLEAR',
        });

        yield navigate('/login');
    } catch (e) {}
}

export default [
    takeEvery('auth/login', login),
    takeEvery('auth/logout', logout),
];
