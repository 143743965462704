import React from 'react';
import styled from 'styled-components';
import useApiResource from '../../hooks/useApiResource';
import CheckMarkIcon from '../icons/interface/CheckMarkIcon';
import colors from '../../const/colors';
import Text from '../typography/Text';

const Container = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: calc(100% + 40px);
    margin-left: -20px;

    > div {
        width: calc(50% - 40px);
        margin-left: 20px;
        margin-right: 20px;
        padding: 18px 0;
        position: relative;
        cursor: pointer;

        display: flex;
        flex-direction: row;
        align-items: center;

        .title {
            display: flex;
            flex: 1;
            margin-left: 18px;
            user-select: none;
        }

        &:after {
            content: '';
            width: calc(100% - 36px);
            position: absolute;
            bottom: 0;
            right: 0;
            background-color: ${colors.greyLight};
            height: 1px;
        }

        &.no-border,
        &:last-child {
            &:after {
                display: none;
            }
        }
    }
`;

const CheckBoxContainer = styled.div`
    width: 18px;
    height: 18px;
    background-color: ${(props) =>
        props.checked ? colors.green : colors.grey};
    display: inline-flex;
    align-items: center;
    justify-content: center;
`;

const CheckBox = ({ checked, ...props }) => (
    <CheckBoxContainer checked={checked} {...props}>
        {checked && <CheckMarkIcon />}
    </CheckBoxContainer>
);

const BoosterSelector = ({ value, onChange }) => {
    const [boosters, boostersLoading] = useApiResource('boosters');
    if (!boosters) return null;

    const toggle = (id) => {
        if (value.find((a) => a.id === id)) {
            onChange(value.filter((a) => a.id !== id));
        } else {
            onChange([...value, { id }]);
        }
    };

    return (
        <Container>
            {boosters.map((booster, index) => {
                const isSelected = !!value.find((a) => a.id === booster.id);
                return (
                    <div
                        key={booster.id}
                        onClick={() => toggle(booster.id)}
                        className={
                            boosters.length % 2 === 0 &&
                            index === boosters.length - 2
                                ? 'no-border'
                                : null
                        }
                    >
                        <CheckBox checked={isSelected} />
                        <Text className="title">{booster.title}</Text>
                    </div>
                );
            })}
        </Container>
    );
};

export default BoosterSelector;
