import React from 'react';
import styled from 'styled-components';

const List = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: calc(100% + 40px);
    margin-left: -20px;

    > * {
        width: 33.33%;
        padding-left: 20px;
        padding-right: 20px;
    }
`;

export default List;
