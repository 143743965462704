import React from 'react';

const BuildingSmallGreyIcon = (props) => (
    <svg
        width="10px"
        height="10px"
        viewBox="0 0 10 10"
        version="1.1"
        {...props}
    >
        <g
            id="Icons"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
        >
            <g
                id="iPhone-11-Pro-Copy-30"
                transform="translate(-48.000000, -228.000000)"
            >
                <rect fill="#FFFFFF" x="0" y="0" width="1061" height="701" />
                <g
                    id="10-px/White/Building"
                    transform="translate(48.000000, 228.000000)"
                >
                    <g id="Building">
                        <rect id="Bound" x="0" y="0" width="10" height="10" />
                        <path
                            d="M5,0 L10,2 L10,10 L4,10 L4,7 L2,7 L2,10 L0,10 L0,2 L5,0 Z M8,7 L6,7 L6,9 L8,9 L8,7 Z M4,3 L2,3 L2,5 L4,5 L4,3 Z M8,3 L6,3 L6,5 L8,5 L8,3 Z"
                            id="Combined-Shape"
                            fill="#DBDBDB"
                        />
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

export default BuildingSmallGreyIcon;
