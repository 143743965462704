import React, { useState } from 'react';
import styled from 'styled-components';
import Modal from '../../components/Modal';
import Title from '../../components/typography/Title';
import Divider from '../../components/misc/Divider';
import Label from '../../components/typography/Label';
import Text from '../../components/typography/Text';
import FormGroup from '../../components/form/FormGroup';
import moment from 'moment';
import CircleButton from '../../components/buttons/CircleButton';
import DeleteIcon from '../../components/icons/interface/DeleteIcon';
import useDeleteApiResource from '../../hooks/useDeleteApiResource';

const LabelRow = styled(({ label, children, withDivider, ...rest }) => (
    <div {...rest}>
        {label && (
            <Label tiny weak as="div">
                {label}
            </Label>
        )}
        {typeof children === 'string' ? <Text>{children}</Text> : children}
        {withDivider && <Divider />}
    </div>
))`
    width: 100%;
    margin-bottom: 18px;
`;

const Row = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row;
`;

const Section = styled(FormGroup)`
    margin-bottom: 30px;
`;

const SingleBookingView = ({ booking, onClose }) => {
    const [requestDelete] = useDeleteApiResource(
        'bookings',
        (success) => onClose && onClose(),
        (fail) => alert('Unable to cancel booking')
    );

    const doDelete = () => {
        const confirmed = window.confirm(
            'Are you sure you want to cancel this booking?'
        );

        if (confirmed) requestDelete(booking.uuid);
    };

    return (
        <Modal
            onClose={onClose}
            headerMenu={[
                <CircleButton grey key="delete" onClick={doDelete}>
                    <DeleteIcon />
                </CircleButton>,
            ]}
        >
            <Container>
                <Title withSpace bold>
                    View booking
                </Title>
                <Divider />

                <Section title="Booking">
                    <Row>
                        <LabelRow label="Reservation time">
                            {booking.slots.map((slot, index) => (
                                <Text key={index}>{slot}</Text>
                            ))}
                        </LabelRow>
                        <LabelRow label="Booking received">
                            {moment(booking.created_at).format(
                                'YYYY-MM-DD HH:mm'
                            )}
                        </LabelRow>
                    </Row>
                </Section>
                <Section title="Customer">
                    <Row>
                        <LabelRow label="Name">
                            {booking?.user?.name || 'User deleted'}
                        </LabelRow>
                        <LabelRow label="Email">
                            {booking?.user?.email || 'USer deleted'}
                        </LabelRow>
                    </Row>
                </Section>

                <Section title="Location">
                    <LabelRow label="Space ID">
                        {booking?.space?.uuid || 'Space deleted'}
                    </LabelRow>
                    <LabelRow label="Address">
                        {booking?.space?.address?.fullAddress ||
                            'Space deleted'}
                    </LabelRow>
                </Section>
            </Container>
        </Modal>
    );
};

export default SingleBookingView;

const Container = styled.div`
    width: 100%;
`;
