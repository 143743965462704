import React, { useCallback } from 'react';
import styled from 'styled-components';
import useApiResource from '../../../hooks/useApiResource';
import SmallCompanyCard from '../../../components/cards/SmallCompanyCard';
import List from '../../../components/List';
import { navigate } from 'hookrouter';

const Container = styled.div`
    width: 100%;
`;

const TeamList = ({ onEdit }) => {
    const [teams, loading] = useApiResource('teams', null, {
        refetch: true,
    });

    return (
        <Container>
            {!!teams && (
                <List>
                    {teams.map((a) => (
                        <div
                            key={a.id}
                            onClick={() => navigate(`/admin/team/${a.id}`)}
                        >
                            <SmallCompanyCard
                                logo={a.logo?.url}
                                userCount={' '}
                                name={a.name}
                            />
                        </div>
                    ))}
                </List>
            )}
        </Container>
    );
};

export default TeamList;
