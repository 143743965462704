import { createStore, applyMiddleware } from 'redux';
import { createLogger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import sagas from './sagas';
import { eventSystemReduxMiddleware } from 'eventsy';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import reducers from './reducers';

const logger = createLogger({
    level: 'info',
});

const sagaMiddleware = createSagaMiddleware();

const persistedReducers = persistReducer(
    {
        key: 'root',
        storage: storage,
        whitelist: ['user'],
    },
    reducers
);

const configureStore = () => {
    const store = createStore(
        persistedReducers,
        applyMiddleware(logger, sagaMiddleware, eventSystemReduxMiddleware)
    );

    if (process.env.NODE_ENV !== 'production') {
        if (module.hot) {
            module.hot.accept('./reducers', () => {
                store.replaceReducer(reducers);
            });
        }
    }

    return store;
};

const configuredStore = configureStore();

export const persistor = persistStore(configuredStore, {}, () => {
    configuredStore.dispatch({
        type: 'app/ready',
        payload: configuredStore.getState(),
    });
});

export default configuredStore;

sagaMiddleware.run(sagas);
