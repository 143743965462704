import React from 'react';
import styled, { css } from 'styled-components';
import colors from '../../const/colors';

const Container = styled.button`
    box-shadow: none;
    background: none;
    border: none;

    height: 32px;
    border-radius: 16px;
    background-color: ${colors.black};
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0 12px;
    cursor: not-allowed;
    transition: background-color 0.15s ease;
    outline: none;

    span {
        font-size: 14px;
        line-height: 1;
        color: white;
        font-weight: 500;
        padding-top: 2px;
    }

    ${(props) =>
        !props.disabled &&
        css`
            cursor: pointer;
            &:hover {
                background-color: #2e2e2e;
            }

            &:active {
                background-color: #3d3d3d;
            }
        `};

    ${(props) =>
        props.grey &&
        css`
            span {
                color: ${colors.black};
            }

            background-color: ${colors.greyLight};

            ${(props) =>
                !props.disabled &&
                css`
                    &:hover {
                        background-color: #e0e0e0;
                    }

                    &:active {
                        background-color: #dbdbdb;
                    }
                `};
        `}

    ${(props) =>
        props.light &&
        css`
            span {
                color: ${colors.greyDark};
            }

            background-color: white;

            ${(props) =>
                !props.disabled &&
                css`
                    &:hover {
                        background-color: white;
                    }

                    &:active {
                        background-color: #f0f0f0;
                    }
                `};
        `}
`;

const Button = ({ children, ...props }) => (
    <Container {...props}>
        {typeof children === 'string' ? <span>{children}</span> : children}
    </Container>
);

export default Button;
