import React, { useCallback, useState } from 'react';
import resourceEndpoints from '../const/resourceEndpoint';
import api from '../services/Api';
import delay from '../utils/delay';
import { dispatch } from '../redux';

const useDeleteApiResource = (resource, onSuccess, onFail) => {
    const [loading, setLoading] = useState(false);

    const sendRequest = useCallback((id, removeLink) => {
        setLoading(true);

        let endpoint = resourceEndpoints[resource];

        api(`${resourceEndpoints[resource]}/${id}/delete`)
            .then((res) => {
                if (resource !== 'invitations' && resource !== 'users') {
                    dispatch('apiData/resource/remove', { resource, id });
                }

                if (removeLink) {
                    dispatch('apiData/resource/removeLink', {
                        resource,
                        link_key: removeLink.link_key,
                        link: removeLink.link,
                    });
                }

                delay(onSuccess);
            })
            .catch(onFail)
            .finally(() => setLoading(false));
    }, []);

    return [sendRequest, loading];
};

export default useDeleteApiResource;
