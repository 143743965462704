import React from 'react';
import styled from 'styled-components';
import PinIcon from '../icons/interface/PinIcon';
import Label from '../typography/Label';
import UserIconTiny from '../icons/interface/UserIconTiny';

const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    svg {
        margin-right: 4px;
    }
`;

const SeatsCell = ({ seats, iconColor, textColor }) => {
    return (
        <Container>
            <UserIconTiny color={iconColor} />
            <Label tiny as="div" color={textColor}>
                {seats}
            </Label>
        </Container>
    );
};

export default SeatsCell;
