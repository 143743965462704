const currencies = [
    {
        code: 'NOK',
        flag: '🇳🇴',
        country: 'NO',
    },
    {
        code: 'SEK',
        flag: '🇸🇪',
        country: 'SE',
    },
    {
        code: 'DKK',
        flag: '🇩🇰',
        country: 'DK',
    },
];

export default currencies;
