import { combineReducers } from 'redux';
import user from './reducers/userReducer';
import apiData from './reducers/apiData';
import app from './reducers/appReducer';
const reducers = combineReducers({
    user,
    app,
    apiData,
});

export default reducers;
