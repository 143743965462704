import React, { useEffect } from 'react';
import { usePath, useRoutes, useRedirect, navigate } from 'hookrouter';
import Login from '../screens/Login';
import Dashboard from '../screens/Dashboard';
import { useSelector } from 'react-redux';
import delayedNavigation from './delayNavigation';
import ProviderIndex from '../screens/Provider/ProviderIndex';
import ProviderSingle from '../screens/Provider/ProviderSingle';
import Settings from '../screens/Settings/Settings';
import Logout from '../screens/Logout';
import SpaceIndex from '../screens/Space/SpaceIndex';
import LocationIndex from '../screens/Location/LocationIndex';
import UserManagement from '../screens/UserManagement/UserManagement';
import BookingIndex from '../screens/Bookings/BookingIndex';
import TeamOverview from '../screens/UserManagement/Team/TeamOverview';
import GroupOverview from '../screens/UserManagement/Group/GroupOverview';

const routes = {
    '/': () => <Login />,
    '/login': () => <Login />,
    '/logout': () => <Logout />,
    '/admin*': () => <AdminRouter />,
};

const adminRoutes = {
    '/': () => <Dashboard />,
    '/provider': () => <ProviderIndex />,
    '/spaces': () => <SpaceIndex />,
    '/locations': () => <LocationIndex />,
    '/provider/:id': ({ id }) => <ProviderSingle id={id} />,
    '/settings': () => <Settings />,
    '/users': () => <UserManagement />,
    '/bookings': () => <BookingIndex />,
    '/team/:id': ({ id }) => <TeamOverview id={parseInt(id)} />,
    '/team/:teamId/:groupId': ({ teamId, groupId }) => (
        <GroupOverview teamId={parseInt(teamId)} groupId={parseInt(groupId)} />
    ),
};

const AdminRouter = () => {
    const token = useSelector((state) => state.user.token);
    const routeResult = useRoutes(adminRoutes);

    if (!token) {
        delayedNavigation('/login');
        return null;
    }

    return routeResult;
};

const Router = () => {
    const hookedRoutes = useRoutes(routes);
    const path = usePath();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [path]);

    return hookedRoutes || 'Not found'; //TODO: Create 404 page
};

export default Router;
