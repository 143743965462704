import React from 'react';
import styled, { css } from 'styled-components';
import colors from '../../const/colors';

const Title = styled.h1`
    font-size: 32px;
    line-height: 1.875;
    font-family: 'Graphik', Helvetica, Areal, serif;
    font-weight: ${(props) => (props.bold ? 500 : 'normal')};
    color: ${(props) =>
        props.color || (props.weak ? colors.greyDark : colors.black)};

    ${(props) =>
        props.withSpace &&
        css`
            margin-top: 24px;
            margin-bottom: 28px;
        `};
`;

export default Title;
