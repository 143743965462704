import React from 'react';
import styled from 'styled-components';
import CheckBox from './CheckBox';
import Text from '../typography/Text';

const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 48px;
    margin-top: 6px;
    margin-bottom: 12px;
    cursor: pointer;

    .title {
        margin-left: 18px;
    }
`;

const CheckBoxInput = ({ checked, label, onChange, ...rest }) => {
    return (
        <Container {...rest} onClick={() => onChange && onChange(!checked)}>
            <CheckBox checked={checked} />
            <Text className="title">{label}</Text>
        </Container>
    );
};

export default CheckBoxInput;
