const resourceEndpoints = {
    providers: '/admin/provider',
    occasions: '/admin/occasion',
    facilities: '/admin/facility',
    customers: '/admin/customer',
    locations: '/admin/location',
    spaces: '/admin/space',
    users: '/admin/user',
    teams: '/admin/team',
    bookings: '/admin/booking',
    bookingExport: '/admin/booking/export',
    boosters: '/admin/booster',
    invitations: '/admin/invitation',
    groups: '/admin/group',
    features: '/admin/feature',
};

export default resourceEndpoints;
