import React from 'react';
import styled from 'styled-components';
import CircleButton from '../buttons/CircleButton';
import ArrowLeftIcon from '../icons/ArrowLeftIcon';
import colors from '../../const/colors';
import Button from '../buttons/Button';

const Container = styled.div`
    padding: 12px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

const EditHeader = ({ onBack, onEdit }) => {
    return (
        <Container>
            <CircleButton
                grey
                onClick={onBack ? onBack : () => window.history.go(-1)}
            >
                <ArrowLeftIcon />
            </CircleButton>
            <Button grey onClick={onEdit}>
                Edit
            </Button>
        </Container>
    );
};

export default EditHeader;
