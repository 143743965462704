import React from 'react';
import styled from 'styled-components';
import Modal from '../../../components/Modal';
import Title from '../../../components/typography/Title';
import Divider from '../../../components/misc/Divider';
import Label from '../../../components/typography/Label';
import FormController from '../../../components/form/FormController';
import useFormState from '../../../hooks/useFormState';
import Input from '../../../components/form/Input';
import ImageInput from '../../../components/form/ImageInput';
import useSaveApiResource from '../../../hooks/useSaveApiResource';
import useDeleteApiResource from '../../../hooks/useDeleteApiResource';
import Button from '../../../components/buttons/Button';
import CircleButton from '../../../components/buttons/CircleButton';
import DeleteIcon from '../../../components/icons/interface/DeleteIcon';

const Container = styled.div``;

const BoosterForm = ({ booster, edit, onDelete, onClose }) => {
    const [formData, updateFormData] = useFormState({
        title: booster && booster.title ? booster.title : '',
        description: booster && booster.description ? booster.description : '',
        price: booster && booster.price ? booster.price : '',
        image: booster && booster.image ? booster.image : null,
    });

    const [sendRequest, requestLoading, validationErrors] = useSaveApiResource(
        'boosters',
        {
            onSuccess: () => onClose(),
        }
    );

    const [requestDelete, deleteLoading] = useDeleteApiResource(
        'boosters',
        () => onDelete(),
        () => alert('Unable to delete provider')
    );

    const save = () => {
        sendRequest({
            ...formData,
            id: edit ? booster.id : null,
            image_id: formData.image ? formData.image.id : null,
        });
    };

    const doDelete = () => {
        const confirmed = window.confirm(
            'Are you sure you want to delete this booster?'
        );

        if (confirmed) requestDelete(booster.id);
    };

    let headerMenu = [
        <Button grey key="save" onClick={save}>
            Save
        </Button>,
    ];

    if (edit) {
        headerMenu = [
            <CircleButton grey key="delete" onClick={doDelete}>
                <DeleteIcon />
            </CircleButton>,
            ...headerMenu,
        ];
    }

    return (
        <Modal onClose={onClose} headerMenu={headerMenu}>
            <Container>
                <Title withSpace bold>
                    {edit ? 'Edit' : 'New'} Booster
                </Title>
                <Divider />
                <Label as="div" withSpace tiny>
                    Title
                </Label>
                <FormController error={validationErrors.title}>
                    <Input
                        value={formData.title}
                        onChange={(e) =>
                            updateFormData('title', e.target.value)
                        }
                    />
                </FormController>
                <FormController
                    label="Description"
                    error={validationErrors.description}
                >
                    <Input
                        as="textarea"
                        value={formData.description}
                        onChange={(e) =>
                            updateFormData('description', e.target.value)
                        }
                    />
                </FormController>
                <FormController label="Price" error={validationErrors.price}>
                    <Input
                        value={formData.price}
                        onChange={(e) =>
                            updateFormData('price', e.target.value)
                        }
                    />
                </FormController>
                <Divider />
                <Label as="div" withSpace>
                    Image
                </Label>

                <FormController error={validationErrors.image_id}>
                    <ImageInput
                        value={
                            formData.image
                                ? {
                                      id: formData.image.id,
                                      image: formData.image.url,
                                  }
                                : null
                        }
                        onImageAdded={(image) => updateFormData('image', image)}
                        onImageDeleted={() => updateFormData('image', null)}
                    />
                </FormController>
            </Container>
        </Modal>
    );
};

export default BoosterForm;
