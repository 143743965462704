import React, { useEffect } from 'react';
import styled from 'styled-components';
import Title from '../components/typography/Title';
import useApiResource from '../hooks/useApiResource';
import { useSelector } from 'react-redux';

const Container = styled.div``;

const Dashboard = () => {
    console.log('dash render');
    //const [providers, loading] = useApiResource('providers');

    return (
        <Container>
            <Title>Dashboard!</Title>
        </Container>
    );
};

export default Dashboard;
