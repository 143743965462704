import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 12px;

    &:last-child {
        margin-right: 0;
    }

    svg {
        margin-right: 4px;
    }
`;

const IconLabel = ({ icon, text }) => {
    return (
        <Container>
            <div className="icon-wrap">{icon}</div>
            <div className="text-wrap">{text}</div>
        </Container>
    );
};

export default IconLabel;
