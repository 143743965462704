import React from 'react';
import styled from 'styled-components';
import useFormState from '../../../hooks/useFormState';
import Modal from '../../../components/Modal';
import Button from '../../../components/buttons/Button';
import DeleteIcon from '../../../components/icons/interface/DeleteIcon';
import CircleButton from '../../../components/buttons/CircleButton';
import Title from '../../../components/typography/Title';
import Divider from '../../../components/misc/Divider';
import useSaveApiResource from '../../../hooks/useSaveApiResource';
import FormController from '../../../components/form/FormController';
import Input from '../../../components/form/Input';
import { Column, Row } from '../../../components/layout/Grid';
import useDeleteApiResource from '../../../hooks/useDeleteApiResource';
import AddressInput from '../../../components/form/AddressInput';
import Label from '../../../components/typography/Label';
import ImageInput from '../../../components/form/ImageInput';
import { navigate } from 'hookrouter';

const Container = styled.div`
    width: 100%;
`;

const TeamForm = ({ team, edit, onClose, onDelete }) => {
    const [formData, updateFormData] = useFormState({
        name: team && team.name ? team.name : '',
        address: team && team.address ? team.address : null,
        contact_email: team && team.contact_email ? team.contact_email : '',
        logo: team && team.logo ? team.logo : null,
    });

    const [sendRequest, requestLoading, validationErrors] = useSaveApiResource(
        'teams',
        {
            onSuccess: onClose,
            onFail: (fail) => console.log('failed', fail),
        }
    );

    const [requestDelete] = useDeleteApiResource(
        'teams',
        (success) => navigate('/admin/users'),
        (fail) => alert('Unable to delete team')
    );

    const save = () => {
        sendRequest({
            ...formData,
            id: edit ? team.id : null,
            logo_id: formData.logo ? formData.logo.id : null,
        });
    };

    const doDelete = () => {
        const confirmed = window.confirm(
            'Are you sure you want to delete this Team?'
        );

        if (confirmed)
            requestDelete(team.id, {
                link_key: 'company_id',
                link: 'company',
            });
    };

    let headerMenu = [
        <Button grey key="save" onClick={save}>
            Save
        </Button>,
    ];

    if (edit) {
        headerMenu = [
            <CircleButton grey key="delete" onClick={doDelete}>
                <DeleteIcon />
            </CircleButton>,
            ...headerMenu,
        ];
    }

    return (
        <Modal onClose={onClose} headerMenu={headerMenu}>
            <Container>
                <Title withSpace bold>
                    {!edit ? 'New Team' : 'Edit Team'}
                </Title>

                <Divider />
                <Row>
                    <Column span={3}>
                        <FormController
                            label="Name"
                            error={validationErrors.name}
                        >
                            <Input
                                value={formData.name}
                                onChange={(e) =>
                                    updateFormData('name', e.target.value)
                                }
                            />
                        </FormController>
                    </Column>
                    <Column span={3}>
                        <FormController
                            label="Contact email"
                            error={validationErrors.contact_email}
                        >
                            <Input
                                value={formData.contact_email}
                                onChange={(e) =>
                                    updateFormData(
                                        'contact_email',
                                        e.target.value
                                    )
                                }
                            />
                        </FormController>
                    </Column>
                </Row>

                <FormController
                    label="Address"
                    error={validationErrors.address}
                >
                    <AddressInput
                        initialValue={
                            formData.address
                                ? formData.address.fullAddress
                                : null
                        }
                        onChange={(e) => updateFormData('address', e)}
                    />
                </FormController>

                <Divider />
                <Label as="div" withSpace>
                    Logo
                </Label>
                <FormController error={validationErrors.logo_id}>
                    <ImageInput
                        value={
                            formData.logo
                                ? {
                                      id: formData.logo.id,
                                      image: formData.logo.url,
                                  }
                                : null
                        }
                        onImageAdded={(image) => {
                            updateFormData('logo', image);
                        }}
                        onImageDeleted={() => updateFormData('logo', null)}
                    />
                </FormController>
            </Container>
        </Modal>
    );
};

export default TeamForm;
