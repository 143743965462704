import React from 'react';
import styled from 'styled-components';
import Label from '../typography/Label';
import PinIconTiny from '../icons/interface/PinIonTiny';

const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    svg {
        margin-right: 4px;
    }
`;

const LocationCell = ({ location, iconColor, textColor }) => {
    return (
        <Container>
            <PinIconTiny color={iconColor} />
            <Label tiny as="div" color={textColor}>
                {location}
            </Label>
        </Container>
    );
};

export default LocationCell;
