import React, { useCallback, useState } from 'react';
import { dispatch } from '../redux';
import delay from '../utils/delay';
import api from '../services/Api';

const useRemoveUserFromTeam = (onSuccess, onFail) => {
    const [loading, setLoading] = useState(false);

    const sendRequest = useCallback((teamId, user) => {
        setLoading(true);

        let endpoint = `/admin/team/${teamId}/remove-user`;

        api(endpoint, 'post', { user_id: user.id })
            .then((res) => {
                const updatedUser = {
                    ...user,
                    teams: user.teams.filter((a) => a.id !== teamId),
                };

                dispatch('apiData/resource/update', {
                    resource: 'users',
                    data: updatedUser,
                });

                delay(onSuccess);
            })
            .catch((fail) => onFail && onFail(fail))
            .finally(() => setLoading(false));
    });

    return [sendRequest, loading];
};

export default useRemoveUserFromTeam;
