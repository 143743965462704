import React from 'react';
import { css } from 'styled-components';
import colors from '../../../const/colors';

export default css`
    box-shadow: none;
    border: none;
    height: 48px;
    padding: 12px 18px 12px;
    background-color: ${colors.greyLight};
    color: ${colors.black};
    font-size: 18px;
    line-height: 24px;
    margin-top: 6px;
    margin-bottom: 12px;
    caret-color: ${colors.green};

    &::placeholder {
        color: #999999;
        font-family: 'Graphik', serif;
    }

    &:hover,
    &:active,
    &:focus {
        background-color: #e0e0e0;
        outline: none;
    }
`;
