import React from 'react';
import styled from 'styled-components';
import Text from '../typography/Text';
import colors from '../../const/colors';
import CheckMarkIcon from '../icons/interface/CheckMarkIcon';

const Container = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: calc(100% + 40px);
    margin-left: -20px;

    > div {
        width: calc(50% - 40px);
        margin-left: 20px;
        margin-right: 20px;
        padding: 18px 0;
        position: relative;
        cursor: pointer;

        display: flex;
        flex-direction: row;
        align-items: center;

        .title {
            display: flex;
            flex: 1;
            margin-left: 18px;
            user-select: none;
        }

        &:after {
            content: '';
            width: calc(100% - 36px);
            position: absolute;
            bottom: 0;
            right: 0;
            background-color: ${colors.greyLight};
            height: 1px;
        }

        &.no-border,
        &:last-child {
            &:after {
                display: none;
            }
        }
    }
`;

const CheckBoxContainer = styled.div`
    width: 18px;
    height: 18px;
    background-color: ${(props) =>
        props.checked ? colors.green : colors.grey};
    display: inline-flex;
    align-items: center;
    justify-content: center;
`;

const CheckBox = ({ checked, ...props }) => (
    <CheckBoxContainer checked={checked} {...props}>
        {checked && <CheckMarkIcon />}
    </CheckBoxContainer>
);

const GroupSelector = ({ groups, value, onChange }) => {
    if (!groups) return null;

    const toggle = (id) =>
        !value.find((val) => val === id)
            ? onChange([...value, id])
            : onChange(value.filter((val) => val !== id));

    return (
        <Container>
            {groups.map((group, index) => (
                <div
                    key={group.id}
                    className={
                        groups.length % 2 === 0 && index === groups.length - 2
                            ? 'no-border'
                            : null
                    }
                    onClick={() => toggle(group.id)}
                >
                    <CheckBox checked={value.includes(group.id)} />
                    <Text className="title">{group.name}</Text>
                </div>
            ))}
        </Container>
    );
};

export default GroupSelector;
