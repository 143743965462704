const defaultState = {};

export default (state = defaultState, { type, payload }) => {
    switch (type) {
        case 'apiData/update':
            return { ...state, ...payload };
        case 'apiData/resource/add':
            let updatedResource = null;
            if (Array.isArray(payload.data)) {
                updatedResource = state[payload.resource]
                    ? [...state[payload.resource], ...payload.data]
                    : [...payload.data];
            } else {
                updatedResource = state[payload.resource]
                    ? [...state[payload.resource], payload.data]
                    : [payload.data];
            }
            return { ...state, [payload.resource]: updatedResource };
        case 'apiData/resource/update':
            return {
                ...state,
                [payload.resource]: state[payload.resource].map((a) => {
                    if (payload.data.uuid && payload.data.uuid !== a.uuid)
                        return a;
                    if (payload.data.id && payload.data.id !== a.id) return a;
                    return { ...a, ...payload.data };
                }),
            };
        case 'apiData/resource/remove':
            return {
                ...state,
                [payload.resource]: state[payload.resource].filter(
                    (a) => a.id !== payload.id && a.uuid !== payload.id
                ),
            };
        case 'apiData/resource/removeLink':
            return {
                ...state,
                [payload.resource]: state[payload.resource].map((a) => ({
                    ...a,
                    [payload.link_key]: null,
                    [payload.link]: null,
                })),
            };
        case 'REDUX/CLEAR':
            return defaultState;
        default:
            return state;
    }
};
