import React from 'react';
import styled from 'styled-components';
import Title from '../typography/Title';
import Divider from '../misc/Divider';
import Tabs from '../buttons/Tabs';

const Container = styled.div`
    width: 100%;
    padding-bottom: 80px;

    .toolbar-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
`;

const AdminTabPage = ({ title, tabs, children, buttons }) => {
    return (
        <Container>
            <Title bold>{title}</Title>
            <div className="toolbar-row">
                {!!tabs && tabs}
                {!!buttons && buttons}
            </div>

            <Divider withSpace />
            {children}
        </Container>
    );
};

export default AdminTabPage;
