import React from 'react';
import styled from 'styled-components';
import MinimalCard from './MinimalCard';
import colors from '../../const/colors';
import Label from '../typography/Label';
import BuildingSmallGreyIcon from '../icons/BuildingSmallGreyIcon';
import DoorSmallGreyIcon from '../icons/DoorSmallGreyIcon';

const Container = styled(MinimalCard)`
    cursor: pointer;
    > div {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    span {
        margin-top: 0;
    }

    .image-wrap {
        width: 24px;
        height: 24px;
        background-color: ${colors.greyLight};
        margin-right: 12px;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center center;
        }
    }

    .spacer {
        display: flex;
        flex: 1;
    }

    .icon-count {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-right: 12px;

        &:last-child {
            margin-right: 0;
        }

        svg {
            margin-right: 4px;
        }
    }
`;

const SmallProviderCard = ({
    logo,
    name,
    locationCount,
    spaceCount,
    ...props
}) => {
    return (
        <Container {...props}>
            <div>
                <div className="image-wrap">
                    {!!logo && <img src={logo} alt={name} />}
                </div>
                <Label small as="span">
                    {name}
                </Label>
                <div className="spacer" />
                <div className="icon-count">
                    <BuildingSmallGreyIcon />
                    <Label tiny as="span">
                        {locationCount}
                    </Label>
                </div>
                <div className="icon-count">
                    <DoorSmallGreyIcon />
                    <Label tiny as="span">
                        {spaceCount}
                    </Label>
                </div>
            </div>
        </Container>
    );
};

export default SmallProviderCard;
