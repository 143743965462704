import React from 'react';

const DownIcon = ({ color, size = 24, ...props }) => (
    <svg width={size} height={size} viewBox="0 0 24 24" {...props}>
        <g
            id="24-px/UI/Drop-down-arrow"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
        >
            <g id="Drop-down-arrow">
                <rect id="Bound" x="0" y="0" width="24" height="24" />
                <polyline
                    id="Path-3"
                    stroke={color || '#000000'}
                    strokeWidth="2"
                    transform="translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000) "
                    points="13.5 7.5 10.5 12 13.5 16.5"
                />
            </g>
        </g>
    </svg>
);

export default DownIcon;
