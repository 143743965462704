import React, { useEffect } from 'react';
import styled from 'styled-components';
import { dispatch } from '../redux';

const Container = styled.div``;

const Logout = () => {
    useEffect(() => {
        dispatch('auth/logout');
    }, []);

    return null;
};

export default Logout;
