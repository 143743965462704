import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Input from './Input';
import Button from '../buttons/Button';
import CircleButton from '../buttons/CircleButton';
import DeleteIcon from '../icons/interface/DeleteIcon';

const Container = styled.div`
    flex-direction: column;
    display: flex;
`;

const Row = styled.div`
    flex-direction: row;
    align-self: stretch;
    display: flex;

    .button-wrap {
        display: flex;
        height: 48px;
        margin-top: 6px;
        align-items: center;
        margin-left: 12px;
    }
`;

const ListInput = ({ onChange, value = [] }) => {
    const handleChange = (index, changedVal) => {
        onChange &&
            onChange(value.map((item, i) => (i === index ? changedVal : item)));
    };

    const removeItem = (index) => {
        let filtered = value.filter((a, i) => i !== index);

        if (filtered.length === 0) filtered = [''];

        onChange && onChange(filtered);
    };

    const addRow = () => {
        if (value.length && !value[value.length - 1]) return;
        onChange && onChange([...value, '']);
    };

    return (
        <Container>
            {value.map((row, index) => (
                <Row key={index}>
                    <Input
                        value={row}
                        onChange={(e) => handleChange(index, e.target.value)}
                    />
                    <div className="button-wrap">
                        <CircleButton
                            grey
                            key="delete"
                            onClick={() => removeItem(index)}
                        >
                            <DeleteIcon />
                        </CircleButton>
                    </div>
                </Row>
            ))}
            <Row>
                <Button grey onClick={addRow}>
                    Add row
                </Button>
            </Row>
        </Container>
    );
};

export default ListInput;
