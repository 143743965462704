import React from 'react';
import styled from 'styled-components';
import MinimalCard from './MinimalCard';
import colors from '../../const/colors';
import Label from '../typography/Label';

const Container = styled(MinimalCard)`
    cursor: pointer;
    > div {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    span {
        margin-top: 0;
    }

    .image-wrap {
        width: 24px;
        height: 24px;
        background-color: ${colors.greyLight};
        margin-right: 12px;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center center;
        }
    }
`;

const SmallLocationCard = ({
    image,
    name,
    locationCount,
    spaceCount,
    ...props
}) => {
    return (
        <Container {...props}>
            <div>
                <div className="image-wrap">
                    {!!image && <img src={image} alt={name} />}
                </div>
                <Label small as="span">
                    {name}
                </Label>
            </div>
        </Container>
    );
};

export default SmallLocationCard;
