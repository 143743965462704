import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    width: ${(props) => (props.size ? `${props.size}px` : '32px')};
    height: ${(props) => (props.size ? `${props.size}px` : '32px')};
    position: relative;

    > * {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
`;

const IconSpacer = ({ size, children }) => {
    return <Container size={size}>{children}</Container>;
};

export default IconSpacer;
