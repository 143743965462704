import React from 'react';
import styled from 'styled-components';
import useFormState from '../../../hooks/useFormState';
import Modal from '../../../components/Modal';
import Button from '../../../components/buttons/Button';
import DeleteIcon from '../../../components/icons/interface/DeleteIcon';
import CircleButton from '../../../components/buttons/CircleButton';
import Title from '../../../components/typography/Title';
import Divider from '../../../components/misc/Divider';
import useSaveApiResource from '../../../hooks/useSaveApiResource';
import FormController from '../../../components/form/FormController';
import Input from '../../../components/form/Input';
import { Column, Row } from '../../../components/layout/Grid';
import CompanySelector from '../../../components/form/CompanySelector';
import LocationSelector from '../../../components/form/LocationSelector';
import useDeleteApiResource from '../../../hooks/useDeleteApiResource';
import usePasswordReset from '../../../hooks/usePasswordReset';
import useRemoveUserFromTeam from '../../../hooks/useRemoveUserFromTeam';

const Container = styled.div`
    width: 100%;
`;

const ViewUser = ({ teamId, user, edit, onClose, onDelete }) => {
    const doDelete = () => {
        const confirmed = window.confirm(
            'Are you sure you want to remove this user from the team?'
        );

        if (confirmed) removeUserFromTeam(teamId, user);
    };

    const [removeUserFromTeam] = useRemoveUserFromTeam(
        (success) => onClose && onClose(),
        (fail) => {
            console.log('fail', fail);
            alert('Unable to remove user from team');
        }
    );

    const headerMenu = [
        <CircleButton grey key="delete" onClick={doDelete}>
            <DeleteIcon />
        </CircleButton>,
    ];

    return (
        <Modal onClose={onClose} headerMenu={headerMenu}>
            <Container>
                <Title withSpace bold>
                    {!edit ? 'New user' : 'View user'}
                </Title>

                <Divider />
                <Row>
                    <Column span={3}>
                        <FormController label="First name">
                            <Input value={user.firstName} readOnly />
                        </FormController>
                    </Column>
                    <Column span={3}>
                        <FormController label="Last name">
                            <Input value={user.lastName} readOnly={true} />
                        </FormController>
                    </Column>
                </Row>
                <Row>
                    <Column span={3}>
                        <FormController label="Phone number">
                            <Input
                                value={user.phoneNumber || ''}
                                readOnly={true}
                            />
                        </FormController>
                    </Column>
                    <Column span={3}>
                        <FormController label="Email">
                            <Input value={user.email || ''} readOnly={true} />
                        </FormController>
                    </Column>
                </Row>
            </Container>
        </Modal>
    );
};

export default ViewUser;
