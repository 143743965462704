import React from 'react';
import styled from 'styled-components';
import Input from './Input';

const Container = styled.div`
    width: 100%;
    position: relative;
    margin-top: 6px;
    margin-bottom: 12px;

    > *:last-child {
        position: absolute;
        left: 12px;
        top: 50%;
        transform: translateY(-50%);
    }
`;

const StyledInput = styled(Input)`
    padding-left: 48px;
    margin-top: 0;
    margin-bottom: 0;
    width: 100%;
`;

const IconInput = ({ icon, ...props }) => {
    return (
        <Container>
            <StyledInput {...props} />
            {icon}
        </Container>
    );
};

export default IconInput;
