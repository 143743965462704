import React, { useCallback, useState } from 'react';
import { dispatch } from '../redux';
import api from '../services/Api';
import resourceEndpoints from '../const/resourceEndpoint';
import delay from '../utils/delay';

const useSaveApiResource = (
    resource,
    { onSuccess, onFail, onValidationError } = {}
) => {
    const [loading, setLoading] = useState(false);
    const [validationErrors, setValidationErrors] = useState({});
    const sendRequest = useCallback(
        (data, { customEndpoint, isUpdate } = {}) => {
            setLoading(true);
            setValidationErrors({});

            const requestIsUpdate = isUpdate || !!(data.id || data.uuid);
            let endpoint = customEndpoint || resourceEndpoints[resource];

            if (requestIsUpdate && !customEndpoint)
                endpoint += `/${data.uuid || data.id}`;

            api(endpoint, 'POST', data)
                .then((res) => {
                    if (resource !== 'invitations' && resource !== 'users') {
                        if (!requestIsUpdate) {
                            dispatch('apiData/resource/add', {
                                resource,
                                data: res,
                            });
                        } else {
                            dispatch('apiData/resource/update', {
                                resource,
                                data: res,
                            });
                        }
                    }

                    // Delay it to allow for setLoading to finish
                    delay(() => onSuccess(res));
                })
                .catch((err) => {
                    if (err.status === 422) {
                        setValidationErrors(err.data.errors);
                        onValidationError && onValidationError(err.data);
                    } else {
                        onFail && onFail(err);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        },
        []
    );

    return [sendRequest, loading, validationErrors];
};

export default useSaveApiResource;
