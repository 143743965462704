import React from 'react';
import styled, { css } from 'styled-components';

export const gutterWidth = 40;

const colWidth = 100 / 6;

export const Grid = styled.div`
    width: 100%;
    position: relative;
`;

export const Row = styled.div`
    display: flex;
    flex-direction: row;
    width: calc(100% + ${gutterWidth}px);
    flex-wrap: wrap;
    position: relative;
    left: -${gutterWidth / 2}px;
`;

export const Column = styled.div`
    display: flex;
    flex-direction: column;
    width: ${(props) => colWidth * props.span || 1}%;
    padding-left: ${gutterWidth / 2}px;
    padding-right: ${gutterWidth / 2}px;

    ${(props) =>
        props.offset &&
        css`
            margin-left: ${props.offset * colWidth}%;
        `};

    @media (max-width: 1024px) {
        width: ${(props) =>
            props.tabletSpan ? colWidth * props.tabletSpan : 100}%;
        margin-left: ${(props) =>
            props.tabletOffset ? props.tabletOffset * colWidth + '%' : 0};
    }

    @media (max-width: 767px) {
        width: 100%;
        margin-left: 0;
    }
`;
