import React, { useState } from 'react';
import styled from 'styled-components';
import useApiResource from '../../hooks/useApiResource';
import useDeleteApiResource from '../../hooks/useDeleteApiResource';
import IconTagResourceRow from './components/IconTagResourceRow';
import AddIcon from '../../components/icons/interface/AddIcon';
import Text from '../../components/typography/Text';
import BoosterForm from './components/BoosterForm';

const Container = styled.div`
    .add-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
        padding: 4px 0;

        svg {
            margin-right: 18px;
        }
    }
`;

const Boosters = () => {
    const [boosters, loading] = useApiResource('boosters');
    const [formVisible, setFormVisible] = useState(false);
    const [edit, setEdit] = useState(false);
    const [deleteRequest] = useDeleteApiResource('boosters');

    if (!boosters) return null;

    return (
        <Container>
            {boosters.map((booster) => (
                <IconTagResourceRow
                    key={booster.id}
                    name={booster.title}
                    onEdit={() => {
                        setEdit(booster);
                        setFormVisible(true);
                    }}
                    onDelete={() => deleteRequest(booster.id)}
                />
            ))}
            <div
                className="add-row"
                onClick={() => {
                    setEdit(false);
                    setFormVisible(true);
                }}
            >
                <AddIcon />
                <Text>Create new</Text>
            </div>
            {formVisible && (
                <BoosterForm
                    edit={!!edit}
                    booster={edit}
                    onClose={() => setFormVisible(false)}
                />
            )}
        </Container>
    );
};

export default Boosters;
