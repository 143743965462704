import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import useApiResource from '../../../hooks/useApiResource';
import colors from '../../../const/colors';
import Label from '../../../components/typography/Label';
import Button from '../../../components/buttons/Button';
import api from '../../../services/Api';

const Container = styled.div``;

const List = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    > div {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 8px 0 16px;
        justify-content: space-between;
        border-bottom: 1px solid ${colors.grey};

        &:last-child {
            border-bottom: none;
        }
    }
`;
const Paginator = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
`;

const Page = styled.div`
    margin: 5px;
    color: ${(props) => (props.isCurrent ? 'blue' : 'black')};
    font-size: 1.25em;
    cursor: pointer;
`;

const UserList = ({ onEdit, formVisible }) => {
    const [users, setUsers] = useState(false);
    const [usersMeta, setUsersMeta] = useState({
        current_page: 1,
    });

    /*useApiResource('', false, {
        customFetcher: () =>
            api('/admin/user', 'GET').then((res) => {
                setUsersMeta(res.meta);
                setUsers(res.data);
            }),
    });*/

    const loadNextUserPage = (page) =>
        api('/admin/user?page=' + page, 'GET').then((res) => {
            setUsersMeta(res.meta);
            setUsers(res.data);
        });

    useEffect(() => {
        if (formVisible === false) {
            loadNextUserPage(usersMeta.current_page);
        }
    }, [formVisible]);

    const userNavigator = () => {
        let pages = [];
        if (usersMeta.last_page === 1) return;
        for (let index = 1; index <= usersMeta.last_page; ++index) {
            pages.push(
                <Page
                    isCurrent={index === usersMeta.current_page}
                    onClick={() => loadNextUserPage(index)}
                    key={index}
                >
                    {index}
                </Page>
            );
        }
        return <Paginator>{pages}</Paginator>;
    };

    return (
        <Container>
            {!!users && (
                <List>
                    {users.map((user) => (
                        <div key={user.id}>
                            <Label as="div">{user.name}</Label>
                            <Button onClick={() => onEdit(user)} grey>
                                Edit
                            </Button>
                        </div>
                    ))}
                </List>
            )}
            {userNavigator()}
        </Container>
    );
};

export default UserList;
