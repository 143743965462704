import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import IconInput from './IconInput';
import PinIcon from '../icons/interface/PinIcon';
import usePlacesAutocomplete, {
    getGeocode,
    getLatLng,
} from 'use-places-autocomplete';
import colors from '../../const/colors';
import Card from '../Card';
import currencies from '../../const/currencies';

const Container = styled.div`
    position: relative;
`;

const SuggestionsList = styled(Card)`
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 100%;
    top: 100%;
    margin-top: -12px;
    left: 0;
    padding: 0;
    z-index: 10;
`;

const Suggestion = styled.div`
    padding: 15px 20px;
    position: relative;
    transition: background-color 0.2s ease;
    cursor: pointer;

    &:hover {
        background-color: ${colors.greyLight};
    }

    &:after {
        content: '';
        display: block;
        position: absolute;
        width: calc(100% - 40px);
        left: 20px;
        height: 1px;
        background-color: ${colors.greyLight};
    }

    &:last-child:after {
        display: none;
    }
`;

const AddressInput = ({ onChange, initialValue }) => {
    const ref = useRef();
    const [parsedSuggestions, setParsedSuggestions] = useState([]);

    const {
        ready,
        value,
        suggestions: { status, data },
        setValue,
        clearSuggestions,
    } = usePlacesAutocomplete({
        requestOptions: {
            componentRestrictions: {
                country: currencies.map((currency) => currency.country),
            },
            language: 'en',
            types: ['address'],
        },
        debounce: 300,
    });

    useEffect(() => {
        if (initialValue) setValue(initialValue, false);
    }, [initialValue]);

    useEffect(() => {
        const promises = [];
        const parsed = [];

        data.forEach((suggestion) => {
            promises.push(
                getGeocode({ address: suggestion.description }).then((res) => {
                    let details = {};

                    if (res && res[0] && res[0].address_components) {
                        details.formatted = res[0].formatted_address;
                        res[0].address_components.forEach((comp) => {
                            if (
                                comp.types &&
                                comp.types.indexOf('street_number') >= 0
                            ) {
                                details.street_number = comp.long_name;
                            }

                            if (
                                comp.types &&
                                comp.types.indexOf('route') >= 0
                            ) {
                                details.street_name = comp.long_name;
                            }

                            if (
                                comp.types &&
                                comp.types.indexOf('postal_town') >= 0
                            ) {
                                details.city = comp.long_name;
                            }

                            if (
                                comp.types &&
                                comp.types.indexOf('locality') >= 0 &&
                                !details.city
                            ) {
                                details.city = comp.long_name;
                            }

                            if (
                                comp.types &&
                                comp.types.indexOf(
                                    'administrative_area_level_2'
                                ) >= 0
                            ) {
                                details.municipality = comp.long_name;
                            }

                            if (
                                comp.types &&
                                comp.types.indexOf(
                                    'administrative_area_level_1'
                                ) >= 0
                            ) {
                                details.county = comp.long_name;
                            }

                            if (
                                comp.types &&
                                comp.types.indexOf('country') >= 0
                            ) {
                                details.country = comp.long_name;
                            }

                            if (
                                comp.types &&
                                comp.types.indexOf('postal_code') >= 0
                            ) {
                                details.postal_code = comp.long_name;
                            }
                        });
                    }

                    parsed.push({
                        ...res[0],
                        details,
                        formatted_address: details.formatted,
                    });
                })
            );
        });

        if (data && data.length) {
            Promise.all(promises).then((res) => {
                setParsedSuggestions(parsed);
            });
        } else {
            setParsedSuggestions([]);
        }
    }, [data]);

    const handleSelect = (suggestion) => {
        setValue(suggestion.formatted_address, false);
        clearSuggestions();

        getLatLng(suggestion)
            .then(({ lat, lng }) => {
                onChange &&
                    onChange({
                        ...suggestion.details,
                        formatted: suggestion.formatted_address,
                        lat,
                        lng,
                    });
            })
            .catch((err) => {
                alert('Unable to resolve position for selected address');
                console.log('err', err);
            });
    };

    return (
        <Container>
            <IconInput
                icon={<PinIcon />}
                value={value}
                onChange={(e) => setValue(e.target.value)}
                disabled={!ready}
            />
            {status === 'OK' && (
                <SuggestionsList>
                    {parsedSuggestions.map((suggestion) => (
                        <Suggestion
                            key={suggestion.place_id}
                            onClick={() => handleSelect(suggestion)}
                        >
                            {suggestion.formatted_address}
                        </Suggestion>
                    ))}
                </SuggestionsList>
            )}
        </Container>
    );
};

export default AddressInput;
