import { all } from 'redux-saga/effects';
import authSaga from './authSaga';
import providerSaga from './providerSaga';
//IMPORT_SAGAS

export default function* () {
    yield all([
        ...authSaga,
        ...providerSaga,
        //COMBINE_SAGAS
    ]);
}
