import React, { useState } from 'react';
import styled from 'styled-components';
import FormController from '../../../components/form/FormController';
import Input from '../../../components/form/Input';
import Button from '../../../components/buttons/Button';
import IconButton from '../../../components/buttons/IconButton';
import AddWhiteIcon from '../../../components/icons/AddWhiteIcon';
import Divider from '../../../components/misc/Divider';
import useSaveApiResource from '../../../hooks/useSaveApiResource';
import api from '../../../services/Api';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const Column = styled.div`
    display: flex;
    flex: 1;
    padding: 0 15px;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    width: calc(100% + 30px);
    position: relative;
    left: -15px;
`;

const ButtonRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
`;

const StyledFormController = styled(FormController)`
    width: 100%;
`;

const defaultInvitationFields = [
    {
        first_name: '',
        last_name: '',
        phone_number: '',
        email: '',
    },
];

const InvitationForm = ({ hasOpenPath, teamId }) => {
    const [invitationFields, setInvitationFields] = useState(
        defaultInvitationFields
    );

    const [openPathLoading, setOpenPathLoading] = useState(false);

    const [sendRequest, requestLoading, validationErrors] = useSaveApiResource(
        'invitations',
        {
            onSuccess: () => setInvitationFields(defaultInvitationFields),
            onFail: (e) => console.log('error', e),
        }
    );

    const updateField = (index, key, value) => {
        setInvitationFields(
            invitationFields.map((item, i) => {
                if (index !== i) return item;
                return {
                    ...item,
                    [key]: value,
                };
            })
        );
    };

    const sendInvite = () => {
        sendRequest(invitationFields.map((a) => ({ ...a, team_id: teamId })));
    };

    const importFromOpenPath = async () => {
        if (openPathLoading) return false;
        const groupId = window.prompt(
            'Enter the OpenPath group id that you want to import users from'
        );

        if (!groupId) return;
        setOpenPathLoading(true);
        try {
            const users = await api(
                `/admin/team/${teamId}/openpath-users/${groupId}`
            );
            if (users && Array.isArray(users) && users.length) {
                setInvitationFields(
                    users.map((op) => ({
                        first_name: op.firstName,
                        last_name: op.lastName,
                        email: op.email,
                        phone_number: '',
                    }))
                );
            }
            setOpenPathLoading(false);

            console.log('OP USERS', users);
        } catch (e) {
            alert('Unable to fetch users from openpath');
            setOpenPathLoading(false);
        }
    };

    return (
        <Container>
            {invitationFields.map((row, index) => (
                <Row key={index}>
                    <Column>
                        <StyledFormController
                            label="First name"
                            error={validationErrors[`${index}.first_name`]}
                        >
                            <Input
                                value={invitationFields[index].first_name}
                                onChange={(e) =>
                                    updateField(
                                        index,
                                        'first_name',
                                        e.target.value
                                    )
                                }
                            />
                        </StyledFormController>
                    </Column>

                    <Column>
                        <StyledFormController
                            label="Last name"
                            error={validationErrors[`${index}.last_name`]}
                        >
                            <Input
                                value={invitationFields[index].last_name}
                                onChange={(e) =>
                                    updateField(
                                        index,
                                        'last_name',
                                        e.target.value
                                    )
                                }
                            />
                        </StyledFormController>
                    </Column>

                    <Column>
                        <StyledFormController
                            label="Phone number"
                            error={validationErrors[`${index}.phone_number`]}
                        >
                            <Input
                                value={invitationFields[index].phone_number}
                                onChange={(e) =>
                                    updateField(
                                        index,
                                        'phone_number',
                                        e.target.value
                                    )
                                }
                            />
                        </StyledFormController>
                    </Column>

                    <Column>
                        <StyledFormController
                            label="Email"
                            error={validationErrors[`${index}.email`]}
                        >
                            <Input
                                value={invitationFields[index].email}
                                onChange={(e) =>
                                    updateField(index, 'email', e.target.value)
                                }
                            />
                        </StyledFormController>
                    </Column>
                </Row>
            ))}

            <ButtonRow>
                <Button
                    grey
                    onClick={importFromOpenPath}
                >{`Import from OpenPath${
                    openPathLoading ? ' (loading)' : ''
                }`}</Button>

                <IconButton
                    icon={<AddWhiteIcon />}
                    onClick={sendInvite}
                >{`Create ${
                    invitationFields.length > 1 ? 'Invites' : 'Invite'
                }`}</IconButton>
            </ButtonRow>
            <Divider />
        </Container>
    );
};

export default InvitationForm;
