import React from 'react';
import styled from 'styled-components';
import Button from '../../../components/buttons/Button';
import Modal from '../../../components/Modal';
import Title from '../../../components/typography/Title';
import Divider from '../../../components/misc/Divider';
import useFormState from '../../../hooks/useFormState';
import Input from '../../../components/form/Input';
import FormController from '../../../components/form/FormController';
import useSaveApiResource from '../../../hooks/useSaveApiResource';
import CircleButton from '../../../components/buttons/CircleButton';
import DeleteIcon from '../../../components/icons/interface/DeleteIcon';
import useDeleteApiResource from '../../../hooks/useDeleteApiResource';
import CustomerSelector from '../../../components/form/CustomerSelector';
import LocationSelector from '../../../components/form/LocationSelector';
import FeatureSelector from '../../../components/form/FeatureSelector';
import ListInput from '../../../components/form/ListInput';

const Container = styled.div``;

const GroupForm = ({ onClose, edit, group, team, userCount }) => {
    const [formData, updateFormData] = useFormState({
        name: group?.name || '',
        openpath_groups: group?.openpath_groups?.length
            ? group.openpath_groups
            : [''],
        experimental: group?.experimental || false,
        team_id: group?.team_id || team.id,
        customer_capability_id: group?.customer_capability_id || null,
        location_id: group?.location_id || null,
        id: edit ? group.id : null,
        features: group?.features.map((val) => val.id) || [],
    });

    const [sendRequest, requestLoading, validationErrors] = useSaveApiResource(
        'groups',
        {
            onSuccess: onClose,
            onFail: (fail) => console.log('on group fail', fail),
        }
    );

    const save = () => {
        sendRequest({
            ...formData,
            openpath_groups:
                formData.openpath_groups.length === 1 &&
                !formData.openpath_groups[0]
                    ? []
                    : formData.openpath_groups,
        });
    };

    const [requestDelete] = useDeleteApiResource(
        'groups',
        (success) => onClose(),
        (fail) => alert('Unable to delete group')
    );

    const doDelete = () => {
        const confirmed = window.confirm(
            'Are you sure you want to delete this Group? It will remove all access that users had from this group'
        );

        if (confirmed) requestDelete(group.id);
    };

    let headerMenu = [
        <Button grey key="save" onClick={save}>
            Save
        </Button>,
    ];

    if (edit)
        headerMenu = [
            <CircleButton grey key="delete" onClick={doDelete}>
                <DeleteIcon />
            </CircleButton>,
            ...headerMenu,
        ];

    return (
        <Modal onClose={onClose} headerMenu={headerMenu}>
            <Container>
                <Title withSpace bold>
                    {!edit ? 'Create group' : 'Edit group'}
                </Title>
                <Divider />

                <FormController error={validationErrors.location_id}>
                    <LocationSelector
                        selectedLocation={formData.location_id}
                        onChange={(id) => updateFormData('location_id', id)}
                    />
                </FormController>
                <Divider withSpace />
                <FormController error={validationErrors.customer_capability_id}>
                    <CustomerSelector
                        selectedCustomer={formData.customer_capability_id}
                        onChange={(id) =>
                            updateFormData('customer_capability_id', id)
                        }
                    />
                </FormController>
                <Divider />
                <FormController label="Name" error={validationErrors.name}>
                    <Input
                        value={formData.name}
                        onChange={(e) => updateFormData('name', e.target.value)}
                    />
                </FormController>

                <FormController
                    label="OpenPath Group IDs"
                    error={validationErrors.openpath_group}
                >
                    <ListInput
                        value={formData.openpath_groups}
                        onChange={(val) =>
                            updateFormData('openpath_groups', val)
                        }
                    />
                </FormController>
                <FormController
                    label={'Features'}
                    error={validationErrors.features}
                >
                    <FeatureSelector
                        value={formData['features']}
                        onChange={(features) =>
                            updateFormData('features', features)
                        }
                    />
                </FormController>
                {/*
                <FormController
                    label="Access to beta"
                    error={validationErrors.experimental}
                >
                    <CheckBox
                        checked={!!formData.experimental}
                        onClick={() =>
                            updateFormData(
                                'experimental',
                                !formData.experimental
                            )
                        }
                    />
                </FormController>
                */}
            </Container>
        </Modal>
    );
};

export default GroupForm;
