import React from 'react';
import IconSpacer from './IconSpacer';

const Icon = ({ color, ...props }) => (
    <svg
        width="14px"
        height="14px"
        viewBox="0 0 14 14"
        version="1.1"
        {...props}
    >
        <g
            id="Icons"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
        >
            <g
                id="24-px/UI/Left-arrow"
                transform="translate(-4.000000, -5.000000)"
            >
                <g id="Left-arrow">
                    <rect id="Bound" x="0" y="0" width="24" height="24" />
                    <polyline
                        id="Path-3"
                        stroke={color || '#000000'}
                        strokeWidth="2"
                        points="10 6 6 12 10 18"
                    />
                    <line
                        x1="6"
                        y1="12"
                        x2="18"
                        y2="12"
                        id="Path-2"
                        stroke={color || '#000000'}
                        strokeWidth="2"
                    />
                </g>
            </g>
        </g>
    </svg>
);

const ArrowLeftIcon = ({ withSpace, ...props }) =>
    withSpace ? (
        <IconSpacer size={typeof withSpace !== 'boolean' ? withSpace : null}>
            <Icon {...props} />
        </IconSpacer>
    ) : (
        <Icon {...props} />
    );

export default ArrowLeftIcon;
