import React, { useEffect } from 'react';
import { dispatch } from '../redux';

const useCustomHeader = (header) => {
    useEffect(() => {
        dispatch('app/header/set', header);

        return () => {
            dispatch('app/header/clear');
        };
    }, []);
};

export default useCustomHeader;
