import React from 'react';
import styled from 'styled-components';
import Button from './Button';

const Container = styled.div`
    display: flex;
    flex-direction: row;

    > * {
        margin-right: 8px;

        &:last-child {
            margin-right: 0;
        }
    }
`;

const Tabs = ({ items, active, onClick }) => {
    const isActive = (item) =>
        active === (typeof item === 'string' ? item : item.key);

    return (
        <Container>
            {!!items &&
                items.map((a) => (
                    <Button
                        key={typeof a === 'string' ? a : a.key}
                        light={!isActive(a)}
                        grey={isActive(a)}
                        onClick={() =>
                            onClick(typeof a === 'string' ? a : a.key)
                        }
                    >
                        {typeof a === 'string' ? a : a.value}
                    </Button>
                ))}
        </Container>
    );
};

export default Tabs;
