import React from 'react';
import { Provider } from 'react-redux';
import { store } from './redux';
import MasterLayout from './components/layout/MasterLayout';
import Router from './routes/Router';
import './App.css';
import { persistor } from './redux/store';
import { PersistGate } from 'redux-persist/es/integration/react';
import useAppStart from './hooks/useAppStart';
import { Helmet } from 'react-helmet';

const App = () => {
    useAppStart();
    return (
        <Provider store={store}>
            <Helmet>
                <script
                    src={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_PLACES_APIKEY}&libraries=places`}
                />
            </Helmet>
            <PersistGate loading={null} persistor={persistor}>
                <MasterLayout>
                    <Router />
                </MasterLayout>
            </PersistGate>
        </Provider>
    );
};

export default App;
