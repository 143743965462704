import React, { useState } from 'react';
import styled from 'styled-components';
import Text from '../../components/typography/Text';
import AddIcon from '../../components/icons/interface/AddIcon';
import useApiResource from '../../hooks/useApiResource';
import IconTagResourceForm from './components/IconTagResourceForm';
import useDeleteApiResource from '../../hooks/useDeleteApiResource';
import IconTagResourceRow from './components/IconTagResourceRow';

const Container = styled.div`
    .add-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
        padding: 4px 0;

        svg {
            margin-right: 18px;
        }
    }
`;

const IconTagResource = ({ resource, resourceName }) => {
    const [resources, loading] = useApiResource(resource);
    const [formVisible, setFormVisible] = useState(false);
    const [edit, setEdit] = useState(false);

    const [deleteRequest] = useDeleteApiResource(resource);

    if (!resources) return null;

    return (
        <Container>
            {resources.map((item) => (
                <IconTagResourceRow
                    key={item.id}
                    icon={item.icon}
                    name={item.name}
                    onEdit={() => {
                        setEdit(item);
                        setFormVisible(true);
                    }}
                    onDelete={() => deleteRequest(item.id)}
                />
            ))}
            <div
                className="add-row"
                onClick={() => {
                    setEdit(false);
                    setFormVisible(true);
                }}
            >
                <AddIcon />
                <Text>Create new</Text>
            </div>

            {formVisible && (
                <IconTagResourceForm
                    resourceName={resourceName}
                    resourceType={resource}
                    edit={!!edit}
                    resource={edit}
                    onClose={() => setFormVisible(false)}
                />
            )}
        </Container>
    );
};

export default IconTagResource;
