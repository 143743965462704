import React from 'react';
import styled from 'styled-components';
import CheckBox from './CheckBox';
import Text from '../typography/Text';
import Button from '../buttons/Button';
import DropDownArrowIcon from '../icons/interface/DropDownArrowIcon';
import Divider from '../misc/Divider';
import Select from 'react-select';
import colors from '../../const/colors';

const Container = styled.div`
    width: 100%;

    .day {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        .row {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;
            height: 32px;
        }

        .name {
            flex: 1;
            margin-left: 18px;
            user-select: none;
        }

        .divider {
            width: calc(100% - 36px);
        }

        .toggle {
            display: flex;
            flex-direction: row;
            align-items: center;
            flex: 1;
            cursor: pointer;
        }
    }
`;

const TimePickerContainer = styled.div`
    margin-left: 8px;
    position: relative;
`;

const selectStyles = {
    control: () => ({
        backgroundColor: colors.greyLight,
        border: 'none',
        borderRadius: '16px',
        height: '32px',
        marginLeft: '8px',
        display: 'flex',
        flexDirection: 'row',
        width: '90px',
        padding: '0 12px',
        cursor: 'pointer',
    }),
    indicatorSeparator: () => ({
        display: 'none',
    }),
    valueContainer: (provided) => ({
        fontFamily: 'Graphik',
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '18px',
        color: colors.black,
        display: 'flex',
        flex: 1,
    }),
    placeholder: (provided) => ({
        ...provided,
        color: colors.black,
    }),
    dropdownIndicator: (provided) => ({
        padding: 0,
        height: '20px',
        width: '20px',
    }),
};

const days = [
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday',
];

const TimePickerSelect = ({ placeholder, value, onChange }) => {
    const hours = [];

    for (let i = 0; i < 24; i++) {
        let hour = i < 10 ? `0${i}` : `${i}`;

        hours.push(`${hour}:00`);
        hours.push(`${hour}:15`);
        hours.push(`${hour}:30`);
        hours.push(`${hour}:45`);
    }

    return (
        <Select
            placeholder={placeholder}
            options={hours.map((h) => ({ value: h, label: h }))}
            styles={selectStyles}
            isSearchable={false}
            value={value ? { label: value, value: value } : null}
            onChange={onChange}
        />
    );
};

const AvailabilitySelector = ({ availability, onChange }) => {
    const onToggle = (day) => {
        const updated = { ...availability };
        if (updated[day]) {
            delete updated[day];
        } else {
            updated[day] = { from: null, to: null };
        }

        onChange && onChange(updated);
    };

    const onTimeChange = (day, key, value) => {
        const updated = { ...availability };
        updated[day][key] = value;

        onChange && onChange(updated);
    };

    return (
        <Container>
            {days.map((day, index) => (
                <div className="day" key={index}>
                    <div className="row">
                        <div className="toggle" onClick={() => onToggle(day)}>
                            <CheckBox checked={!!availability[day]} />
                            <Text className="name">{day}</Text>
                        </div>

                        {!!availability[day] && (
                            <>
                                <TimePickerSelect
                                    placeholder="From"
                                    onChange={(e) =>
                                        onTimeChange(day, 'from', e.value)
                                    }
                                    value={availability[day].from}
                                />
                                <TimePickerSelect
                                    placeholder="To"
                                    onChange={(e) =>
                                        onTimeChange(day, 'to', e.value)
                                    }
                                    value={availability[day].to}
                                />
                            </>
                        )}
                    </div>
                    {index + 1 < days.length && (
                        <Divider className="divider" withSpace />
                    )}
                </div>
            ))}
        </Container>
    );
};

export default AvailabilitySelector;
