import React, { useState } from 'react';
import styled from 'styled-components';
import useApiResource from '../../hooks/useApiResource';
import CheckBox from './CheckBox';
import Text from '../typography/Text';
import DropUpArrowIcon from '../icons/interface/DropUpArrowIcon';
import DropDownArrowIcon from '../icons/interface/DropDownArrowIcon';
import Input from './Input';
import Divider from '../misc/Divider';
import colors from '../../const/colors';

const Container = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: calc(100% + 40px);
    margin-left: -20px;
`;

const FacilityContainer = styled.div`
    width: calc(50% - 40px);
    margin-left: 20px;
    margin-right: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .toggle {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex: 1;
        cursor: pointer;
    }

    .top {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;

        .title {
            margin-left: 18px;
            display: flex;
            flex: 1;
            user-select: none;
        }
    }

    textarea {
        margin-bottom: 0 !important;
        margin-top: 18px;
    }

    .bottom {
        width: calc(100% - 36px);
    }

    .dropdown-toggle {
        cursor: pointer;
    }
`;

const Facility = ({
    title,
    description,
    selected,
    onToggle,
    onDescriptionChange,
}) => {
    const [open, setOpen] = useState(false);

    const toggle = () => {
        setOpen(!selected);
        onToggle();
    };

    return (
        <FacilityContainer>
            <div className="top">
                <div className="toggle" onClick={toggle}>
                    <CheckBox checked={selected} />
                    <Text className="title">{title}</Text>
                </div>
                {selected && (
                    <div
                        className="dropdown-toggle"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? (
                            <DropUpArrowIcon color={colors.grey} />
                        ) : (
                            <DropDownArrowIcon color={colors.grey} />
                        )}
                    </div>
                )}
            </div>
            <div className="bottom">
                {open && (
                    <Input
                        small
                        as="textarea"
                        placeholder="Add comment.."
                        value={description}
                        onChange={(e) => onDescriptionChange(e.target.value)}
                        rows={3}
                    />
                )}
                <Divider
                    className="divider"
                    color={colors.greyLight}
                    withSpace
                />
            </div>
        </FacilityContainer>
    );
};

const FacilitySelector = ({ value, onChange, ...props }) => {
    const [facilities, loading] = useApiResource('facilities');

    const toggle = (id) => {
        if (value.find((a) => a.id === id)) {
            onChange(value.filter((a) => a.id !== id));
        } else {
            onChange([...value, { id, description: '' }]);
        }
    };

    const updateDescription = (id, description) => {
        onChange(
            value.map((a) => {
                if (a.id === id)
                    return {
                        id,
                        description,
                    };

                return a;
            })
        );
    };

    return (
        <Container>
            {!!facilities &&
                facilities.map((facility) => {
                    const formFacility = value.find(
                        (a) => a.id === facility.id
                    );

                    return (
                        <Facility
                            key={facility.id}
                            title={facility.name}
                            selected={!!formFacility}
                            description={
                                formFacility && formFacility.description
                                    ? formFacility.description
                                    : ''
                            }
                            onToggle={() => toggle(facility.id)}
                            onDescriptionChange={(e) =>
                                updateDescription(facility.id, e)
                            }
                        />
                    );
                })}
        </Container>
    );
};

export default FacilitySelector;
