import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    width: 24px;
    height: 24px;
    position: relative;

    svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
`;

const PhoneIcon = ({ color, ...props }) => (
    <Container {...props}>
        <svg width="14px" height="14px" viewBox="0 0 14 14" version="1.1">
            <g
                id="Icons"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
            >
                <g
                    id="24-px/Iconagraphy/Dark/Phone"
                    transform="translate(-6.000000, -4.000000)"
                >
                    <g id="Phone">
                        <rect id="Bound" x="0" y="0" width="24" height="24" />
                        <path
                            d="M10,6 C10.6402892,5.35971075 11.5087078,5 12.4142136,5 L15,5 L15,5 L15,9 L12,10 L12,14 L15,15 L15,19 L12.4142136,19 C11.5087078,19 10.6402892,18.6402892 10,18 C9.35971075,17.3597108 9,16.4912922 9,15.5857864 L9,8.41421356 C9,7.50870783 9.35971075,6.64028925 10,6 Z"
                            id="Rectangle"
                            fill={color || '#000000'}
                            transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) "
                        />
                    </g>
                </g>
            </g>
        </svg>
    </Container>
);

export default PhoneIcon;
