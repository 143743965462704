import React, { useState } from 'react';
import styled from 'styled-components';
import Subtitle from '../../components/typography/Subtitle';
import Text from '../../components/typography/Text';
import CircleButton from '../../components/buttons/CircleButton';
import AddIcon from '../../components/icons/interface/AddIcon';
import SpaceForm from './SpaceForm';
import SpaceCard from '../../components/cards/SpaceCard';

const Container = styled.div`
    width: 100%;

    .space-list-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        justify-content: space-between;
        margin-bottom: 18px;
    }

    .list {
        width: calc(100% + 40px);
        margin-left: -20px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        > div {
            width: calc(33.33% - 40px);
            margin-left: 20px;
            margin-right: 20px;
        }
    }
`;

const SpaceList = ({ provider, spaces }) => {
    const [formIsVisible, setFormIsVisible] = useState(false);
    const [edit, setEdit] = useState(false);

    return (
        <Container>
            <div className="space-list-header">
                <Text bold as="h2">
                    Spaces
                </Text>
                <CircleButton
                    light
                    onClick={() => {
                        setEdit(false);
                        setFormIsVisible(true);
                    }}
                >
                    <AddIcon color="grey" />
                </CircleButton>
            </div>
            {!!spaces && (
                <div className="list">
                    {spaces.map((space) => (
                        <SpaceCard
                            key={space.id}
                            timestamp={space.created_at}
                            published={space.published}
                            occasion={
                                space.occasions
                                    ? space.occasions.find(
                                          (a) => a.pivot.primary
                                      )
                                    : null
                            }
                            seats={space.seats}
                            location={space.location}
                            image={
                                space.images && space.images.length
                                    ? space.images[0].url
                                    : null
                            }
                            onEdit={() => {
                                setEdit(space);
                                setFormIsVisible(true);
                            }}
                        />
                    ))}
                </div>
            )}

            {formIsVisible && (
                <SpaceForm
                    provider={provider}
                    edit={!!edit}
                    space={edit}
                    onClose={() => setFormIsVisible(false)}
                />
            )}
        </Container>
    );
};

export default SpaceList;
