export const mapObject = (obj, callback) => {
    let mapped = {};

    for (let [key, value] of Object.entries(obj)) {
        mapped[key] = callback(value, key);
    }

    return mapped;
};

export const filterObject = (obj, callback) => {
    let filtered = {};

    for (let [key, value] of Object.entries(obj)) {
        if (callback(value, key)) filtered[key] = value;
    }

    return filtered;
};

export const extractObjectKeys = (obj, keys) => {
    let extracted = {};

    for (let [key, value] of Object.entries(obj)) {
        if (keys.indexOf(key) >= 0) extracted[key] = value;
    }

    return extracted;
};
