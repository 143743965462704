import React from 'react';
import styled from 'styled-components';
import useFormState from '../../../hooks/useFormState';
import Modal from '../../../components/Modal';
import Button from '../../../components/buttons/Button';
import DeleteIcon from '../../../components/icons/interface/DeleteIcon';
import CircleButton from '../../../components/buttons/CircleButton';
import Title from '../../../components/typography/Title';
import Divider from '../../../components/misc/Divider';
import useSaveApiResource from '../../../hooks/useSaveApiResource';
import FormController from '../../../components/form/FormController';
import Input from '../../../components/form/Input';
import { Column, Row } from '../../../components/layout/Grid';
import CompanySelector from '../../../components/form/CompanySelector';
import LocationSelector from '../../../components/form/LocationSelector';
import useDeleteApiResource from '../../../hooks/useDeleteApiResource';
import usePasswordReset from '../../../hooks/usePasswordReset';
import PhoneInputBlock from '../../../components/form/PhoneInputBlock';

const Container = styled.div`
    width: 100%;
`;

const UserForm = ({ user, edit, onClose, onDelete }) => {
    const [formData, updateFormData] = useFormState({
        first_name: user && user.firstName ? user.firstName : '',
        last_name: user && user.lastName ? user.lastName : '',
        email: user && user.email ? user.email : '',
        //company_id: user && user.company_id ? user.company_id : null,
        phone_number: user && user.phoneNumber ? user.phoneNumber : '',
    });

    const [sendRequest, requestLoading, validationErrors] = useSaveApiResource(
        'users',
        {
            onSuccess: (response) => {
                if (!edit) {
                    alert(`User created with password: ${response.password}`);
                }

                onClose();
            },
            onFail: (fail) => console.log('failed', fail),
        }
    );

    const [requestDelete, deleteLoading] = useDeleteApiResource(
        'users',
        (success) => onClose && onClose(),
        (fail) => alert(fail.data.message)
    );

    const [requestPasswordReset] = usePasswordReset(
        'users',
        (success) => {
            alert(`Password has been reset to: ${success}`);
        },
        (fail) => alert('Unable to reset password')
    );

    const save = () => {
        const data = { ...formData };

        if (edit && data.email === user.email) {
            delete data.email;
        }

        if (edit && data.phone_number === user.phoneNumber) {
            delete data.phone_number;
        }

        sendRequest({
            ...data,
            id: edit ? user.id : null,
        });
    };

    const doDelete = () => {
        const confirmed = window.confirm(
            'Are you sure you want to delete this user?'
        );

        if (confirmed) requestDelete(user.id);
    };

    const resetPassword = () => {
        const confirmed = window.confirm(
            'Are you sure you want to reset this users password?'
        );

        if (confirmed) requestPasswordReset(user.id);
    };

    let headerMenu = [
        <Button grey key="save" onClick={save}>
            Save
        </Button>,
    ];

    if (edit) {
        headerMenu = [
            /*
            <Button key="password" onClick={resetPassword}>
                Reset password
            </Button>,*/
            <CircleButton grey key="delete" onClick={doDelete}>
                <DeleteIcon />
            </CircleButton>,
            ...headerMenu,
        ];
    }

    return (
        <Modal onClose={onClose} headerMenu={headerMenu}>
            <Container>
                <Title withSpace bold>
                    {!edit ? 'New User' : 'Edit user'}
                </Title>

                <Divider />

                <Row>
                    <Column span={3}>
                        <FormController
                            label="First name"
                            error={validationErrors.first_name}
                        >
                            <Input
                                value={formData.first_name}
                                onChange={(e) =>
                                    updateFormData('first_name', e.target.value)
                                }
                            />
                        </FormController>
                    </Column>
                    <Column span={3}>
                        <FormController
                            label="Last name"
                            error={validationErrors.last_name}
                        >
                            <Input
                                value={formData.last_name}
                                onChange={(e) =>
                                    updateFormData('last_name', e.target.value)
                                }
                            />
                        </FormController>
                    </Column>
                </Row>
                <Row>
                    <Column span={3}>
                        <FormController
                            label="Phone number"
                            error={validationErrors.phone_number}
                        >
                            <PhoneInputBlock
                                value={formData.phone_number}
                                onChange={(e) => {
                                    updateFormData('phone_number', e);
                                }}
                            />
                        </FormController>
                    </Column>
                    <Column span={3}>
                        <FormController
                            label="Email"
                            error={validationErrors.email}
                        >
                            <Input
                                value={formData.email}
                                onChange={(e) =>
                                    updateFormData('email', e.target.value)
                                }
                            />
                        </FormController>
                    </Column>
                </Row>
            </Container>
        </Modal>
    );
};

export default UserForm;
