import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import Logo from '../Logo';
import MenuIcon from '../icons/interface/MenuIcon';
import { useSelector } from 'react-redux';
import Menu from './Menu';
import CloseIcon from '../icons/interface/CloseIcon';
import { navigate } from 'hookrouter';

const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-top: 10px;
    padding-bottom: 15px;
    position: relative;
`;

const MenuButton = styled.button`
    border: none;
    background: none;
    outline: none;
    box-shadow: none;
    cursor: pointer;
`;

const Wrapper = styled.div`
    min-height: 56px;
    width: 100%;
    position: relative;
`;

const Animator = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    transition: all 0.3s ease;

    ${(props) =>
        !props.show &&
        css`
            transform: translateY(-100%);
        `};
`;

const HeaderLogo = styled(Logo)`
    cursor: pointer;
`;

const Header = () => {
    const customHeader = useSelector((state) => state.app.customHeader);
    const [customHeaderCache, setCustomHeaderCache] = useState(null);
    const [showCustom, setShowCustom] = useState(false);
    const [showDefault, setShowDefault] = useState(true);
    const [menuIsOpen, setMenuIsOpen] = useState(false);

    useEffect(() => {
        if (customHeader) setCustomHeaderCache(customHeader);

        if (!showCustom && showDefault && customHeader) {
            setShowDefault(false);
            setTimeout(() => {
                setShowCustom(true);
            }, 300);
        }

        if (showCustom && !showDefault && !customHeader) {
            setShowCustom(false);
            setTimeout(() => {
                setShowDefault(true);
                setCustomHeaderCache(null);
            }, 300);
        }
    }, [customHeader]);

    return (
        <Wrapper>
            <Animator show={showCustom}>{customHeaderCache}</Animator>
            <Animator show={showDefault}>
                <Container>
                    <HeaderLogo onClick={() => navigate('/admin/spaces')} />
                    <MenuButton onClick={() => setMenuIsOpen(!menuIsOpen)}>
                        {menuIsOpen ? <CloseIcon /> : <MenuIcon />}
                    </MenuButton>
                    {menuIsOpen && <Menu close={() => setMenuIsOpen(false)} />}
                </Container>
            </Animator>
        </Wrapper>
    );
};

export default Header;
