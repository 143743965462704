import React from 'react';

const StarredIcon = (props) => (
    <svg
        width="32px"
        height="32px"
        viewBox="0 0 32 32"
        version="1.1"
        {...props}
    >
        <g
            id="Page-1"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
        >
            <g
                id="Desktop-/-Add-space"
                transform="translate(-668.000000, -1188.000000)"
            >
                <g
                    id="Label-cell"
                    transform="translate(345.000000, 1188.000000)"
                >
                    <g
                        id="Icon-button"
                        transform="translate(323.000000, 0.000000)"
                    >
                        <rect
                            id="Rectangle"
                            stroke="#02C697"
                            strokeWidth="2"
                            x="1"
                            y="1"
                            width="30"
                            height="30"
                            rx="15"
                        />
                        <g
                            id="24-px/Iconagraphy/Dark/Star"
                            transform="translate(4.000000, 4.000000)"
                        >
                            <g id="Star">
                                <rect
                                    id="Bound"
                                    x="0"
                                    y="0"
                                    width="24"
                                    height="24"
                                />
                                <polygon
                                    fill="#000000"
                                    points="12 15.6072824 6.70993273 19.2811529 8.5692706 13.1147116 3.44049135 9.21884705 9.87969262 9.08164726 12 3 14.1203074 9.08164726 20.5595086 9.21884705 15.4307294 13.1147116 17.2900673 19.2811529"
                                />
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

export default StarredIcon;
