import React, { useState } from 'react';
import styled from 'styled-components';
import AdminScreen from '../../components/templates/AdminScreen';
import IconButton from '../../components/buttons/IconButton';
import AddWhiteIcon from '../../components/icons/AddWhiteIcon';
import useApiResource from '../../hooks/useApiResource';
import LocationForm from './LocationForm';
import SmallLocationCard from '../../components/cards/SmallLocationCard';

const Container = styled.div``;

const List = styled.div`
    width: calc(100% + 40px);
    margin-left: -20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    > div {
        width: calc(33.33% - 40px);
        margin-left: 20px;
        margin-right: 20px;
    }
`;

const LocationIndex = () => {
    const [formIsVisible, setFormIsVisible] = useState(false);
    const [edit, setEdit] = useState(false);

    const [locations, loading] = useApiResource('locations');

    return (
        <AdminScreen
            title="Locations"
            buttons={[
                <IconButton
                    key="add"
                    icon={<AddWhiteIcon />}
                    onClick={() => {
                        setEdit(false);
                        setFormIsVisible(true);
                    }}
                >
                    Add
                </IconButton>,
            ]}
        >
            {!!locations && (
                <List>
                    {locations.map((location) => (
                        <SmallLocationCard
                            key={location.id}
                            name={location.name}
                            image={
                                location.images && location.images[0]
                                    ? location.images[0].url
                                    : null
                            }
                            onClick={() => {
                                setEdit(location);
                                setFormIsVisible(true);
                            }}
                        />
                    ))}
                </List>
            )}

            {formIsVisible && (
                <LocationForm
                    location={edit}
                    edit={!!edit}
                    onClose={() => setFormIsVisible(false)}
                />
            )}
        </AdminScreen>
    );
};

export default LocationIndex;
