import React from 'react';
import styled, { css } from 'styled-components';
import inputStyle from './styles/inputStyle';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

const Container = styled.input`
    ${inputStyle};

    ${(props) =>
        props.as === 'textarea' &&
        css`
            height: auto;
            width: 100% !important;
        `};

    ${(props) =>
        props.small &&
        css`
            font-size: 14px;
            padding: 10px 18px;
        `};
`;

const PhoneInputBlock = ({ value, onChange, disabled }) => {
    return (
        <PhoneInput
            value={value}
            defaultCountry="NO"
            onChange={onChange}
            disabled={disabled}
            addInternationalOption={false}
            inputComponent={Container}
        />
    );
};

export default PhoneInputBlock;
