import React from 'react';
import styled, { css } from 'styled-components';
import inputStyle from './styles/inputStyle';

const Container = styled.input`
    ${inputStyle};

    ${(props) =>
        props.as === 'textarea' &&
        css`
            height: auto;
            width: 100% !important;
        `};

    ${(props) =>
        props.small &&
        css`
            font-size: 14px;
            padding: 10px 18px;
        `};
`;

const Input = (props) => {
    return <Container {...props} />;
};

export default Input;
