import React, { useState } from 'react';
import AdminTabPage from '../../components/templates/AdminTabPage';
import Tabs from '../../components/buttons/Tabs';
import UserList from './User/UserList';
import IconButton from '../../components/buttons/IconButton';
import AddWhiteIcon from '../../components/icons/AddWhiteIcon';
import UserForm from './User/UserForm';
import TeamList from './Team/TeamList';
import TeamForm from './Team/TeamForm';

import AccessDebug from './Access/AccessDebug';
import TeamIcon from '../../components/icons/TeamIcon';
import api from '../../services/Api';

const UserManagement = () => {
    const [activeTab, setActiveTab] = useState('Teams');
    const [formVisible, setFormVisible] = useState(false);
    const [edit, setEdit] = useState(false);
    const [accessIssues, setAccessIssues] = useState([]);

    const openNewForm = () => {
        setEdit(false);
        setFormVisible(true);
    };

    const openEditForm = (editable) => {
        setEdit(editable);
        setFormVisible(true);
    };

    const handleAccessScan = async () => {
        setAccessIssues([]);
        try {
            const accessIssues = await api(`/admin/access/verify-teams`);
            setAccessIssues(accessIssues.notEnoughAccess);
        } catch (e) {
            console.log('Error fetching access issues', e.message());
        }
    };

    return (
        <AdminTabPage
            title="User management"
            tabs={
                <Tabs
                    //items={['Teams', 'Users', 'Access Debug']} disabled until fixed
                    items={['Teams', 'Users']}
                    active={activeTab}
                    onClick={setActiveTab}
                />
            }
            buttons={
                activeTab === 'Access Debug' ? (
                    <IconButton
                        icon={<TeamIcon invert />}
                        onClick={handleAccessScan}
                    >
                        Scan for Access issues
                    </IconButton>
                ) : (
                    <IconButton icon={<AddWhiteIcon />} onClick={openNewForm}>
                        Add{' '}
                        {activeTab === 'Users'
                            ? 'user'
                            : activeTab === 'Teams'
                            ? 'team'
                            : 'invitation'}
                    </IconButton>
                )
            }
        >
            {activeTab === 'Teams' && (
                <>
                    <TeamList onEdit={openEditForm} />
                    {formVisible && (
                        <TeamForm
                            onClose={() => setFormVisible(false)}
                            edit={!!edit}
                            company={edit}
                        />
                    )}
                </>
            )}
            {activeTab === 'Users' && (
                <>
                    <UserList formVisible={formVisible} onEdit={openEditForm} />
                    {formVisible && (
                        <UserForm
                            onClose={() => setFormVisible(false)}
                            edit={!!edit}
                            user={edit}
                        />
                    )}
                </>
            )}
            {activeTab === 'Access Debug' && (
                <AccessDebug
                    setIssues={(val) => setAccessIssues(val)}
                    issues={accessIssues}
                />
            )}
        </AdminTabPage>
    );
};

export default UserManagement;
