import React from 'react';
import styled, { css } from 'styled-components';
import Label from '../typography/Label';
import colors from '../../const/colors';
import ValidationErrors from './ValidationErrors';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: 6px;
    padding-bottom: ${(props) => (props.noPaddingBottom ? 0 : '12px')};

    ${(props) =>
        props.hasError &&
        css`
            input,
            textarea,
            .dropzone {
                border-bottom: 2px solid ${colors.red};
            }
        `};
`;

const FormController = ({ label, children, largeLabel, error, ...props }) => {
    return (
        <Container hasError={!!error} {...props}>
            {!!label && (
                <Label small={!largeLabel} withSpace={largeLabel}>
                    {label}
                </Label>
            )}
            {children}
            {!!error && <ValidationErrors errors={error} />}
        </Container>
    );
};

export default FormController;
