import React, { useState } from 'react';
import styled from 'styled-components';
import useFormState from '../../../hooks/useFormState';
import Modal from '../../../components/Modal';
import Button from '../../../components/buttons/Button';
import DeleteIcon from '../../../components/icons/interface/DeleteIcon';
import CircleButton from '../../../components/buttons/CircleButton';
import Title from '../../../components/typography/Title';
import Divider from '../../../components/misc/Divider';
import useSaveApiResource from '../../../hooks/useSaveApiResource';
import FormController from '../../../components/form/FormController';
import Input from '../../../components/form/Input';
import { Column, Row } from '../../../components/layout/Grid';
import GroupSelector from '../../../components/form/GroupSelector';
import CheckBoxInput from '../../../components/form/CheckBoxInput';
import useDeleteApiResource from '../../../hooks/useDeleteApiResource';
import api from '../../../services/Api';
import PhoneInputBlock from '../../../components/form/PhoneInputBlock';

const Container = styled.div`
    width: 100%;

    .user-row {
        position: relative;

        .delete-row-button {
            position: absolute;
            right: -60px;
            top: 56%;
            transform: translateY(-50%);
        }
    }
`;

const TeamUserForm = ({
    team,
    isInvite,
    user,
    groups,
    edit,
    onClose,
    onDelete,
}) => {
    const [formData, updateFormData] = useFormState({
        users: [
            {
                name: user?.name || '',
                email: user?.email || '',
                phoneNumber: user?.phoneNumber || '',
            },
        ],
        teamAdmin:
            edit && !isInvite
                ? user.teams.find((t) => t.id === team.id).pivot?.role ===
                  'admin'
                : user?.role
                ? user.role === 'admin'
                : false,
        groups: user
            ? isInvite
                ? user.groups
                : (user.groups || []).map((a) => a.id)
            : [],
        notifyUser: edit && isInvite ? user.notifyUser : true,
    });

    const [sendRequest, requestLoading, validationErrors] = useSaveApiResource(
        'users',
        {
            onSuccess: (response) => {
                if (!edit) {
                    alert(`User created with password: ${response.password}`);
                }

                onClose();
            },
            onFail: (fail) => console.log('failed', fail),
        }
    );

    const [acceptInvite, acceptInviteLoading] = useSaveApiResource('users', {
        onSuccess: (response) => {
            window.location.reload();
            onClose && onClose();
        },
        onFail: (fail) =>
            alert(
                'Could not accept invitation, can only accept invitations for users who exist in the system'
            ),
    });

    const [deleteInvitation, deleteInvitationLoading] = useDeleteApiResource(
        'invitations',
        (success) => onClose && onClose(),
        (failed) => alert('Unable to delete space')
    );

    const [
        saveInvitation,
        saveInvitationLoading,
        saveInvitationValidationErrors,
    ] = useSaveApiResource('invitations', {
        onSuccess: onClose,
        onFail: () => alert('Unable to create invitation'),
    });

    const getValidationError = (key, index) => {
        if (isInvite)
            return saveInvitationValidationErrors?.[`people.${index}.${key}`];
        return null;
    };

    const [importLoading, setImportLoading] = useState(false);

    const save = () => {
        const data = { ...formData };

        if (edit && data.email === user.email) {
            delete data.email;
        }

        if (edit && data.phone_number === user.phoneNumber) {
            delete data.phone_number;
        }

        if (isInvite && edit) {
            saveInvitation({
                uuid: user.uuid,
                groups: formData.groups,
                role: formData.teamAdmin ? 'admin' : 'member',
                notifyUser: formData.notifyUser,
            });
            return;
        }

        if (isInvite) {
            saveInvitation({
                team_id: team.id,
                groups: formData.groups,
                role: formData.teamAdmin ? 'admin' : 'member',
                people: formData.users,
                notifyUser: formData.notifyUser,
            });
            return;
        }

        sendRequest(
            {
                id: user.id,
                groups: formData.groups,
                role: formData.teamAdmin ? 'admin' : 'member',
            },
            {
                customEndpoint: `/admin/team/${team.id}/user/${user.id}`,
                isUpdate: true,
            }
        );
    };

    const reSend = () => {
        saveInvitation(
            {
                uuid: user.uuid,
                groups: formData.groups,
                role: formData.teamAdmin ? 'admin' : 'member',
            },
            {
                customEndpoint: `/admin/invitation/${user.uuid}/resend`,
                isUpdate: true,
            }
        );
    };

    const forceAcceptInvite = () => {
        acceptInvite(null, {
            customEndpoint: `/admin/invitation/${user.uuid}/accept`,
            isUpdate: true,
        });
    };

    const doDelete = () => {
        const confirmed = window.confirm(
            `Are you sure you want to delete this ${
                isInvite ? 'Invitation' : 'User'
            }`
        );

        if (!confirmed) return;

        if (isInvite) {
            deleteInvitation(user.uuid);
        } else {
            sendRequest(null, {
                customEndpoint: `/admin/team/${team.id}/user/${user.id}/remove`,
                isUpdate: true,
            });
        }
    };

    const removeUserRow = (index) => {
        updateFormData(
            'users',
            formData.users.filter((a, i) => i !== index)
        );
    };

    const importOpenPath = async () => {
        if (importLoading) return false;

        const groupId = window.prompt(
            'Enter the OpenPath GroupID you want to import from'
        );
        if (!groupId) return false;

        setImportLoading(true);

        try {
            const opUsers = await api(
                `/admin/team/${team.id}/openpath-users/${groupId}`
            );

            console.log('opusers', opUsers);

            if (opUsers && Array.isArray(opUsers) && opUsers.length) {
                updateFormData(
                    'users',
                    opUsers.map((a) => ({
                        name: `${a.firstName}${
                            a.lastName ? ' ' + a.lastName : ''
                        }`,
                        email: a.email,
                        phoneNumber: '',
                    }))
                );
            } else {
                alert('No new users found');
            }

            setImportLoading(false);
        } catch (e) {
            alert('Unable to import users from openpath');
            setImportLoading(false);
        }
    };

    let headerMenu = [
        <Button grey key="save" onClick={save}>
            Save
        </Button>,
    ];

    if (!edit && isInvite) {
        headerMenu = [
            ...headerMenu,
            <Button grey key="op" onClick={importOpenPath}>
                Import from OpenPath
            </Button>,
        ];
    }

    if (edit && isInvite) {
        headerMenu = [
            ...headerMenu,
            <Button grey key="resend" onClick={reSend}>
                Resend
            </Button>,
            <Button grey key="accept" onClick={forceAcceptInvite}>
                Force Accept
            </Button>,
        ];
    }

    if (edit) {
        headerMenu = [
            ...headerMenu,
            <CircleButton key="delete" onClick={doDelete} grey>
                <DeleteIcon />
            </CircleButton>,
        ];
    }

    const updateUserRow = (fieldKey, value, index) => {
        updateFormData(
            'users',
            formData.users.map((a, i) => {
                if (i !== index) return a;
                return {
                    ...a,
                    [fieldKey]: value,
                };
            })
        );
    };

    return (
        <Modal onClose={onClose} headerMenu={headerMenu}>
            <Container>
                <Title withSpace bold>
                    {!edit
                        ? 'New Invitation'
                        : isInvite
                        ? 'Edit invitation'
                        : 'Edit membership'}
                </Title>
                {formData.users.length > 1 && (
                    <>
                        <Divider />
                        <FormController label="Groups">
                            <GroupSelector
                                groups={groups}
                                value={formData.groups}
                                onChange={(e) => updateFormData('groups', e)}
                            />
                        </FormController>
                    </>
                )}
                {formData.users.map((a, index) => (
                    <div className="user-row" key={index}>
                        <Divider />

                        {formData.users.length > 1 && (
                            <CircleButton
                                key="delete"
                                onClick={() => removeUserRow(index)}
                                grey
                                className="delete-row-button"
                            >
                                <DeleteIcon />
                            </CircleButton>
                        )}

                        <FormController
                            label="Full name"
                            error={getValidationError('name', index)}
                        >
                            <Input
                                value={formData.users[index].name}
                                onChange={(e) =>
                                    updateUserRow('name', e.target.value, index)
                                }
                                disabled={edit}
                            />
                        </FormController>
                        <Row>
                            <Column span={3}>
                                <FormController
                                    label="Email"
                                    error={getValidationError('email', index)}
                                >
                                    <Input
                                        value={formData.users[index].email}
                                        onChange={(e) =>
                                            updateUserRow(
                                                'email',
                                                e.target.value,
                                                index
                                            )
                                        }
                                        disabled={edit}
                                    />
                                </FormController>
                            </Column>
                            <Column span={3}>
                                <FormController
                                    label="Phone number"
                                    error={getValidationError(
                                        'phoneNumber',
                                        index
                                    )}
                                >
                                    <PhoneInputBlock
                                        value={
                                            formData.users[index].phoneNumber
                                        }
                                        onChange={(e) => {
                                            updateUserRow(
                                                'phoneNumber',
                                                e,
                                                index
                                            );
                                        }}
                                        disabled={edit}
                                    />
                                </FormController>
                            </Column>
                        </Row>
                    </div>
                ))}
                {!!(edit && isInvite) && (
                    <FormController label="Invitation code">
                        <Input value={user?.invitationCode} readOnly={true} />
                    </FormController>
                )}
                {formData.users.length <= 1 && (
                    <>
                        <FormController label="Notify user">
                            <CheckBoxInput
                                checked={!formData.notifyUser}
                                label="Invite silently"
                                onChange={(e) =>
                                    updateFormData('notifyUser', !e)
                                }
                            />
                        </FormController>

                        <FormController label="Team Admin">
                            <CheckBoxInput
                                checked={formData.teamAdmin}
                                label="Assign Team Admin access"
                                onChange={(e) => updateFormData('teamAdmin', e)}
                            />
                        </FormController>

                        <FormController
                            label="Groups"
                            error={validationErrors.groups}
                        >
                            <GroupSelector
                                groups={groups}
                                value={formData.groups}
                                onChange={(e) => updateFormData('groups', e)}
                            />
                        </FormController>
                    </>
                )}
            </Container>
        </Modal>
    );
};

export default TeamUserForm;
