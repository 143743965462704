import React from 'react';
import styled, { css } from 'styled-components';
import colors from '../../const/colors';

const Label = styled.label`
    font-size: 18px;
    line-height: 1.33;
    font-family: 'Graphik', Helvetica, Areal, serif;
    font-weight: 500;
    color: ${(props) =>
        props.color || (props.weak ? colors.greyDark : colors.black)};

    ${(props) =>
        props.small &&
        css`
            font-size: 14px;
            line-height: 1.285;
        `};

    ${(props) =>
        props.tiny &&
        css`
            font-size: 12px;
            line-height: 1.5;
        `};

    ${(props) =>
        props.withSpace &&
        css`
            margin-bottom: 18px;
        `}
    ${(props) =>
        props.smallSpace &&
        css`
            margin-bottom: 12px;
        `};
`;

export default Label;
