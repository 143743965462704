import FormController from './FormController';
import IconInput from './IconInput';
import PhoneIcon from '../icons/PhoneIcon';
import Input from './Input';
import FormGroup from './FormGroup';
import React from 'react';

const ContactInformation = ({
    contactInformation,
    validationErrors,
    onChange,
}) => {
    const updateValue = (value, field) => {
        const updatedContactInformation = contactInformation;
        updatedContactInformation[field] = value;
        onChange && onChange(updatedContactInformation);
    };

    return (
        <FormGroup title="Support">
            <FormController
                label="Phone number"
                error={validationErrors?.['contact_information.phone']}
            >
                <IconInput
                    icon={<PhoneIcon />}
                    value={contactInformation.phone}
                    onChange={(e) => updateValue(e.target.value, 'phone')}
                />
            </FormController>
            <FormController
                label="E-mail"
                error={validationErrors?.['contact_information.email']}
            >
                <Input
                    value={contactInformation.email}
                    onChange={(e) => updateValue(e.target.value, 'email')}
                />
            </FormController>
            <FormController
                label="Host chat url"
                error={validationErrors?.['contact_information.chat']}
            >
                <Input
                    value={contactInformation.chat}
                    onChange={(e) => updateValue(e.target.value, 'chat')}
                />
            </FormController>
        </FormGroup>
    );
};

export default ContactInformation;
