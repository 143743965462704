import React from 'react';
import api from '../services/Api';
import fileToBase64 from '../utils/fileToBase64';

const useImageUpload = () => {
    const uploader = async (id, file, onSuccess, onError) => {
        const b64 = await fileToBase64(file);

        api('/admin/image', 'POST', {
            name: file.name,
            image: b64,
        })
            .then((res) => {
                onSuccess(res);
            })
            .catch((err) => {
                onError(err);
            });
    };

    return uploader;
};

export default useImageUpload;
