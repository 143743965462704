import React from 'react';

const CheckMarkIcon = ({ color, ...props }) => (
    <svg width="12px" height="9px" viewBox="0 0 12 9" version="1.1" {...props}>
        <g
            id="Icons"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
        >
            <g
                id="24-px/UI/Check-list-active"
                transform="translate(-6.000000, -7.000000)"
                stroke={color || '#FFFFFF'}
                strokeWidth="2"
            >
                <g id="Check-list-active">
                    <polyline id="Path" points="7.5 11.5 10.5 14.5 16.5 8.5" />
                </g>
            </g>
        </g>
    </svg>
);

export default CheckMarkIcon;
