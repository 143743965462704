import React, { useCallback, useState } from 'react';
import styled, { css } from 'styled-components';
import { useDropzone } from 'react-dropzone';
import randomString from '../../utils/randomString';
import IconButton from '../buttons/IconButton';
import AddIcon from '../icons/interface/AddIcon';
import colors from '../../const/colors';
import CircleButton from '../buttons/CircleButton';
import DeleteIcon from '../icons/interface/DeleteIcon';
import useImageUpload from '../../hooks/useImageUpload';
import fileToBase64 from '../../utils/fileToBase64';
import SortableImage from './SortableImage';

const Container = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: calc(100% + 12px);
    margin-left: -6px;
    margin-bottom: 12px;

    .pad-space {
        padding-left: 6px;
        padding-right: 6px;
    }
`;

const allowedMimes = [
    'image/jpeg',
    'image/png',
    'image/jpg',
    'image/gif',
    'image/svg+xml',
];

const UploadCard = styled(({ children, ...props }) => {
    return (
        <div {...props}>
            {children}
            <CircleButton light small>
                <AddIcon color={colors.greyDark} />
            </CircleButton>
        </div>
    );
})`
    width: 120px;
    height: 120px;
    position: relative;
    background: #ffffff;
    box-shadow: 0 8px 24px 0 rgba(0, 0, 0, 0.1);
    padding: 12px;

    ${(props) =>
        props.wide &&
        css`
            width: 160px;
        `};

    button {
        position: absolute;
        right: 12px;
        bottom: 12px;
        pointer-events: none;
    }

    .dropzone {
        position: absolute;
        outline: none;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        cursor: pointer;
    }
`;

const ImageCard = styled(({ onDelete, coverImage, wide, image, ...props }) => {
    return (
        <div {...props}>
            <CircleButton light small onClick={onDelete}>
                <DeleteIcon />
            </CircleButton>
            <img
                src={image}
                alt="logo"
                className={coverImage ? 'cover' : null}
            />
        </div>
    );
})`
    width: 120px;
    height: 120px;
    position: relative;
    background: #ffffff;
    box-shadow: 0 8px 24px 0 rgba(0, 0, 0, 0.1);

    ${(props) =>
        props.wide &&
        css`
            width: 160px;
        `};

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center center;

        &.cover {
            object-fit: cover;
        }
    }

    button {
        position: absolute;
        right: 12px;
        bottom: 12px;
    }
`;

const ImageInput = ({
    value,
    multiple,
    onImageAdded,
    onImageDeleted,
    coverImage,
    wide,
    sortable,
    onSort,
}) => {
    const [previewImages, setPreviewImages] = useState([]);
    const [errorMessages, setErrorMessages] = useState([]);
    const uploader = useImageUpload();

    const onDrop = useCallback(
        (acceptedFiles) => {
            let newPreviews = [];
            let newErrors = [];
            let fileTypeError = false;
            let sizeError = false;

            acceptedFiles.map((item) => {
                if (allowedMimes.indexOf(item.type) < 0) fileTypeError = true;
                if (item.size >= 20000000) sizeError = true;

                if (!fileTypeError && !sizeError) {
                    let hashId = randomString(10);
                    newPreviews.push({
                        image: URL.createObjectURL(item),
                        id: hashId,
                    });

                    uploader(
                        hashId,
                        item,
                        (success) => {
                            setPreviewImages(
                                previewImages.filter((a) => a.id !== hashId)
                            );
                            onImageAdded(success);
                        },
                        (fail) => {
                            console.log('fail', fail);
                            setPreviewImages(
                                previewImages.filter((a) => a.id !== hashId)
                            );
                        }
                    );
                }
            });

            setPreviewImages([...previewImages, ...newPreviews]);

            if (fileTypeError) {
                newErrors.push(
                    'Ett eller flere av bildene du lastet opp er ikke støtte. Tillate bildetyper er JPG, PNG, GIF og SVG'
                );
            }
            if (sizeError) {
                newErrors.push(
                    'Ett eller flere av bildene dine er større en grensen på 20MB'
                );
            }

            setErrorMessages(newErrors);
        },
        [value]
    );

    const onDelete = (id) => {
        if (typeof id === 'string') {
            setPreviewImages(previewImages.filter((a) => a.id !== id));
        } else {
            onImageDeleted(id);
        }
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        multiple,
    });

    const renderPreviews = !value
        ? previewImages
        : Array.isArray(value)
        ? [...value, ...previewImages]
        : [value, ...previewImages];

    return (
        <Container>
            {!!(renderPreviews.length >= 2 && sortable) ? (
                <SortableImage
                    onSort={onSort}
                    items={renderPreviews.map((item) => ({
                        id: item.id,
                        component: (
                            <ImageCard
                                image={item.image || item.url}
                                onDelete={() => onDelete(item.id)}
                                coverImage={coverImage}
                                wide={wide ? 1 : 0}
                            />
                        ),
                    }))}
                />
            ) : (
                renderPreviews.map((item) => (
                    <div key={item.id} className="pad-space">
                        <ImageCard
                            image={item.image || item.url}
                            onDelete={() => onDelete(item.id)}
                            coverImage={coverImage}
                            wide={wide ? 1 : 0}
                        />
                    </div>
                ))
            )}
            {(!!(!multiple && !renderPreviews.length) || multiple) && (
                <div className="pad-space">
                    <UploadCard wide={wide ? 1 : 0}>
                        <div className="dropzone" {...getRootProps()}>
                            <input {...getInputProps()} />
                        </div>
                    </UploadCard>
                </div>
            )}
        </Container>
    );
};

export default ImageInput;
