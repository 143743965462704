import React from 'react';

const CircleUserIcon = ({ ...props }) => (
    <svg width="22px" height="22px" viewBox="0 0 22 22" {...props}>
        <g
            id="Page-1"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
        >
            <g
                id="Desktop-/-Team-users-view"
                transform="translate(-183.000000, -235.000000)"
            >
                <rect fill="#FFFFFF" x="0" y="0" width="1440" height="812" />
                <g
                    id="Icon-Label-cell"
                    transform="translate(158.000000, 230.000000)"
                >
                    <rect id="Div" x="0" y="0" width="1125" height="32" />
                    <g
                        id="Combined-Shape"
                        transform="translate(24.000000, 4.000000)"
                        fill="#16171A"
                    >
                        <path d="M12,1 C18.0751322,1 23,5.92486775 23,12 C23,18.0751322 18.0751322,23 12,23 C5.92486775,23 1,18.0751322 1,12 C1,5.92486775 5.92486775,1 12,1 Z M12,3 C7.02943725,3 3,7.02943725 3,12 C3,14.6690051 4.16180193,17.0666569 6.00726843,18.7148179 C6.15547923,15.5335689 8.78186626,13 12,13 C15.2181337,13 17.8445208,15.5335689 17.9933454,18.7148909 C19.8381981,17.0666569 21,14.6690051 21,12 C21,7.02943725 16.9705627,3 12,3 Z M12,6 C13.6568542,6 15,7.34314575 15,9 C15,10.6568542 13.6568542,12 12,12 C10.3431458,12 9,10.6568542 9,9 C9,7.34314575 10.3431458,6 12,6 Z" />
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

export default CircleUserIcon;
