import React from 'react';
import styled from 'styled-components';
import Title from '../typography/Title';
import Divider from '../misc/Divider';

const Container = styled.div`
    width: 100%;
`;

const ScreenHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;

    .page-buttons {
        display: flex;
        flex-direction: row;
        padding-bottom: 8px;

        > {
            margin-right: 8px;

            &:last-child {
                margin-right: 0;
            }
        }
    }
`;

const PageTitle = styled(Title)`
    margin-top: 24px;
    margin-bottom: 10px;
`;

const AdminScreen = ({ title, buttons, children, ...rest }) => {
    return (
        <Container>
            <ScreenHeader>
                <PageTitle bold>{title}</PageTitle>
                <div className="page-buttons">{buttons}</div>
            </ScreenHeader>
            <Divider />
            {children}
        </Container>
    );
};

export default AdminScreen;
