const defaultState = { customHeader: false };

export default (state = defaultState, { type, payload }) => {
    switch (type) {
        case 'app/header/set':
            return { ...state, customHeader: payload };
        case 'app/header/clear':
            return { ...state, customHeader: false };
        case 'REDUX/CLEAR':
            return defaultState;
        default:
            return state;
    }
};
