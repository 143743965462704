import React from 'react';
import styled from 'styled-components';
import colors from '../../const/colors';

const Container = styled.div`
    width: 32px;
    height: 32px;
    position: relative;
    border-radius: 50%;
    background-color: ${(props) => props.color || colors.black};

    > * {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
`;

const CircleIcon = ({ children, size, color, loading, ...props }) => {
    return (
        <Container {...props} size={size} color={color}>
            {React.cloneElement(children, {
                color: color === 'black' ? 'white' : colors.black,
            })}
        </Container>
    );
};

export default CircleIcon;
