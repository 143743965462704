import React from 'react';

const AddIcon = ({ color, size = 24, ...props }) => (
    <svg width={size} height={size} viewBox="0 0 24 24" {...props}>
        <g
            id="24-px/UI/Add"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
        >
            <g id="Add">
                <rect id="Bound" x="0" y="0" width="24" height="24" />
                <path
                    d="M13,4 L13,11 L20,11 L20,13 L13,13 L13,20 L11,20 L11,13 L4,13 L4,11 L11,11 L11,4 L13,4 Z"
                    id="Combined-Shape"
                    fill={color || '#000000'}
                />
            </g>
        </g>
    </svg>
);

export default AddIcon;
