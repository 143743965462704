const fileToBase64 = (file, noReplace) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () =>
            resolve(
                noReplace
                    ? reader.result
                    : reader.result.replace(/^data:.+;base64,/, '')
            );
        reader.readAsDataURL(file);
    });

export default fileToBase64;
