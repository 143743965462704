import styled from 'styled-components';
import useApiResource from '../../hooks/useApiResource';
import CheckMarkIcon from '../icons/interface/CheckMarkIcon';
import colors from '../../const/colors';
import React from 'react';
import Text from '../../components/typography/Text';

const Container = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: calc(100% + 40px);
    margin-left: -20px;

    > div {
        width: calc(50% - 40px);
        margin-left: 20px;
        margin-right: 20px;
        padding: 18px 0;
        position: relative;
        cursor: pointer;

        display: flex;
        flex-direction: row;
        align-items: center;

        .title {
            display: flex;
            flex: 1;
            margin-left: 18px;
            user-select: none;
        }

        &:after {
            content: '';
            width: calc(100% - 36px);
            position: absolute;
            bottom: 0;
            right: 0;
            background-color: ${colors.greyLight};
            height: 1px;
        }

        &.no-border,
        &:last-child {
            &:after {
                display: none;
            }
        }
    }
`;

const CheckBoxContainer = styled.div`
    width: 18px;
    height: 18px;
    background-color: ${(props) =>
        props.checked ? colors.green : colors.grey};
    display: inline-flex;
    align-items: center;
    justify-content: center;
`;

const CheckBox = ({ checked, ...props }) => (
    <CheckBoxContainer checked={checked} {...props}>
        {checked && <CheckMarkIcon />}
    </CheckBoxContainer>
);

const FeatureSelector = ({ onChange, value, error }) => {
    const [possibleFeatures, loading] = useApiResource('features');

    const toggle = (id) =>
        !value.find((val) => val === id)
            ? onChange([...value, id])
            : onChange(value.filter((val) => val !== id));

    if (!possibleFeatures) return null;
    return (
        <Container>
            {possibleFeatures &&
                possibleFeatures.map((feature, index) => {
                    return (
                        <div
                            key={feature.key}
                            className={
                                possibleFeatures.length % 2 === 0 &&
                                index === possibleFeatures.length - 2
                                    ? 'no-border'
                                    : null
                            }
                            onClick={() => toggle(feature.id)}
                        >
                            <CheckBox
                                checked={
                                    !!value.find((val) => val === feature.id)
                                }
                            />
                            <Text className="title">{feature.name}</Text>
                        </div>
                    );
                })}
        </Container>
    );
};

export default FeatureSelector;
