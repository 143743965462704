import React from 'react';
import styled from 'styled-components';
import colors from '../../const/colors';

const Divider = styled.div`
    width: 100%;
    height: 0.5px;
    background-color: ${(props) => props.color || colors.grey};
    margin-top: 18px;
    margin-bottom: 18px;
`;

export default Divider;
