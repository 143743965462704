import React, { useMemo, useState, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import AdminTabPage from '../../../components/templates/AdminTabPage';
import IconButton from '../../../components/buttons/IconButton';
import AddWhiteIcon from '../../../components/icons/AddWhiteIcon';
import TeamIcon from '../../../components/icons/TeamIcon';
import Label from '../../../components/typography/Label';
import UserIconTiny from '../../../components/icons/interface/UserIconTiny';
import colors from '../../../const/colors';
import CircleButton from '../../../components/buttons/CircleButton';
import DropDownArrowIcon from '../../../components/icons/interface/DropDownArrowIcon';
import Text from '../../../components/typography/Text';
import useCustomHeader from '../../../hooks/useCustomHeader';
import EditHeader from '../../../components/layout/EditHeader';
import TeamForm from './TeamForm';
import GroupForm from '../Group/GroupForm';
import useApiResource from '../../../hooks/useApiResource';
import Tabs from '../../../components/buttons/Tabs';
import CircleUserIcon from '../../../components/icons/interface/CircleUserIcon';
import Button from '../../../components/buttons/Button';
import TeamUserForm from './TeamUserForm';
import api from '../../../services/Api';

const List = styled.div`
    display: flex;
    flex-direction: column;

    > div {
        display: flex;
        flex-direction: row;
        width: 100%;
        align-items: center;
        position: relative;
        padding: 15px 0;

        .name {
            margin-left: 18px;
        }

        &:after {
            content: '';
            display: block;
            height: 1px;
            width: calc(100% - 40px);
            background-color: ${colors.grey};
            position: absolute;
            bottom: 0;
            right: 0;
        }

        .stretch {
            display: flex;
            flex: 1;
            margin-left: 18px;
            align-items: center;
        }

        .user-count {
            display: flex;
            flex-direction: row;
            margin-right: 16px;

            svg {
                margin-right: 5px;
                position: relative;
                top: 2px;
            }
        }
    }

    .inv-status {
        padding: 5px 10px;
        border-radius: 3px;
        margin-left: 10px;
        color: white;
        font-size: 12px;
        font-weight: 600;
    }

    .inv-status.open {
        background-color: #ffa53e;
        &:after {
            content: 'INVITED';
        }
    }

    .inv-status.declined {
        background-color: #dcdfe6;
        color: black;
        &:after {
            content: 'DECLINED';
        }
    }

    .inv-status.expired {
        background-color: #ff5b42;
        &:after {
            content: 'EXPIRED';
        }
    }

    .group-status {
        padding: 5px 10px;
        border-radius: 3px;
        margin-left: 10px;
        color: white;
        font-size: 12px;
        font-weight: 600;
    }

    .group-status.active {
        background-color: #07a14c;
        &:after {
            content: 'ACTIVE SUBSCRIPTION';
        }
    }

    .group-status.inactive {
        background-color: #ffa53e;
        color: black;
        &:after {
            content: 'INACTIVE SUBSCRIPTION';
        }
    }
`;

const ListMeta = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 20px;

    > div {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-left: 10px;

        div {
            margin-left: 5px;
        }
    }
`;

const Arrow = styled(DropDownArrowIcon)`
    transform: rotate(-90deg);
`;
const Paginator = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
`;

const Page = styled.div`
    margin: 5px;
    color: ${(props) => (props.isCurrent ? 'blue' : 'black')};
    font-size: 1.25em;
    cursor: pointer;
`;

const TeamOverview = ({ id }) => {
    const [editFormVisible, setEditFormVisible] = useState(false);
    const [groupFormVisible, setGroupFormVisible] = useState(false);
    const [users, setUsers] = useState(false);
    const [usersMeta, setUsersMeta] = useState(false);
    const [invitations, setInvitations] = useState(false);
    const [invitationsMeta, setInvitationsMeta] = useState(false);

    const [team] = useApiResource('teams', id);
    const [groups] = useApiResource('groups', null, {
        refetch: true,
        dataFilter: (group) => group.team_id === team?.id,
    });

    useApiResource('', id, {
        customFetcher: () =>
            api('/admin/team/' + id + '/users', 'GET').then((res) => {
                setUsersMeta(res.meta);
                setUsers(res.data);
            }),
    });
    useApiResource('', id, {
        customFetcher: () =>
            api('/admin/team/' + id + '/invitations', 'GET').then((res) => {
                setInvitationsMeta(res.meta);
                setInvitations(res.data);
            }),
    });

    useCustomHeader(<EditHeader onEdit={() => setEditFormVisible(true)} />);

    const teamUserCount = useMemo(() => {
        if (!groups) return 0;
        if (!users || !users.data) return 0;
        return users.data.length;
    }, [groups]);

    const groupUserCount = useCallback(
        (groupId) => {
            if (!users) return 0;

            return users.filter((a) =>
                a.groups.find((b) => {
                    return b.id === groupId;
                })
            ).length;
        },
        [users]
    );

    const teamInvitationCount = useMemo(() => {
        if (!invitations) return 0;
        return invitations.length;
    }, [invitations]);

    const teamGroupCount = useMemo(() => groups?.length || 0, [groups]);

    const [activeTab, setActiveTab] = useState('Users');
    const [openUser, setOpenUser] = useState(null);
    const [openGroup, setOpenGroup] = useState(null);
    const [userFormVisible, setUserFormVisible] = useState(false);

    if (!team) return null;

    const invitationStateHandler = (person) => {
        if (!!person.id) {
            return null;
        } else if (person.rejectedAt) {
            return 'declined';
        } else if (person.isExpired) {
            return 'expired';
        } else {
            return 'open';
        }
    };

    const groupStateHandler = (group) => {
        if (group.subscription == 1) {
            return 'active';
        } else if (group.subscription == 2) {
            return 'inactive';
        } else {
            return '';
        }
    };

    const usersOrInvitations = (list) => {
        return (
            <List>
                {list.map((person, key) => (
                    <div key={key}>
                        <CircleUserIcon />
                        <div className="stretch">
                            <Label as="div" className="name">
                                {person.name}
                            </Label>
                            <div
                                className={`inv-status ${invitationStateHandler(
                                    person
                                )}`}
                            />
                        </div>
                        <Button
                            grey
                            onClick={() => {
                                setOpenUser(person);
                                setUserFormVisible(true);
                            }}
                        >
                            View
                        </Button>
                    </div>
                ))}
            </List>
        );
    };
    const loadNextUserPage = (page) =>
        api('/admin/team/' + id + '/users?page=' + page, 'GET').then((res) => {
            setUsersMeta(res.meta);
            setUsers(res.data);
        });
    const userNavigator = () => {
        let pages = [];
        if (usersMeta.last_page === 1) return;
        for (let index = 1; index <= usersMeta.last_page; ++index) {
            pages.push(
                <Page
                    isCurrent={index === usersMeta.current_page}
                    onClick={() => loadNextUserPage(index)}
                    key={index}
                >
                    {index}
                </Page>
            );
        }
        return <Paginator>{pages}</Paginator>;
    };
    const loadNexInvitationPage = (page) =>
        api('/admin/team/' + id + '/invitations?page=' + page, 'GET').then(
            (res) => {
                setInvitationsMeta(res.meta);
                setInvitations(res.data);
            }
        );
    const invitationsNavigator = () => {
        let pages = [];
        if (invitationsMeta.last_page === 1) return;
        for (let index = 1; index <= invitationsMeta.last_page; ++index) {
            pages.push(
                <Page
                    key={index}
                    isCurrent={index === invitationsMeta.current_page}
                    onClick={() => loadNexInvitationPage(index)}
                >
                    {index}
                </Page>
            );
        }

        return <Paginator>{pages}</Paginator>;
    };

    return (
        <AdminTabPage
            title={team.name}
            tabs={
                <Tabs
                    items={['Users', 'Invitations', 'Groups']}
                    active={activeTab}
                    onClick={setActiveTab}
                />
            }
            buttons={
                activeTab === 'Users' || activeTab === 'Invitations' ? (
                    <div>
                        <IconButton
                            icon={<AddWhiteIcon />}
                            key="invite-user"
                            onClick={() => {
                                setOpenUser(null);
                                setUserFormVisible(true);
                            }}
                        >
                            New Invitation
                        </IconButton>
                    </div>
                ) : (
                    <IconButton
                        icon={<AddWhiteIcon />}
                        onClick={() => {
                            setOpenGroup(null);
                            setGroupFormVisible(true);
                        }}
                    >
                        New Group
                    </IconButton>
                )
            }
        >
            <>
                {activeTab === 'Users' && !!users && (
                    <>
                        {usersOrInvitations(users)}
                        {userNavigator()}
                    </>
                )}
                {activeTab === 'Invitations' && !!invitations && (
                    <>
                        {usersOrInvitations(invitations)}
                        {invitationsNavigator()}
                    </>
                )}
                {activeTab === 'Groups' && !!groups && (
                    <List>
                        {groups.map((group) => (
                            <div key={group.id}>
                                <TeamIcon />
                                <div className="stretch">
                                    <Label as="div">{group.name}</Label>
                                    <div
                                        className={`group-status ${groupStateHandler(
                                            group
                                        )}`}
                                    />
                                </div>
                                <div className="user-count">
                                    <UserIconTiny color={colors.grey} />
                                    <Label as="div" small>
                                        {groupUserCount(group.id)}
                                    </Label>
                                </div>
                                <CircleButton
                                    color="black"
                                    onClick={() => {
                                        setOpenGroup(group);
                                        setGroupFormVisible(true);
                                    }}
                                >
                                    <Arrow color="white" />
                                </CircleButton>
                            </div>
                        ))}
                    </List>
                )}
                <ListMeta>
                    <div>
                        <Text tiny color={colors.greyDark}>
                            Groups:
                        </Text>
                        <Label small as="div">
                            {teamGroupCount}
                        </Label>
                    </div>
                    <div>
                        <Text tiny color={colors.greyDark}>
                            Users:
                        </Text>
                        <Label small as="div">
                            {usersMeta?.total}
                        </Label>
                    </div>
                    <div>
                        <Text tiny color={colors.greyDark}>
                            Invitations:
                        </Text>
                        <Label small as="div">
                            {invitationsMeta?.total}
                        </Label>
                    </div>
                </ListMeta>
            </>

            {editFormVisible && (
                <TeamForm
                    onClose={() => setEditFormVisible(false)}
                    edit={true}
                    team={team}
                />
            )}
            {groupFormVisible && (
                <GroupForm
                    onClose={() => setGroupFormVisible(false)}
                    team={team}
                    group={openGroup}
                    edit={!!openGroup}
                />
            )}

            {!!userFormVisible && (
                <TeamUserForm
                    edit={!!openUser}
                    user={openUser}
                    team={team}
                    groups={groups}
                    isInvite={!openUser || openUser.expiresOn}
                    onClose={() => {
                        setUserFormVisible(false);
                        setOpenUser(null);
                        !openUser || openUser.expiresOn
                            ? loadNexInvitationPage(
                                  invitationsMeta.current_page
                              )
                            : loadNextUserPage(usersMeta.current_page);
                    }}
                />
            )}
        </AdminTabPage>
    );
};

export default TeamOverview;
