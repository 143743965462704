import React from 'react';
import IconSpacer from './IconSpacer';

const Icon = ({ color, ...props }) => (
    <svg
        width="10px"
        height="12px"
        viewBox="0 0 10 12"
        version="1.1"
        {...props}
    >
        <g
            id="Icons"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
        >
            <g
                id="24-px/Iconagraphy/Dark/User"
                transform="translate(-7.000000, -5.000000)"
            >
                <g id="User">
                    <rect id="Bound" x="0" y="0" width="24" height="24" />
                    <path
                        d="M10,11 L14,11 C15.6568542,11 17,12.3431458 17,14 L17,17 L17,17 L7,17 L7,14 C7,12.3431458 8.34314575,11 10,11 Z"
                        id="Rectangle-Copy"
                        fill={color || '#000000'}
                    />
                    <circle
                        id="Oval-Copy"
                        fill={color || '#000000'}
                        cx="12"
                        cy="7.5"
                        r="2.5"
                    />
                </g>
            </g>
        </g>
    </svg>
);

const UserIcon = ({ withSpace, ...props }) =>
    withSpace ? (
        <IconSpacer size={typeof withSpace !== 'boolean' ? withSpace : null}>
            <Icon {...props} />
        </IconSpacer>
    ) : (
        <Icon {...props} />
    );

export default UserIcon;
