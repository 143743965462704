import React from 'react';
import styled from 'styled-components';
import CircleButton from './buttons/CircleButton';
import CloseIcon from './icons/interface/CloseIcon';

const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.48);
    padding: 48px;
    overflow: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;

    .modal {
        position: relative;
        z-index: 120;
        width: 100%;
        background-color: white;
        max-width: 870px;
        margin: auto;
        padding: 12px 0;
    }

    .modal-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 0 18px;

        .menu-icons {
            display: flex;
            flex-direction: row;

            > * {
                margin-right: 8px;

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }

    .modal-content {
        padding: 0 80px;
    }

    .bg-close {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 110;
        cursor: pointer;
    }
`;

const Modal = ({ onClose, headerMenu, children }) => {
    return (
        <Container>
            <div className="modal">
                <div className="modal-header">
                    <CircleButton grey onClick={onClose}>
                        <CloseIcon />
                    </CircleButton>
                    <div className="menu-icons">{headerMenu}</div>
                </div>
                <div className="modal-content">{children}</div>
            </div>
            <div className="bg-close" onClick={onClose} />
        </Container>
    );
};

export default Modal;
