import React from 'react';

const MenuIcon = (props) => (
    <svg
        width="18px"
        height="10px"
        viewBox="0 0 18 10"
        version="1.1"
        {...props}
    >
        <g
            id="Icons"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
        >
            <g
                id="iPhone-11-Pro-Copy-30"
                transform="translate(-492.000000, -457.000000)"
            >
                <rect fill="#FFFFFF" x="0" y="0" width="1061" height="701" />
                <g
                    id="24-px/UI/Menu"
                    transform="translate(489.000000, 450.000000)"
                >
                    <g id="Menu">
                        <rect id="Bound" x="0" y="0" width="24" height="24" />
                        <line
                            x1="3"
                            y1="8"
                            x2="21"
                            y2="8"
                            id="Path-2"
                            stroke="#000000"
                            strokeWidth="2"
                        />
                        <line
                            x1="3"
                            y1="16"
                            x2="21"
                            y2="16"
                            id="Path-2"
                            stroke="#000000"
                            strokeWidth="2"
                        />
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

export default MenuIcon;
