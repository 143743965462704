import React from 'react';
import styled from 'styled-components';
import colors from '../../const/colors';

const MinimalCard = styled.div`
    background-color: white;
    box-shadow: 0 20px 24px -10px rgba(0, 0, 0, 0.12);
    margin-bottom: 36px;
    padding: 18px;
    transition: all 0.15s ease;

    &:hover {
        box-shadow: 0 20px 24px -10px rgba(0, 0, 0, 0.2);
    }
`;

export default MinimalCard;
