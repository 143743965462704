import React from 'react';

const DeleteIcon = ({ color, ...props }) => (
    <svg
        width="10px"
        height="12px"
        viewBox="0 0 10 12"
        version="1.1"
        {...props}
    >
        <g
            id="Icons"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
        >
            <g
                id="24-px/Iconagraphy/Dark/Trash"
                transform="translate(-7.000000, -6.000000)"
            >
                <g id="Trash">
                    <rect id="Bound" x="0" y="0" width="24" height="24" />
                    <path
                        d="M17,10 L17,12 L16,12 L16,18 L8,18 L8,12 L7,12 L7,10 L17,10 Z M11,12 L10,12 L10,16 L11,16 L11,12 Z M14,12 L13,12 L13,16 L14,16 L14,12 Z M16,6 L16,8 L8,8 L8,6 L16,6 Z"
                        id="Combined-Shape"
                        fill={color || '#000000'}
                    />
                </g>
            </g>
        </g>
    </svg>
);

export default DeleteIcon;
