import React, { useCallback, useState } from 'react';
import resourceEndpoints from '../const/resourceEndpoint';
import api from '../services/Api';
import delay from '../utils/delay';

const usePasswordReset = (resource, onSuccess, onFail) => {
    const [loading, setLoading] = useState(false);

    const sendRequest = useCallback((id) => {
        setLoading(true);

        api(`${resourceEndpoints[resource]}/${id}/reset-password`)
            .then((res) => {
                delay(() => onSuccess(res.password));
            })
            .catch(onFail)
            .finally(() => setLoading(false));
    }, []);

    return [sendRequest, loading];
};

export default usePasswordReset;
