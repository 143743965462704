import { put, takeEvery, call, select } from 'redux-saga/effects';

function* fetchProviders({ type, payload }) {
    try {
        const token = yield select((state) => state.user.token);

        const response = yield call(
            fetch,
            `${process.env.REACT_APP_API_URL}/admin/provider`,
            {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        const responseBody = yield response.json();

        yield put({
            type: 'apiData/update',
            payload: {
                providers: responseBody,
            },
        });
    } catch (e) {}
}

export default [takeEvery('providers/fetch', fetchProviders)];
