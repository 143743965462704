import React from 'react';
import styled from 'styled-components';
import useApiResource from '../../hooks/useApiResource';
import Label from '../typography/Label';
import Select from './Select';

const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;

const CompanySelector = ({ selectedCompany, onChange, ...props }) => {
    const [companies, loading] = useApiResource('companies');

    if (!companies) return null;

    const mappedCompanies = companies.map((a) => ({
        label: a.name,
        value: a.id,
    }));

    return (
        <Container {...props}>
            <Label bold as="div">
                Company
            </Label>
            <Select
                selected={mappedCompanies.find(
                    (a) => a.value === selectedCompany
                )}
                onChange={onChange}
                options={mappedCompanies}
                placeholder="Select company"
                color="grey"
            />
        </Container>
    );
};

export default CompanySelector;
