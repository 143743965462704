import React, { useState } from 'react';
import styled from 'styled-components';
import Modal from '../../../components/Modal';
import Title from '../../../components/typography/Title';
import Divider from '../../../components/misc/Divider';
import FormController from '../../../components/form/FormController';
import Input from '../../../components/form/Input';
import CheckBox from '../../../components/form/CheckBox';
import Button from '../../../components/buttons/Button';
import Label from '../../../components/typography/Label';
import IconUpload from '../../../components/form/IconUpload';
import useSaveApiResource from '../../../hooks/useSaveApiResource';
import useDeleteApiResource from '../../../hooks/useDeleteApiResource';
import Text from '../../../components/typography/Text';
import { LANGUAGE } from '../../../const/languages';

const Container = styled.div`
    width: 100%;
`;

const IconTagResourceForm = ({
    resourceType,
    resourceName,
    edit,
    resource,
    onClose,
}) => {
    const [icon, setIcon] = useState(
        resource && resource.icon ? resource.icon : null
    );

    const [desk_flow, setDeskFlow] = useState(
        resource && resource.desk_flow ? !!resource.desk_flow : false
    );

    const [translations, setTranslations] = useState(
        (resource?.translations?.length > 0
            ? resource.translations
            : [
                  {
                      locale: LANGUAGE.ENGLISH,
                      name: resource.name || '',
                      description: resource.description || '',
                  },
              ]
        ).reduce(
            (prev, t) => ({
                ...prev,
                [t.locale]: { name: t.name, description: t.description },
            }),
            {}
        )
    );

    const [sendRequest, requestLoading, validationErrors] = useSaveApiResource(
        resourceType,
        {
            onSuccess: () => onClose(),
        }
    );

    const [requestDelete, requestDeleteLoading] = useDeleteApiResource(
        resourceType,
        (success) => onClose(),
        (fail) => console.log('delete failed', fail)
    );

    const save = () => {
        sendRequest({
            id: edit ? resource.id : null,
            icon,
            desk_flow,
            translations,
        });
    };

    const onDelete = () => {
        requestDelete(resource.id);
    };

    let headerMenu = [
        <Button grey key="save" onClick={save}>
            {edit ? 'Save' : 'Publish'}
        </Button>,
    ];

    if (edit)
        headerMenu = [
            <Button key="delete" onClick={onDelete}>
                Delete
            </Button>,
            ...headerMenu,
        ];

    function renderDeskFlowCheckBox(desk_flow) {
        return [
            <CheckBox
                checked={desk_flow}
                onClick={() => setDeskFlow(!desk_flow)}
            />,
        ];
    }

    function renderDeskFlowInfo(desk_flow) {
        return [<Text>{desk_flow ? 'Enabled' : 'Disabled'}</Text>];
    }

    return (
        <Modal onClose={onClose} headerMenu={headerMenu}>
            <Container>
                <Title withSpace bold>
                    {edit ? 'Edit' : 'New'} {resourceName}
                </Title>
                <Divider />

                {Object.values(LANGUAGE).map((lang) => (
                    <FormController
                        error={validationErrors.name}
                        key={`name-${lang}`}
                    >
                        <Label withSpace tiny>
                            Title ({lang.toUpperCase()})
                        </Label>
                        <Input
                            value={translations[lang]?.name || ''}
                            onChange={(e) =>
                                setTranslations({
                                    ...translations,
                                    [lang]: {
                                        ...translations[lang],
                                        name: e.target.value,
                                    },
                                })
                            }
                        />
                    </FormController>
                ))}
                {resourceType === 'occasions' && (
                    <div>
                        {Object.values(LANGUAGE).map((lang) => (
                            <FormController
                                label={`Description (${lang.toUpperCase()})`}
                                key={`desc-${lang}`}
                            >
                                <Input
                                    as="textarea"
                                    value={
                                        translations[lang]?.description || ''
                                    }
                                    onChange={(e) =>
                                        setTranslations({
                                            ...translations,
                                            [lang]: {
                                                ...translations[lang],
                                                description: e.target.value,
                                            },
                                        })
                                    }
                                />
                            </FormController>
                        ))}
                        <FormController>
                            <Label withSpace tiny>
                                Desk flow
                            </Label>
                            {!edit
                                ? renderDeskFlowCheckBox(desk_flow)
                                : renderDeskFlowInfo(desk_flow)}
                        </FormController>
                    </div>
                )}
                <Divider />

                <Label tiny smallSpace as="div">
                    Icon
                </Label>

                <FormController error={validationErrors.icon}>
                    <IconUpload
                        value={icon}
                        onAdd={(b64) => setIcon(b64)}
                        onDelete={() => setIcon(null)}
                    />
                </FormController>
            </Container>
        </Modal>
    );
};

export default IconTagResourceForm;
