import React from 'react';

const PinIcon = ({ color, size = 24, ...props }) => (
    <svg width={size} height={size} viewBox="0 0 24 24" {...props}>
        <g
            id="24-px/Iconagraphy/Dark/Pin"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
        >
            <g id="Pin">
                <rect id="Bound" x="0" y="0" width="24" height="24" />
                <path
                    d="M12,5 C14.7614237,5 17,7.26344932 17,10.0555556 C17,14.2921062 12,18 12,18 C12,18 7,14.2921062 7,10.0555556 C7,7.26344932 9.23857625,5 12,5 Z M12,8 C10.8954305,8 10,8.8954305 10,10 C10,11.1045695 10.8954305,12 12,12 C13.1045695,12 14,11.1045695 14,10 C14,8.8954305 13.1045695,8 12,8 Z"
                    id="Combined-Shape"
                    fill={color || '#000000'}
                />
            </g>
        </g>
    </svg>
);

export default PinIcon;
