import React, { useState } from 'react';
import styled from 'styled-components';
import AdminScreen from '../../components/templates/AdminScreen';
import IconButton from '../../components/buttons/IconButton';
import AddWhiteIcon from '../../components/icons/AddWhiteIcon';
import useApiResource from '../../hooks/useApiResource';
import List from '../../components/List';
import SmallProviderCard from '../../components/cards/SmallProviderCard';
import ProviderForm from './ProviderForm';
import { navigate } from 'hookrouter';

const ProviderIndex = () => {
    const [providers] = useApiResource('providers', null, {
        refetch: true,
    });
    const [formIsVisible, setFormIsVisible] = useState(false);

    return (
        <AdminScreen
            title="Space providers"
            buttons={[
                <IconButton
                    onClick={() => setFormIsVisible(true)}
                    icon={<AddWhiteIcon />}
                    key="add"
                >
                    Add
                </IconButton>,
            ]}
        >
            {!!providers && (
                <List>
                    {providers.map((provider) => (
                        <div key={provider.id}>
                            <SmallProviderCard
                                logo={provider.logo ? provider.logo.url : null}
                                name={provider.name}
                                locationCount={provider.locationCount}
                                spaceCount={provider.spaceCount}
                                onClick={() =>
                                    navigate('/admin/provider/' + provider.id)
                                }
                            />
                        </div>
                    ))}
                </List>
            )}

            {formIsVisible && (
                <ProviderForm onClose={() => setFormIsVisible(false)} />
            )}
        </AdminScreen>
    );
};

export default ProviderIndex;
