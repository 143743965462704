import React, { useCallback, useState } from 'react';

const useFormState = (initialValue) => {
    const [formData, setFormData] = useState(initialValue);

    const updateFormData = useCallback((key, value, callback = null) => {
        if (typeof key === 'object') {
            setFormData((state) => ({ ...state, ...key }));
        } else {
            setFormData((state) => ({ ...state, [key]: value }));
        }
    }, []);

    return [formData, updateFormData];
};

export default useFormState;
