import React from 'react';

const PinIconTiny = ({ color, ...props }) => (
    <svg
        width="10px"
        height="10px"
        viewBox="0 0 10 10"
        version="1.1"
        {...props}
    >
        <g
            id="10-px/Dark/Pin"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
        >
            <g id="Pin">
                <g>
                    <rect id="Bound" x="0" y="0" width="10" height="10" />
                    <path
                        d="M5,0 C7.209139,0 9,1.74111486 9,3.88888889 C9,7.14777403 5,10 5,10 C5,10 1,7.14777403 1,3.88888889 C1,1.74111486 2.790861,0 5,0 Z M5,2.5 C4.17157288,2.5 3.5,3.17157288 3.5,4 C3.5,4.82842712 4.17157288,5.5 5,5.5 C5.82842712,5.5 6.5,4.82842712 6.5,4 C6.5,3.17157288 5.82842712,2.5 5,2.5 Z"
                        id="Combined-Shape"
                        fill={color || '#FFFFFF'}
                    />
                </g>
            </g>
        </g>
    </svg>
);

export default PinIconTiny;
