import React, { useMemo } from 'react';
import styled from 'styled-components';
import useFormState from '../../../hooks/useFormState';
import Modal from '../../../components/Modal';
import Button from '../../../components/buttons/Button';
import DeleteIcon from '../../../components/icons/interface/DeleteIcon';
import CircleButton from '../../../components/buttons/CircleButton';
import Title from '../../../components/typography/Title';
import Divider from '../../../components/misc/Divider';
import useSaveApiResource from '../../../hooks/useSaveApiResource';
import FormController from '../../../components/form/FormController';
import Input from '../../../components/form/Input';
import { Column, Row } from '../../../components/layout/Grid';
import CompanySelector from '../../../components/form/CompanySelector';
import LocationSelector from '../../../components/form/LocationSelector';
import useDeleteApiResource from '../../../hooks/useDeleteApiResource';
import usePasswordReset from '../../../hooks/usePasswordReset';
import moment from 'moment';

const Container = styled.div`
    width: 100%;
`;

const ViewInvitation = ({ invitation, edit, onClose, onDelete }) => {
    const [requestDelete, deleteLoading] = useDeleteApiResource(
        'invitations',
        (success) => onClose && onClose(),
        (fail) => alert('Unable to delete invitation')
    );

    const doDelete = () => {
        const confirmed = window.confirm(
            'Are you sure you want to delete this invitation and the user that was created from it?'
        );

        if (confirmed) requestDelete(invitation.uuid);
    };

    const headerMenu = [
        <CircleButton grey key="delete" onClick={doDelete}>
            <DeleteIcon />
        </CircleButton>,
    ];

    const invitationStatus = useMemo(() => {
        if (invitation.rejectedAt) return 'Rejected';
        if (moment(invitation.expiresOn).isBefore()) return 'Expired';
        return 'Active';
    }, [invitation]);

    return (
        <Modal onClose={onClose} headerMenu={headerMenu}>
            <Container>
                <Title withSpace bold>
                    {!edit ? 'New invitation' : 'View invitation'}
                </Title>

                <Divider />
                <Row>
                    <Column span={3}>
                        <FormController label="First name">
                            <Input value={invitation.firstName} readOnly />
                        </FormController>
                    </Column>
                    <Column span={3}>
                        <FormController label="Last name">
                            <Input
                                value={invitation.lastName}
                                readOnly={true}
                            />
                        </FormController>
                    </Column>
                </Row>
                <Row>
                    <Column span={3}>
                        <FormController label="Phone number">
                            <Input
                                value={invitation.phoneNumber || ''}
                                readOnly={true}
                            />
                        </FormController>
                    </Column>
                    <Column span={3}>
                        <FormController label="Email">
                            <Input
                                value={invitation.email || ''}
                                readOnly={true}
                            />
                        </FormController>
                    </Column>
                </Row>
                {edit && (
                    <Row>
                        <Column span={3}>
                            <FormController label="Invitation code">
                                <Input
                                    value={invitation.invitationCode}
                                    readOnly={true}
                                />
                            </FormController>
                        </Column>
                        <Column span={3}>
                            <FormController label="Status">
                                <Input
                                    value={invitationStatus}
                                    readOnly={true}
                                />
                            </FormController>
                        </Column>
                    </Row>
                )}
            </Container>
        </Modal>
    );
};

export default ViewInvitation;
