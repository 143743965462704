import React from 'react';
import styled from 'styled-components';
import Text from '../../../components/typography/Text';
import CircleButton from '../../../components/buttons/CircleButton';
import UpIcon from '../../../components/icons/interface/UpIcon';
import colors from '../../../const/colors';
import DownIcon from '../../../components/icons/interface/DownIcon';
import Button from '../../../components/buttons/Button';
import DeleteIcon from '../../../components/icons/interface/DeleteIcon';
import Divider from '../../../components/misc/Divider';
import OccasionIcon from '../../../components/icons/OccasionIcon';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .row {
        display: flex;
        flex-direction: row;
        width: 100%;
        align-items: center;

        &.cell {
            padding-top: 4px;
            padding-bottom: 4px;
        }

        &.padded {
            padding-left: 42px;
        }
    }

    button {
        margin-left: 8px;
    }

    .name {
        display: flex;
        flex: 1;
        margin-left: 18px;
        line-height: 1;
    }
`;

const IconTagResourceRow = ({
    icon,
    name,
    onEdit,
    onDelete,
    onUp,
    onDown,
    ...props
}) => (
    <Container>
        <div className="row cell">
            {!!icon && <OccasionIcon src={icon} />}
            <Text className="name">{name}</Text>
            {!!onUp && (
                <CircleButton light onClick={onUp}>
                    <UpIcon color={colors.grey} />
                </CircleButton>
            )}
            {!!onDown && (
                <CircleButton light onClick={onDown}>
                    <DownIcon color={colors.grey} />
                </CircleButton>
            )}
            <Button grey onClick={onEdit}>
                Edit
            </Button>
            <CircleButton grey onClick={onDelete}>
                <DeleteIcon />
            </CircleButton>
        </div>
        <div className={`row ${!!icon ? 'padded' : null}`}>
            <Divider withSpace />
        </div>
    </Container>
);

export default IconTagResourceRow;
