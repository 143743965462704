import React from 'react';
import styled from 'styled-components';
import useApiResource from '../../hooks/useApiResource';
import Label from '../typography/Label';
import Select from './Select';

const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;

const CustomerSelector = ({ selectedCustomer, onChange, ...props }) => {
    const [customers, loading] = useApiResource('customers');

    if (!customers) return null;

    const mappedCustomers = customers.map((a) => ({
        label: a.name,
        value: a.id,
    }));

    mappedCustomers.unshift({
        label: 'Not selected',
        value: null,
    });

    return (
        <Container {...props}>
            <Label bold as="div">
                Customer
            </Label>
            <Select
                selected={mappedCustomers.find(
                    (a) => a.value === selectedCustomer
                )}
                onChange={(e) =>
                    onChange(
                        e,
                        customers.find((a) => a.id === e)
                    )
                }
                options={mappedCustomers}
                placeholder="Select customer"
                color="grey"
            />
        </Container>
    );
};

export default CustomerSelector;
