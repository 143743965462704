import React, { useState } from 'react';
import styled from 'styled-components';
import useApiResource from '../../hooks/useApiResource';
import Title from '../../components/typography/Title';
import Text from '../../components/typography/Text';
import BuildingSmallGreyIcon from '../../components/icons/BuildingSmallGreyIcon';
import Label from '../../components/typography/Label';
import DoorSmallGreyIcon from '../../components/icons/DoorSmallGreyIcon';
import IconLabel from '../../components/misc/IconLabel';
import Divider from '../../components/misc/Divider';
import ContactCell from '../../components/cells/ContactCell';
import formatPhoneNumber from '../../utils/formatPhoneNumber';
import useCustomHeader from '../../hooks/useCustomHeader';
import EditHeader from '../../components/layout/EditHeader';
import ProviderForm from './ProviderForm';
import SpaceList from '../Space/SpaceList';
import { dispatch } from '../../redux';
import { navigate } from 'hookrouter';

const Container = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;

    .provider-logo {
        height: 48px;
        width: auto;
    }
`;

const Description = styled(Text)`
    width: 700px;
    text-align: center;
    margin-bottom: 24px;
`;

const ProviderStats = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 0;

    > div {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
`;

const SmallDivider = styled(Divider)`
    width: 375px;
`;

const ProviderSingle = ({ id }) => {
    useCustomHeader(<EditHeader onEdit={() => setEditFormVisible(true)} />);
    const [editFormVisible, setEditFormVisible] = useState(false);
    const [provider, loading] = useApiResource('providers', id);
    const [providerSpaces, providerSpacesLoading] = useApiResource(
        'spaces',
        null,
        {
            dataFilter: (a) => a.provider_capability_id === id,
        }
    );

    if (!provider) return null;
    return (
        <Container>
            {!!provider.logo && (
                <img
                    className="provider-logo"
                    src={provider.logo.url}
                    alt="logo"
                />
            )}

            <Title withSpace bold>
                {provider.name}
            </Title>

            <ProviderStats>
                <IconLabel
                    icon={<BuildingSmallGreyIcon />}
                    text={
                        <Label tiny as="div">
                            {`${provider.locationCount} buildings`}{' '}
                        </Label>
                    }
                />
                <IconLabel
                    icon={<DoorSmallGreyIcon />}
                    text={
                        <Label
                            tiny
                            as="div"
                        >{`${provider.spaceCount} spaces`}</Label>
                    }
                />
            </ProviderStats>
            <SmallDivider withSpace />
            <ContactCell
                name={provider.contact_name}
                subtitle={formatPhoneNumber(provider.contact_telephone)}
                buttonText="Call"
                onClick={() => alert('call')}
            />
            <Divider withSpace />

            <SpaceList provider={provider} spaces={providerSpaces} />

            {editFormVisible && (
                <ProviderForm
                    edit
                    onDelete={() => navigate('/admin/provider')}
                    provider={provider}
                    onClose={() => setEditFormVisible(false)}
                />
            )}
        </Container>
    );
};

export default ProviderSingle;
