import React from 'react';
import { Column, Row } from '../layout/Grid';
import FormController from './FormController';
import Input from './Input';
import Label from '../typography/Label';

const CurrencyInputForm = ({
    currencies,
    pricing,
    priceType,
    validationErrors,
    onChange,
}) => {
    const thePrices = {};
    pricing
        .filter((price) => price.type === priceType)
        .forEach((price) => (thePrices[price.currency] = price));

    const byPriceTypeAndCurrency = (currency) => {
        return (price) =>
            price.type === priceType && price.currency === currency;
    };

    const updateFormDataCurrencies = (value, type, currency) => {
        const updatedPrice = pricing.find(byPriceTypeAndCurrency(currency));
        updatedPrice[type] = value;
        onChange && onChange(pricing);
    };

    const getValidationError = (currency, type) => {
        const pricingIndex = pricing.findIndex(
            byPriceTypeAndCurrency(currency)
        );

        return validationErrors?.[`pricing.${pricingIndex}.${type}`];
    };

    return (
        <>
            <Label as="div" withSpace>
                {priceType.charAt(0).toUpperCase() + priceType.slice(1)} prices
            </Label>
            {currencies.map((currency, index) => (
                <Row key={index}>
                    <Column span={3}>
                        <FormController
                            label={
                                `${currency.flag}` +
                                ' Hourly ' +
                                `(${currency.code})`
                            }
                            error={getValidationError(currency.code, 'hourly')}
                        >
                            <Input
                                value={thePrices[currency.code]?.hourly}
                                onChange={(e) => {
                                    updateFormDataCurrencies(
                                        e.target.value,
                                        'hourly',
                                        currency.code
                                    );
                                }}
                            />
                        </FormController>
                    </Column>
                    <Column span={3}>
                        <FormController
                            label={
                                `${currency.flag}` +
                                ' Daily ' +
                                `(${currency.code})`
                            }
                            error={getValidationError(currency.code, 'daily')}
                        >
                            <Input
                                value={thePrices[currency.code]?.daily}
                                onChange={(e) => {
                                    updateFormDataCurrencies(
                                        e.target.value,
                                        'daily',
                                        currency.code
                                    );
                                }}
                            />
                        </FormController>
                    </Column>
                </Row>
            ))}
        </>
    );
};

export default CurrencyInputForm;
