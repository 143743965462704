import React, { useState } from 'react';
import styled from 'styled-components';
import Modal from '../../components/Modal';
import Button from '../../components/buttons/Button';
import Title from '../../components/typography/Title';
import Divider from '../../components/misc/Divider';
import FormController from '../../components/form/FormController';
import ImageInput from '../../components/form/ImageInput';
import useFormState from '../../hooks/useFormState';
import FormGroup from '../../components/form/FormGroup';
import Input from '../../components/form/Input';
import { Column, Row } from '../../components/layout/Grid';
import useSaveApiResource from '../../hooks/useSaveApiResource';
import OccasionSelector from '../../components/form/OccasionSelector';
import FacilitySelector from '../../components/form/FacilitySelector';
import CircleButton from '../../components/buttons/CircleButton';
import DeleteIcon from '../../components/icons/interface/DeleteIcon';
import useDeleteApiResource from '../../hooks/useDeleteApiResource';
import ProviderSelector from '../../components/form/ProviderSelector';
import LocationSelector from '../../components/form/LocationSelector';
import AddressInput from '../../components/form/AddressInput';
import AvailabilitySelector from '../../components/form/AvailabilitySelector';
import { mapObject, filterObject } from '../../utils/objectUtils';
import BoosterSelector from '../../components/form/BoosterSelector';
import CheckBox from '../../components/form/CheckBox';
import ListInput from '../../components/form/ListInput';
import CurrencyInputForm from '../../components/form/CurrenciesSelector';
import currencies from '../../const/currencies';
import CopyToClipboard from 'react-copy-to-clipboard';
import ContactInformation from '../../components/form/ContactInformation';
import { LANGUAGE } from '../../const/languages';
import priceTypes from '../../const/priceTypes';

const Container = styled.div``;

const Warning = styled.div`
    padding: 12px 0;
    font-size: 18px;
    line-height: 24px;
    margin-top: 6px;
    color: #ffa500ff;
`;

const getSpacePricingDefaults = () =>
    priceTypes
        .map((priceType) =>
            currencies.map((currency) => ({
                currency: currency.code,
                hourly: '',
                daily: '',
                type: priceType.key,
            }))
        )
        .flat();

const getSpacePrices = (spacePricing) =>
    priceTypes
        .map((priceType) =>
            currencies.map((currency) => {
                const cur = spacePricing.find(
                    (c) =>
                        c.currency === currency.code && c.type === priceType.key
                );

                return {
                    currency: currency.code,
                    hourly: cur?.hourly >= 0 ? cur.hourly : '',
                    daily: cur?.daily >= 0 ? cur.daily : '',
                    type: cur?.type ?? priceType.key,
                };
            })
        )
        .flat();

const spaceFormDefaults = (space, provider) => ({
    provider_capability_id:
        space && space.provider_capability_id
            ? space.provider_capability_id
            : provider
            ? provider.id
            : null,
    photos: space && space.images ? space.images : [],
    address: space && space.address ? space.address : null,
    area: space?.address?.area || '',
    occasions:
        space && space.occasions
            ? space.occasions.map((a) => ({
                  id: a.id,
                  primary: a.pivot.primary,
              }))
            : [],
    facilities:
        space && space.facilities
            ? space.facilities.map((a) => ({
                  id: a.id,
                  description: a.pivot.description,
              }))
            : [],
    boosters:
        space && space.boosters
            ? space.boosters.map((a) => ({ id: a.id }))
            : [],
    seats: space && space.seats ? space.seats : '',
    is_satellite: space && space.is_satellite ? space.is_satellite : 0,
    check_in_required:
        space && space.check_in_required ? space.check_in_required : 0,
    full_day: space && space.full_day ? space.full_day : 0,
    add_on_rate: space?.add_on_rate || 0,
    pricing:
        space && space.pricing
            ? getSpacePrices(space.pricing)
            : getSpacePricingDefaults(),
    price_hourly:
        space && typeof space.price_hourly !== 'undefined'
            ? space.price_hourly
            : '',
    price_daily:
        space && typeof space.price_daily !== 'undefined'
            ? space.price_daily
            : '',
    contact_telephone:
        space && space.contact_telephone ? space.contact_telephone : '',
    contact_information:
        space && space.contact_information
            ? space.contact_information
            : {
                  phone: '',
                  email: '',
                  chat: '',
              },
    contact_email: space?.contact_email?.length ? space.contact_email : [''],
    location_id: space.location_id ? space.location_id : null,
    availability: space.opening_hours
        ? mapObject(
              filterObject(space.opening_hours, (value, key) => {
                  const matchingKeys = [
                      'monday',
                      'tuesday',
                      'wednesday',
                      'thursday',
                      'friday',
                      'saturday',
                      'sunday',
                  ];

                  if (matchingKeys.indexOf(key) < 0) return false;
                  return !!value;
              }),
              (value) => ({
                  from: value.split('-')[0],
                  to: value.split('-')[1],
              })
          )
        : {
              monday: {
                  from: '08:00',
                  to: '17:00',
              },
              tuesday: {
                  from: '08:00',
                  to: '17:00',
              },
              wednesday: {
                  from: '08:00',
                  to: '17:00',
              },
              thursday: {
                  from: '08:00',
                  to: '17:00',
              },
              friday: {
                  from: '08:00',
                  to: '17:00',
              },
          },
    access_open_path: space.open_path_zones
        ? space.open_path_zones.map((val) => val.zone)
        : [],
});

const SpaceForm = ({ provider, edit, space, onClose }) => {
    const [formData, updateFormData] = useFormState(
        spaceFormDefaults(space, provider)
    );

    const [requestDelete, deleteLoading] = useDeleteApiResource(
        'spaces',
        (success) => onClose && onClose(),
        (failed) => alert('Unable to delete space')
    );

    const [sendRequest, requestLoading, validationErrors] = useSaveApiResource(
        'spaces',
        {
            onSuccess: () => onClose && onClose(),
            onValidationError: () =>
                alert(
                    'You have some missing fields, please review the form and try again.'
                ),
        }
    );

    const [translations, setTranslations] = useState(
        (space?.translations?.length > 0
            ? space.translations
            : [
                  {
                      locale: LANGUAGE.ENGLISH,
                      entrance: space?.entrance || '',
                      floor_level: space?.floor_level || '',
                      room_name: space?.room_name || '',
                      directions: space?.directions || '',
                  },
              ]
        ).reduce(
            (prev, t) => ({
                ...prev,
                [t.locale]: {
                    entrance: t.entrance,
                    floor_level: t.floor_level,
                    room_name: t.room_name,
                    directions: t.directions,
                },
            }),
            {}
        )
    );

    const save = (publish = space.published) => {
        let payload = {
            ...formData,
            address: { ...(formData.address || {}), area: formData.area },
            id: edit ? space.id : null,
            photos: formData.photos.map((a) => a.id),
            occasions: formData.occasions.map((a) => ({
                id: a.id,
                primary: a.primary,
            })),
            facilities: formData.facilities.map((a) => ({
                id: a.id,
                description: a.description,
            })),
            availability: mapObject(formData.availability, (item) =>
                !!(item.from && item.to) ? `${item.from}-${item.to}` : null
            ),
            access_open_path: formData.access_open_path || [],
            contact_email: formData.contact_email.filter((a) => !!a),
            published: typeof publish == 'boolean' ? publish : space.published,
            translations,
        };

        sendRequest(payload);
    };

    const doDelete = () => {
        const confirmed = window.confirm(
            'Are you sure you want to delete this space?'
        );

        if (confirmed) {
            requestDelete(space.id);
        }
    };

    let headerMenu = [
        <Button grey key="save" onClick={save}>
            {edit ? 'Save' : 'Save draft'}
        </Button>,
    ];

    if (space)
        headerMenu = [
            <CopyToClipboard grey text={space.link}>
                <Button key="copy">Copy Deeplink</Button>
            </CopyToClipboard>,
            <CircleButton key="delete" onClick={doDelete} grey>
                <DeleteIcon />
            </CircleButton>,
            ...headerMenu,
        ];

    if (space.published) {
        headerMenu.push(
            <Button key="unpublish" onClick={() => save(false)}>
                Unpublish
            </Button>
        );
    } else {
        headerMenu.push(
            <Button key="publish" onClick={() => save(true)}>
                Publish
            </Button>
        );
    }

    function getAddOnWarning() {
        return formData.add_on_rate !== 0 ? (
            <FormController label="Orbit add-on rate">
                <Warning>
                    Final price will increase by: {formData.add_on_rate}%
                </Warning>
            </FormController>
        ) : null;
    }

    return (
        <Modal headerMenu={headerMenu} onClose={onClose}>
            <Container>
                <Title bold>{edit ? 'Edit' : 'Add'} Space</Title>
                {edit && (
                    <>
                        <Divider withSpace />
                        <FormController label="UUID">
                            <Input value={space.uuid} disabled={true} />
                        </FormController>
                    </>
                )}

                <Row>
                    <Column span={3}>
                        <Divider withSpace />
                        <FormController
                            noPaddingBottom
                            error={validationErrors.provider_capability_id}
                        >
                            <ProviderSelector
                                selectedProvider={
                                    formData.provider_capability_id
                                }
                                onChange={(id) =>
                                    updateFormData('provider_capability_id', id)
                                }
                            />
                        </FormController>
                    </Column>
                    <Column span={3}>
                        <Divider withSpace />
                        <FormController
                            noPaddingBottom
                            error={validationErrors.location_id}
                        >
                            <LocationSelector
                                selectedLocation={formData.location_id}
                                onChange={(id, location) => {
                                    if (!formData.address) {
                                        updateFormData({
                                            location_id: id,
                                            address: location.address,
                                            area:
                                                formData.area ||
                                                location.address.area ||
                                                '',
                                        });
                                    } else {
                                        updateFormData('location_id', id);
                                    }
                                }}
                            />
                        </FormController>
                    </Column>
                </Row>

                <Divider withSpace />

                <FormController
                    label="Photos"
                    largeLabel
                    error={validationErrors.photos}
                >
                    <ImageInput
                        multiple
                        value={formData.photos}
                        coverImage
                        wide
                        sortable
                        onImageAdded={(image) =>
                            updateFormData('photos', [
                                ...formData.photos,
                                image,
                            ])
                        }
                        onSort={(order) =>
                            updateFormData(
                                'photos',
                                order.map((a) =>
                                    formData.photos.find((b) => b.id === a)
                                )
                            )
                        }
                        onImageDeleted={(id) =>
                            updateFormData(
                                'photos',
                                formData.photos.filter((a) => a.id !== id)
                            )
                        }
                    />
                </FormController>

                <Divider withSpace />

                <FormGroup title="Address">
                    <FormController
                        label="Street"
                        error={validationErrors.address}
                    >
                        <AddressInput
                            initialValue={
                                formData.address
                                    ? formData.address.fullAddress
                                    : null
                            }
                            onChange={(e) => {
                                console.log('address', e);
                                updateFormData('address', e);
                            }}
                        />
                    </FormController>
                    <FormController label="Area">
                        <Input
                            value={formData.area}
                            onChange={(e) =>
                                updateFormData('area', e.target.value)
                            }
                        />
                    </FormController>
                </FormGroup>

                <Divider withSpace />

                <FormGroup title="OpenPath">
                    <FormController
                        label="Zone Id"
                        error={validationErrors.zone}
                    >
                        <ListInput
                            value={formData.access_open_path}
                            onChange={(e) => {
                                updateFormData('access_open_path', e);
                            }}
                        />
                    </FormController>
                </FormGroup>

                {Object.values(LANGUAGE).map((lang) => (
                    <FormGroup title={`Directions (${lang.toUpperCase()})`}>
                        <FormController
                            key={`entrance-${lang}`}
                            label="Entrance"
                            error={
                                validationErrors[
                                    `translations.${lang}.entrance`
                                ]
                            }
                        >
                            <Input
                                value={translations[lang]?.entrance}
                                onChange={(e) =>
                                    setTranslations({
                                        ...translations,
                                        [lang]: {
                                            ...translations[lang],
                                            entrance: e.target.value,
                                        },
                                    })
                                }
                            />
                        </FormController>
                        <Row>
                            <Column span={3}>
                                <FormController
                                    key={`floor-level-${lang}`}
                                    label="Floor level"
                                    error={
                                        validationErrors[
                                            `translations.${lang}.floor_level`
                                        ]
                                    }
                                >
                                    <Input
                                        value={translations[lang]?.floor_level}
                                        onChange={(e) =>
                                            setTranslations({
                                                ...translations,
                                                [lang]: {
                                                    ...translations[lang],
                                                    floor_level: e.target.value,
                                                },
                                            })
                                        }
                                    />
                                </FormController>
                            </Column>
                            <Column span={3}>
                                <FormController
                                    key={`room-name-${lang}`}
                                    label="Room name"
                                    error={
                                        validationErrors[
                                            `translations.${lang}.room_name`
                                        ]
                                    }
                                >
                                    <Input
                                        value={translations[lang]?.room_name}
                                        onChange={(e) =>
                                            setTranslations({
                                                ...translations,
                                                [lang]: {
                                                    ...translations[lang],
                                                    room_name: e.target.value,
                                                },
                                            })
                                        }
                                    />
                                </FormController>
                            </Column>
                        </Row>
                        <FormController
                            key={`directions-${lang}`}
                            label="Comment"
                            error={
                                validationErrors[
                                    `translations.${lang}.directions`
                                ]
                            }
                        >
                            <Input
                                as="textarea"
                                rows={5}
                                value={translations[lang]?.directions}
                                onChange={(e) =>
                                    setTranslations({
                                        ...translations,
                                        [lang]: {
                                            ...translations[lang],
                                            directions: e.target.value,
                                        },
                                    })
                                }
                            />
                        </FormController>
                    </FormGroup>
                ))}

                <Divider withSpace />

                <FormGroup title="Occasions">
                    <FormController error={validationErrors.occasions}>
                        <OccasionSelector
                            value={formData.occasions}
                            onChange={(occasions) => {
                                if (occasions.length === 1) {
                                    occasions[0].primary = true;
                                }
                                updateFormData('occasions', occasions);
                            }}
                        />
                    </FormController>
                </FormGroup>

                <Divider withSpace />

                <FormGroup title="Space allows only full day bookings">
                    <FormController>
                        <CheckBox
                            checked={!!formData.full_day}
                            onClick={() =>
                                updateFormData('full_day', !formData.full_day)
                            }
                        />
                    </FormController>
                </FormGroup>

                <Divider withSpace />

                <FormGroup title="Facilities">
                    <FormController error={validationErrors.facilities}>
                        <FacilitySelector
                            value={formData.facilities}
                            onChange={(facilities) =>
                                updateFormData('facilities', facilities)
                            }
                        />
                    </FormController>
                </FormGroup>

                <Divider withSpace />

                <FormGroup title="Boosters">
                    <FormController error={validationErrors.boosters}>
                        <BoosterSelector
                            value={formData.boosters}
                            onChange={(boosters) =>
                                updateFormData('boosters', boosters)
                            }
                        />
                    </FormController>
                </FormGroup>

                <Divider withSpace />

                <FormGroup title="Seats">
                    <Row>
                        <Column span={3}>
                            <FormController error={validationErrors.seats}>
                                <Input
                                    value={formData.seats}
                                    onChange={(e) =>
                                        updateFormData('seats', e.target.value)
                                    }
                                />
                            </FormController>
                        </Column>
                    </Row>
                </FormGroup>

                <Divider withSpace />

                <FormGroup title="Availability">
                    <FormController>
                        <AvailabilitySelector
                            availability={formData.availability}
                            onChange={(updatedAvailability) =>
                                updateFormData(
                                    'availability',
                                    updatedAvailability
                                )
                            }
                        />
                    </FormController>
                </FormGroup>

                <Divider withSpace />

                <FormGroup title="Available as Satellite">
                    <FormController>
                        <CheckBox
                            checked={!!formData.is_satellite}
                            onClick={() =>
                                updateFormData(
                                    'is_satellite',
                                    !formData.is_satellite
                                )
                            }
                        />
                    </FormController>
                </FormGroup>

                <Divider withSpace />

                <FormGroup title="Space requires check in">
                    <FormController>
                        <CheckBox
                            checked={!!formData.check_in_required}
                            onClick={() =>
                                updateFormData(
                                    'check_in_required',
                                    !formData.check_in_required
                                )
                            }
                        />
                    </FormController>
                </FormGroup>

                <Divider withSpace />

                <FormGroup title="Price policies">
                    {getAddOnWarning()}
                    <CurrencyInputForm
                        currencies={currencies}
                        pricing={formData.pricing}
                        priceType="satellite"
                        validationErrors={validationErrors}
                        onChange={(updatedPricing) =>
                            updateFormData('pricing', updatedPricing)
                        }
                    />
                    <CurrencyInputForm
                        currencies={currencies}
                        pricing={formData.pricing}
                        priceType="base"
                        validationErrors={validationErrors}
                        onChange={(updatedPricing) =>
                            updateFormData('pricing', updatedPricing)
                        }
                    />
                </FormGroup>

                <Divider withSpace />

                <ContactInformation
                    contactInformation={formData.contact_information}
                    validationErrors={validationErrors}
                    onChange={(val) =>
                        updateFormData('contact_information', val)
                    }
                />

                <FormGroup title="Notifications">
                    <p>
                        We will send notifications regarding this space to the
                        following e-mail addresses
                    </p>
                    <FormController
                        label="E-mails"
                        error={validationErrors.contact_email}
                    >
                        <ListInput
                            value={formData.contact_email}
                            onChange={(val) =>
                                updateFormData('contact_email', val)
                            }
                        />
                    </FormController>
                </FormGroup>
            </Container>
        </Modal>
    );
};

export default SpaceForm;
