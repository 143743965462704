import React from 'react';
import styled, { css } from 'styled-components';
import colors from '../../const/colors';

const Text = styled.div`
    font-size: 18px;
    line-height: 1.33;
    font-family: 'Graphik', Helvetica, Areal, serif;
    font-weight: ${(props) => (props.bold ? 500 : 'normal')};
    color: ${(props) =>
        props.color || (props.weak ? colors.greyDark : colors.black)};

    ${(props) =>
        props.small &&
        css`
            font-size: 14px;
            line-height: 1.428;
        `};

    ${(props) =>
        props.tiny &&
        css`
            font-size: 12px;
            line-height: 1.5;
        `};
`;

export default Text;
