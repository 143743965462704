import React from 'react';
import styled from 'styled-components';
import colors from '../../const/colors';
import CheckMarkIcon from '../icons/interface/CheckMarkIcon';

const Container = styled.div`
    width: 18px;
    height: 18px;
    background-color: ${(props) =>
        props.checked ? colors.green : colors.grey};
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;

const CheckBox = ({ checked, ...props }) => {
    return (
        <Container checked={checked} {...props}>
            {checked && <CheckMarkIcon />}
        </Container>
    );
};

export default CheckBox;
