import React from 'react';
import styled from 'styled-components';
import colors from '../../const/colors';
import Label from '../typography/Label';

const Container = styled.ul`
    padding-left: 0;
    list-style: none;
    color: ${colors.red};

    font-size: 14px;
    margin-top: -4px;
`;

const ValidationErrors = ({ errors, ...props }) => {
    if (!errors) return null;

    return (
        <Container>
            {errors.map((line, k) => (
                <li key={k}>{line}</li>
            ))}
        </Container>
    );
};

export default ValidationErrors;
