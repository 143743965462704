import React from 'react';
import styled, { css } from 'styled-components';
import Button from './Button';

const StyledButton = styled(Button)`
    div {
        display: flex;
        flex-direction: row;
        align-items: center;

        svg {
            margin-right: 5px;
            position: relative;
            top: 1px;

            ${(props) =>
                props.iconAfter &&
                css`
                    margin-right: 0;
                    margin-left: 5px;
                `};
        }
    }
`;

const IconButton = ({ icon, iconAfter, children, ...props }) => {
    return (
        <StyledButton iconAfter={iconAfter} {...props}>
            <div>
                {!!(icon && !iconAfter) && icon}
                <span>{children}</span>
                {!!(icon && iconAfter) && icon}
            </div>
        </StyledButton>
    );
};

export default IconButton;
