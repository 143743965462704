import React from 'react';
import styled from 'styled-components';
import useApiResource from '../../hooks/useApiResource';
import CheckMarkIcon from '../icons/interface/CheckMarkIcon';
import colors from '../../const/colors';
import Text from '../typography/Text';
import UnStarredIcon from '../icons/interface/UnStarredIcon';
import StarredIcon from '../icons/interface/StarredIcon';

const Container = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: calc(100% + 40px);
    margin-left: -20px;

    > div {
        width: calc(50% - 40px);
        margin-left: 20px;
        margin-right: 20px;
        padding: 18px 0;
        position: relative;
        cursor: pointer;

        display: flex;
        flex-direction: row;
        align-items: center;

        .title {
            display: flex;
            flex: 1;
            margin-left: 18px;
            user-select: none;
        }

        &:after {
            content: '';
            width: calc(100% - 36px);
            position: absolute;
            bottom: 0;
            right: 0;
            background-color: ${colors.greyLight};
            height: 1px;
        }

        &.no-border,
        &:last-child {
            &:after {
                display: none;
            }
        }
    }
`;

const CheckBoxContainer = styled.div`
    width: 18px;
    height: 18px;
    background-color: ${(props) =>
        props.checked ? colors.green : colors.grey};
    display: inline-flex;
    align-items: center;
    justify-content: center;
`;

const CheckBox = ({ checked, ...props }) => (
    <CheckBoxContainer checked={checked} {...props}>
        {checked && <CheckMarkIcon />}
    </CheckBoxContainer>
);

const OccasionSelector = ({ value, onChange }) => {
    const [occasions, occasionsLoading] = useApiResource('occasions');
    if (!occasions) return null;

    const toggle = (id) => {
        if (value.find((a) => a.id === id)) {
            onChange(value.filter((a) => a.id !== id));
        } else {
            onChange([...value, { id }]);
        }
    };

    const togglePrimary = (id, event) => {
        event.stopPropagation();

        let selected = [...value];

        if (!selected.find((a) => a.id === id)) {
            selected.push({ id });
        }

        onChange(
            selected.map((a) => ({
                id: a.id,
                primary: a.id !== id ? false : !a.primary,
            }))
        );
    };

    return (
        <Container>
            {occasions.map((occasion, index) => {
                const isSelected = !!value.find((a) => a.id === occasion.id);
                return (
                    <div
                        key={occasion.id}
                        onClick={() => toggle(occasion.id)}
                        className={
                            occasions.length % 2 === 0 &&
                            index === occasions.length - 2
                                ? 'no-border'
                                : null
                        }
                    >
                        <CheckBox
                            checked={value.find((a) => a.id === occasion.id)}
                        />
                        <Text className="title">{occasion.name}</Text>

                        {isSelected ? (
                            value.find(
                                (a) => a.id === occasion.id && a.primary
                            ) ? (
                                <StarredIcon
                                    onClick={(e) =>
                                        togglePrimary(occasion.id, e)
                                    }
                                />
                            ) : (
                                <UnStarredIcon
                                    onClick={(e) =>
                                        togglePrimary(occasion.id, e)
                                    }
                                />
                            )
                        ) : null}
                    </div>
                );
            })}
        </Container>
    );
};

export default OccasionSelector;
