import React from 'react';
import styled from 'styled-components';
import Button from '../../components/buttons/Button';
import CircleButton from '../../components/buttons/CircleButton';
import DeleteIcon from '../../components/icons/interface/DeleteIcon';
import Title from '../../components/typography/Title';
import Modal from '../../components/Modal';
import Divider from '../../components/misc/Divider';
import FormGroup from '../../components/form/FormGroup';
import FormController from '../../components/form/FormController';
import Input from '../../components/form/Input';
import useFormState from '../../hooks/useFormState';
import useSaveApiResource from '../../hooks/useSaveApiResource';
import useDeleteApiResource from '../../hooks/useDeleteApiResource';
import ImageInput from '../../components/form/ImageInput';
import AddressInput from '../../components/form/AddressInput';
import { Column, Row } from '../../components/layout/Grid';
import ContactInformation from '../../components/form/ContactInformation';
import { LANGUAGE } from '../../const/languages';

const Container = styled.div``;

const LocationForm = ({ location, edit, onClose }) => {
    const [formData, updateFormData] = useFormState({
        name: location && location.name ? location.name : '',
        phone_number: location?.phone_number || '',
        images: location && location.images ? location.images : [],
        address: location && location.address ? location.address : null,
        area: location?.address?.area || '',
        openpath_key: location?.openpath_key || '',
        contact_information: location?.contact_information || {
            phone: '',
            email: '',
            chat: '',
        },
        translations: (location?.translations?.length > 0
            ? location.translations
            : [
                  {
                      locale: LANGUAGE.ENGLISH,
                      description: location.description || '',
                  },
              ]
        ).reduce(
            (prev, t) => ({
                ...prev,
                [t.locale]: { description: t.description },
            }),
            {}
        ),
    });

    const [sendRequest, requestLoading, validationErrors] = useSaveApiResource(
        'locations',
        {
            onSuccess: (success) => onClose && onClose(),
            onFail: (failed) => {
                console.log('save error:', failed);
            },
        }
    );

    const [requestDelete, deleteLoading] = useDeleteApiResource(
        'locations',
        (success) => onClose && onClose(),
        (failed) => alert('Unable to delete location')
    );

    const save = () => {
        sendRequest({
            ...formData,
            id: edit ? location.id : null,
            address: { ...(formData.address || {}), area: formData.area },
            images: formData.images.map((a) => a.id),
        });
    };

    const doDelete = () => {
        const confirmed = window.confirm(
            'Are you sure you want to delete this location?'
        );

        if (confirmed) requestDelete(location.id);
    };

    let headerMenu = [
        <Button grey key="save" onClick={save}>
            Save
        </Button>,
    ];

    if (location) {
        headerMenu = [
            <CircleButton key="delete" onClick={doDelete} grey>
                <DeleteIcon />
            </CircleButton>,
            ...headerMenu,
        ];
    }

    return (
        <Modal headerMenu={headerMenu} onClose={onClose}>
            <Container>
                <Title bold>{edit ? 'Edit' : 'Add'} Location</Title>
                <Divider />

                <FormGroup title="About">
                    <FormController label="Name">
                        <Input
                            value={formData.name}
                            onChange={(e) =>
                                updateFormData('name', e.target.value)
                            }
                        />
                    </FormController>

                    {Object.values(LANGUAGE).map((lang) => (
                        <FormController
                            label={`Description (${lang.toUpperCase()})`}
                            key={`desc-${lang}`}
                        >
                            <Input
                                as="textarea"
                                value={
                                    formData.translations[lang]?.description ||
                                    ''
                                }
                                onChange={(e) =>
                                    updateFormData({
                                        translations: {
                                            ...formData.translations,
                                            [lang]: {
                                                description:
                                                    e.target.value || null,
                                            },
                                        },
                                    })
                                }
                                rows={3}
                            />
                        </FormController>
                    ))}
                    <Row>
                        <Column span={3}>
                            <FormController
                                label="Openpath location key"
                                error={validationErrors.openpath_key}
                            >
                                <Input
                                    value={formData.openpath_key}
                                    onChange={(e) =>
                                        updateFormData(
                                            'openpath_key',
                                            e.target.value
                                        )
                                    }
                                />
                            </FormController>
                        </Column>
                    </Row>
                </FormGroup>

                <Divider />

                <FormGroup title="Address">
                    <FormController label="Street">
                        <AddressInput
                            initialValue={
                                formData.address
                                    ? formData.address.fullAddress
                                    : null
                            }
                            onChange={(e) => {
                                updateFormData('address', e);
                                console.log('selected location', e);
                            }}
                        />
                    </FormController>
                    <FormController label="Area">
                        <Input
                            value={formData.area}
                            onChange={(e) =>
                                updateFormData('area', e.target.value)
                            }
                        />
                    </FormController>
                </FormGroup>

                <Divider />

                <ContactInformation
                    contactInformation={formData.contact_information}
                    validationErrors={validationErrors}
                    onChange={(val) =>
                        updateFormData('contact_information', val)
                    }
                />

                <Divider />

                <FormController
                    label="Photos"
                    largeLabel
                    error={validationErrors.images}
                >
                    <ImageInput
                        multiple
                        value={formData.images}
                        coverImage
                        wide
                        sortable
                        onSort={(order) =>
                            updateFormData(
                                'images',
                                order.map((a) =>
                                    formData.images.find((b) => b.id === a)
                                )
                            )
                        }
                        onImageAdded={(image) =>
                            updateFormData('images', [
                                ...formData.images,
                                image,
                            ])
                        }
                        onImageDeleted={(id) =>
                            updateFormData(
                                'images',
                                formData.images.filter((a) => a.id !== id)
                            )
                        }
                    />
                </FormController>
            </Container>
        </Modal>
    );
};

export default LocationForm;
