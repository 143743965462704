import React from 'react';

const TeamIcon = ({ invert, ...props }) => (
    <svg width="24px" height="20px" viewBox="0 0 24 20" {...props}>
        <g
            id="Page-1"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
        >
            <g
                id="Desktop-/-Company-customer"
                transform="translate(-182.000000, -188.000000)"
            >
                <rect
                    fill={invert ? '#16171A' : '#FFFFFF'}
                    x="0"
                    y="0"
                    width="1440"
                    height="812"
                />
                <g
                    id="Icon-Label-cell"
                    transform="translate(158.000000, 182.000000)"
                >
                    <rect id="Div" x="0" y="0" width="1125" height="32" />
                    <g
                        id="Combined-Shape"
                        transform="translate(24.000000, 4.000000)"
                        fill={invert ? '#FFFFFF' : '#16171A'}
                    >
                        <path d="M8,12 C10.8008324,12 12.8999307,13.1609179 14.2715608,15.3938337 C15.2321013,14.4762775 16.4774002,14 18,14 C21.4386152,14 23.4629134,16.4291578 23.9929883,20.8817871 C24.0590933,21.4370697 23.6587225,21.9299125 23.1179175,21.9931569 L23,22 L15.0074797,22.0000672 L15.0074797,22.0000672 L14.9662987,22.0006299 C14.4835796,21.98428 14.0696147,21.6191756 14.0077221,21.1240347 C13.3979857,16.2461432 11.4326104,14 8,14 C4.56738959,14 2.60201432,16.2461432 1.99227788,21.1240347 C1.92377538,21.6720547 1.4239852,22.0607804 0.875965265,21.9922779 C0.327945327,21.9237754 -0.0607803691,21.4239852 0.00772212329,20.8759653 C0.73131901,15.0871902 3.43261041,12 8,12 Z M18,6 C19.6568542,6 21,7.34314575 21,9 C21,10.6568542 19.6568542,12 18,12 C16.3431458,12 15,10.6568542 15,9 C15,7.34314575 16.3431458,6 18,6 Z M8,2 C10.209139,2 12,3.790861 12,6 C12,8.209139 10.209139,10 8,10 C5.790861,10 4,8.209139 4,6 C4,3.790861 5.790861,2 8,2 Z" />
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

export default TeamIcon;
