import React, { useState } from 'react';
import styled from 'styled-components';
import Input from '../components/form/Input';
import Card from '../components/Card';
import Title from '../components/typography/Title';
import Label from '../components/typography/Label';
import Button from '../components/buttons/Button';
import { navigate } from 'hookrouter';
import { dispatch } from '../redux';
import { useListener } from 'eventsy';

const Container = styled.div`
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;

const LoginCard = styled(Card)`
    display: flex;
    flex-direction: column;
    padding: 20px;
    width: 500px;
`;

const LoginButton = styled(Button)`
    align-self: flex-end;
    margin-top: 20px;
`;

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    useListener('auth/login/success', () => navigate('/admin/provider'));
    useListener('auth/failed', () => {
        // Set loading to false;
        alert('Login failed');
    });

    const login = () => {
        dispatch('auth/login', { email, password });
    };

    return (
        <Container>
            <LoginCard>
                <Title bold>Login</Title>
                <Label small>Email</Label>
                <Input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <Label small>Password</Label>
                <Input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />

                <LoginButton onClick={login}>Login</LoginButton>
            </LoginCard>
        </Container>
    );
};

export default Login;
