import React from 'react';

const UserIconTiny = ({ color, ...props }) => (
    <svg
        width="10px"
        height="10px"
        viewBox="0 0 10 10"
        version="1.1"
        {...props}
    >
        <g
            id="10-px/Dark/User"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
        >
            <g id="User-">
                <g id="User-2">
                    <g id="User">
                        <rect id="Bound" x="0" y="0" width="10" height="10" />
                        <path
                            d="M6,5 C7.65685425,5 9,6.34314575 9,8 L9,9 L1,9 L1,8 C1,6.34314575 2.34314575,5 4,5 L6,5 Z M5,0 C6.1045695,0 7,0.8954305 7,2 C7,3.1045695 6.1045695,4 5,4 C3.8954305,4 3,3.1045695 3,2 C3,0.8954305 3.8954305,0 5,0 Z"
                            id="Combined-Shape"
                            fill={color || '#FFFFFF'}
                        />
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

export default UserIconTiny;
