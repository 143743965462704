import React, { useState } from 'react';
import styled from 'styled-components';
import AdminTabPage from '../../components/templates/AdminTabPage';
import Tabs from '../../components/buttons/Tabs';
import IconTagResource from './IconTagResource';
import Boosters from './Boosters';

const Container = styled.div``;

const Settings = () => {
    const [activeTab, setActiveTab] = useState('Occasions');
    return (
        <AdminTabPage
            title="Settings"
            tabs={
                <Tabs
                    items={['Occasions', 'Facilities', 'Boosters', 'General']}
                    active={activeTab}
                    onClick={setActiveTab}
                />
            }
        >
            {activeTab === 'Occasions' && (
                <IconTagResource resource="occasions" resourceName="Occasion" />
            )}
            {activeTab === 'Facilities' && (
                <IconTagResource
                    resource="facilities"
                    resourceName="Facility"
                />
            )}
            {activeTab === 'Boosters' && <Boosters />}
        </AdminTabPage>
    );
};

export default Settings;
