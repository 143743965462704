import React, { useState } from 'react';
import styled from 'styled-components';
import AdminTabPage from '../../../components/templates/AdminTabPage';
import Tabs from '../../../components/buttons/Tabs';
import colors from '../../../const/colors';
import useCustomHeader from '../../../hooks/useCustomHeader';
import EditHeader from '../../../components/layout/EditHeader';
import CircleUserIcon from '../../../components/icons/interface/CircleUserIcon';
import Label from '../../../components/typography/Label';
import Button from '../../../components/buttons/Button';
import InvitationForm from './InvitationForm';
import Divider from '../../../components/misc/Divider';
import useApiResource from '../../../hooks/useApiResource';
import GroupForm from './GroupForm';
import ViewInvitation from './ViewInvitation';
import ViewUser from './ViewUser';

const Container = styled.div``;

const List = styled.div`
    display: flex;
    flex-direction: column;

    > div {
        display: flex;
        flex-direction: row;
        width: 100%;
        align-items: center;
        position: relative;
        padding: 15px 0;

        &:after {
            content: '';
            display: block;
            height: 1px;
            width: calc(100% - 40px);
            background-color: ${colors.grey};
            position: absolute;
            bottom: 0;
            right: 0;
        }

        .name {
            margin-left: 18px;
            flex: 1;
            display: flex;
        }
    }
`;

const GroupOverview = ({ teamId, groupId }) => {
    const [activeTab, setActiveTab] = useState('Active');
    const [editFormVisible, setEditFormVisible] = useState(false);
    const [openInvitation, setOpenInvitation] = useState(null);
    const [openUser, setOpenUser] = useState(null);

    const [group] = useApiResource('groups', groupId);
    const [team] = useApiResource('teams', teamId);
    const [invitations] = useApiResource('invitations', null, {
        dataFilter: (inv) => inv.groupId === groupId,
    });

    const [users] = useApiResource('users', null, {
        dataFilter: (user) =>
            !!user.groups && user.groups.find((a) => a.id === groupId),
    });

    useCustomHeader(<EditHeader onEdit={() => setEditFormVisible(true)} />);

    if (!team || !group) return null;

    return (
        <AdminTabPage
            title={`${team.name} \\ ${group.name}`}
            tabs={
                <Tabs
                    items={['Active', 'Invitations']}
                    active={activeTab}
                    onClick={setActiveTab}
                />
            }
        >
            {activeTab === 'Invitations' && (
                <InvitationForm
                    groupId={group.id}
                    hasOpenPath={!!group.openpath_group}
                />
            )}
            <List>
                {activeTab === 'Active'
                    ? users
                        ? users.map((user) => (
                              <div key={user.id}>
                                  <CircleUserIcon />
                                  <Label as="div" className="name">
                                      {user.name}
                                  </Label>
                                  <Button
                                      grey
                                      onClick={() => setOpenUser(user)}
                                  >
                                      View
                                  </Button>
                              </div>
                          ))
                        : null
                    : invitations
                    ? invitations.map((invitation) => (
                          <div key={`inv-${invitation.uuid}`}>
                              <CircleUserIcon />
                              <Label as="div" className="name">
                                  {invitation.name}
                              </Label>
                              <Button
                                  grey
                                  onClick={() => setOpenInvitation(invitation)}
                              >
                                  View
                              </Button>
                          </div>
                      ))
                    : null}
            </List>
            {editFormVisible && (
                <GroupForm
                    onClose={() => setEditFormVisible(false)}
                    team={team}
                    group={group}
                    edit={true}
                    userCount={users.length}
                />
            )}
            {!!openInvitation && (
                <ViewInvitation
                    edit={true}
                    invitation={openInvitation}
                    onClose={() => setOpenInvitation(null)}
                />
            )}
            {!!openUser && (
                <ViewUser
                    edit={true}
                    user={openUser}
                    groupId={groupId}
                    onClose={() => setOpenUser(null)}
                />
            )}
        </AdminTabPage>
    );
};

export default GroupOverview;
