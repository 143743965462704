import React, { useEffect } from 'react';
import { useListener } from 'eventsy';
import { dispatch } from '../redux';
import { navigate, usePath } from 'hookrouter';
import delayedNavigation from '../routes/delayNavigation';

const useAppStart = () => {
    const path = window.location.pathname;
    useListener('app/ready', ({ user }) => {
        if (user && user.token && path.indexOf('/admin') !== 0)
            delayedNavigation('/admin/provider');
    });
};

export default useAppStart;
