import React from 'react';
import styled from 'styled-components';
import CircleIcon from '../icons/CircleIcon';
import UserIcon from '../icons/UserIcon';
import colors from '../../const/colors';
import Label from '../typography/Label';
import Text from '../typography/Text';
import Button from '../buttons/Button';

const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    min-width: 375px;
    padding-left: 20px;
    padding-right: 20px;

    .text {
        display: flex;
        flex: 1;
        flex-direction: column;
        padding-left: 18px;
        padding-right: 18px;
    }
`;

const ContactCell = ({ name, subtitle, buttonText, onClick, ...props }) => {
    return (
        <Container {...props}>
            <CircleIcon color={colors.greyLight}>
                <UserIcon />
            </CircleIcon>
            <div className="text">
                <Label small>{name}</Label>
                <Text tiny weak>
                    {subtitle}
                </Text>
            </div>
            <Button grey onClick={onClick}>
                {buttonText}
            </Button>
        </Container>
    );
};

export default ContactCell;
