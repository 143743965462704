import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { dispatch } from '../redux';
import api from '../services/Api';
import resourceEndpoints from '../const/resourceEndpoint';

const useApiResource = (resource, id, options = {}) => {
    const [loading, setLoading] = useState(true);
    const { refetch, customFetcher, dataFilter } = options;

    const data = useSelector((state) => {
        if (dataFilter && state.apiData[resource]) {
            return state.apiData[resource].filter(dataFilter);
        }

        if (id) {
            if (state.apiData[resource])
                return state.apiData[resource].find((a) => a.id === id);
            return null;
        }
        return state.apiData[resource];
    });

    const fetchAll = () => {
        const endpoint = resourceEndpoints[resource];
        return api(endpoint).then((res) => {
            dispatch('apiData/update', { [resource]: res });
        });
    };

    const fetchSingle = () => {
        const endpoint = `${resourceEndpoints[resource]}/${id}`;

        return api(endpoint).then((res) => {
            dispatch('apiData/resource/add', { resource, data: res });
        });
    };

    useEffect(() => {
        if (!data || (Array.isArray(data) && !data.length) || refetch) {
            const fetcher = customFetcher
                ? () => customFetcher(api)
                : id
                ? fetchSingle
                : fetchAll;

            fetcher()
                .catch((err) => {
                    console.log('Error from server', err);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [resource, id]);

    return [data, loading];
};

export default useApiResource;
