import React from 'react';
import styled from 'styled-components';
import Card from '../Card';
import LocationCell from '../cells/LocationCell';
import colors from '../../const/colors';
import SeatsCell from '../cells/SeatsCell';
import Label from '../typography/Label';
import Text from '../typography/Text';
import Button from '../buttons/Button';
import OccasionIcon from '../icons/OccasionIcon';
import moment from 'moment';

const Container = styled(Card)``;

const SpaceImageWrap = styled.div`
    position: relative;
    width: 100%;

    &:before {
        content: '';
        padding-bottom: 71%;
        display: block;
        width: 100%;
    }

    > img {
        width: 100%;
        position: absolute;
        height: 100%;
        object-fit: cover;
        object-position: center center;
        left: 0;
        top: 0;
        z-index: 0;
    }

    &:after {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background-image: linear-gradient(
            180deg,
            rgba(0, 0, 0, 0) 30%,
            rgba(0, 0, 0, 0.8) 100%
        );
        z-index: 1;
    }

    .details-bar {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        display: flex;
        flex-direction: row;
        z-index: 2;
        padding: 18px;

        > div:last-child {
            margin-left: 12px;
        }

        .main-occasion {
            display: flex;
            align-items: center;

            .icon {
                background-color: white;
                margin-right: 12px;
            }
        }
    }
`;

const Spacer = styled.div`
    display: flex;
    flex: 1;
`;

const Bottom = styled.div`
    padding: 18px 18px 22px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;

    .pubstats {
        display: flex;
        flex-direction: column;
        flex: 1;

        > div:first-child {
            margin-bottom: 4px;
        }
    }
`;

const SpaceCard = ({
    image,
    occasion,
    location,
    seats,
    published,
    timestamp,
    onEdit,
    ...props
}) => {
    return (
        <Container {...props}>
            <SpaceImageWrap>
                <img src={image} />

                <div className="details-bar">
                    {!!occasion && (
                        <div className="main-occasion">
                            <div className="icon">
                                <OccasionIcon src={occasion.icon} />
                            </div>
                            <Text small bold color="white">
                                {occasion.name}
                            </Text>
                        </div>
                    )}
                    <Spacer />
                    {!!location && (
                        <LocationCell
                            iconColor={colors.greyDark}
                            textColor="white"
                            location={location.name}
                        />
                    )}

                    <SeatsCell
                        iconColor={colors.greyDark}
                        textColor="white"
                        seats={seats || 0}
                    />
                </div>
            </SpaceImageWrap>
            <Bottom>
                <div className="pubstats">
                    <Label tiny as="div" color={colors.black}>
                        {published ? 'Published' : 'Unpublished'}
                    </Label>
                    <Text small color={colors.greyDark}>
                        {moment(timestamp).format('D. MMM. Y HH:mm')}
                    </Text>
                </div>
                <Button grey onClick={onEdit}>
                    Edit
                </Button>
            </Bottom>
        </Container>
    );
};

export default SpaceCard;
