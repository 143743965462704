import React from 'react';
import styled from 'styled-components';
import Label from '../typography/Label';

const Container = styled.div`
    width: 100%;
`;

const FormGroup = ({ title, children, ...props }) => {
    return (
        <Container {...props}>
            {!!title && (
                <Label as="div" withSpace>
                    {title}
                </Label>
            )}
            {children}
        </Container>
    );
};

export default FormGroup;
