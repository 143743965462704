import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import AdminScreen from '../../components/templates/AdminScreen';
import AddWhiteIcon from '../../components/icons/AddWhiteIcon';
import IconButton from '../../components/buttons/IconButton';
import useApiResource from '../../hooks/useApiResource';
import SpaceCard from '../../components/cards/SpaceCard';
import SpaceForm from './SpaceForm';

const Container = styled.div``;

const List = styled.div`
    width: calc(100% + 40px);
    margin-left: -20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    > div {
        width: calc(33.33% - 40px);
        margin-left: 20px;
        margin-right: 20px;
    }
`;

const SpaceIndex = () => {
    const [formIsVisible, setFormIsVisible] = useState(false);
    const [edit, setEdit] = useState(false);
    const [spaces, loading] = useApiResource('spaces');

    return (
        <AdminScreen
            title="Spaces"
            buttons={[
                <IconButton
                    onClick={() => {
                        setEdit(false);
                        setFormIsVisible(true);
                    }}
                    icon={<AddWhiteIcon />}
                    key="add"
                >
                    Add
                </IconButton>,
            ]}
        >
            {!!spaces && (
                <List>
                    {spaces.map((space) => (
                        <SpaceCard
                            key={space.id}
                            timestamp={space.created_at}
                            published={space.published}
                            occasion={
                                space.occasions
                                    ? space.occasions.find(
                                          (a) => a.pivot.primary
                                      )
                                    : null
                            }
                            seats={space.seats}
                            location={space.location}
                            image={
                                space.images && space.images.length
                                    ? space.images[0].url
                                    : null
                            }
                            onEdit={() => {
                                setEdit(space);
                                setFormIsVisible(true);
                            }}
                        />
                    ))}
                </List>
            )}

            {formIsVisible && (
                <SpaceForm
                    provider={null}
                    edit={!!edit}
                    space={edit}
                    onClose={() => setFormIsVisible(false)}
                />
            )}
        </AdminScreen>
    );
};

export default SpaceIndex;
