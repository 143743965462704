import React, { useCallback } from 'react';
import styled from 'styled-components';
import fileToBase64 from '../../utils/fileToBase64';
import { useDropzone } from 'react-dropzone';
import CircleButton from '../buttons/CircleButton';
import AddIcon from '../icons/interface/AddIcon';
import colors from '../../const/colors';
import DeleteIcon from '../icons/interface/DeleteIcon';

const Container = styled.div`
    margin-bottom: 12px;
`;

const UploadCard = styled(({ children, ...props }) => {
    return (
        <div {...props}>
            {children}
            <CircleButton light small>
                <AddIcon color={colors.greyDark} />
            </CircleButton>
        </div>
    );
})`
    width: 120px;
    height: 120px;
    position: relative;
    background: #ffffff;
    box-shadow: 0 8px 24px 0 rgba(0, 0, 0, 0.1);
    padding: 12px;

    button {
        position: absolute;
        right: 12px;
        bottom: 12px;
        pointer-events: none;
    }

    .dropzone {
        position: absolute;
        outline: none;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        cursor: pointer;
    }
`;

const ImageCard = styled(({ onDelete, image, ...props }) => {
    return (
        <div {...props}>
            <CircleButton grey small onClick={onDelete}>
                <DeleteIcon />
            </CircleButton>
            <img src={image} alt="logo" />
        </div>
    );
})`
    width: 120px;
    height: 120px;
    position: relative;
    background: #ffffff;
    box-shadow: 0 8px 24px 0 rgba(0, 0, 0, 0.1);
    padding: 12px;

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center center;
    }

    button {
        position: absolute;
        right: 12px;
        bottom: 12px;
    }
`;

const IconUpload = ({ value, onAdd, onDelete }) => {
    const onDrop = useCallback((acceptedFiles) => {
        acceptedFiles.map(async (file) => {
            if (file.type !== 'image/svg+xml') {
                return alert('File must be an svg');
            }

            const b64 = await fileToBase64(file, true);

            onAdd(b64);
        });
    }, []);

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
    });

    return (
        <Container>
            {!value && (
                <div>
                    <UploadCard>
                        <div className="dropzone" {...getRootProps()}>
                            <input {...getInputProps()} />
                        </div>
                    </UploadCard>
                </div>
            )}
            {!!value && (
                <div>
                    <ImageCard image={value} onDelete={onDelete} />
                </div>
            )}
        </Container>
    );
};

export default IconUpload;
