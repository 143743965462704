import React from 'react';
import styled from 'styled-components';
import colors from '../../const/colors';

const Subtitle = styled.h2`
    font-size: 24px;
    line-height: 1.25;
    font-family: 'Graphik', Helvetica, Areal, serif;
    font-weight: ${(props) => (props.bold ? 500 : 'normal')};
    color: ${(props) =>
        props.color || (props.weak ? colors.greyDark : colors.black)};
`;

export default Subtitle;
