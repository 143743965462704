const defaultState = {};

export default (state = defaultState, { type, payload }) => {
    switch (type) {
        case 'user/update':
            return { ...state, ...payload };
        case 'REDUX/CLEAR':
        case 'auth/failed':
            return defaultState;
        default:
            return state;
    }
};
