import React from 'react';
import styled from 'styled-components';

const OccasionIcon = styled.img`
    width: ${(props) => (props.large ? '32px' : '24px')};
    height: ${(props) => (props.large ? '32px' : '24px')};
    display: block;
`;

export default OccasionIcon;
