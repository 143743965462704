import React from 'react';
import styled from 'styled-components';
import Button from './Button';

const CircleButton = styled(Button)`
    width: ${(props) => (props.small ? '24px' : '32px')};
    height: ${(props) => (props.small ? '24px' : '32px')};
    padding: 0;
`;

export default CircleButton;
