import React from 'react';

const CloseIcon = (props) => (
    <svg
        width="14px"
        height="14px"
        viewBox="0 0 14 14"
        version="1.1"
        {...props}
    >
        <g
            id="Icons"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
        >
            <g id="24-px/UI/Close" transform="translate(-5.000000, -5.000000)">
                <g id="Close">
                    <rect id="Bound" x="0" y="0" width="24" height="24" />
                    <line
                        x1="6"
                        y1="6"
                        x2="18"
                        y2="18"
                        id="Path-8"
                        stroke="#000000"
                        strokeWidth="2"
                    />
                    <line
                        x1="18"
                        y1="6"
                        x2="6"
                        y2="18"
                        id="Path-8"
                        stroke="#000000"
                        strokeWidth="2"
                    />
                </g>
            </g>
        </g>
    </svg>
);

export default CloseIcon;
